import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Button from '../../Components/Common/Button/Button';
import { fetchAllCollection } from '../../Components/Api/API';
// import { ToastNotification } from '../../Components/Utils/ToastNotifications';
import { ArrowForward, PlusWithCircle, ShareColection } from '../../Components/SVG';
import Loader from "../../Components/Common/Loader/Loader";
import CreateCollectionsModal from '../../Components/Modals/CreateCollectionsModal';

const Collections = (props) => {
    const { count, setCount, loading, setLoading, selectedCollection, setSelectedCollection } = props;
    const [collections, setCollections] = useState()
    const [createCollection, setCreateCollection] = useState(false)

    const getAllCollection = () => {
        setLoading(true);
        fetchAllCollection().then((res) => {

            if (res?.code === 400) {
                // ToastNotification("error", "Please save a project from Map View.");
                setLoading(false);
            }
            if (res?.success) {
                if (res?.data?.length === 0) {
                    setLoading(false);
                } else if (res?.data?.length > 0) {
                    setCollections(res?.data);
                    setSelectedCollection(res?.data?.[0]);
                    setLoading(false);
                }
            }
        });
    };

    useEffect(() => {
        getAllCollection();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.recommendation}>
            <h3 className={styles.projectMapping}>Collection</h3>
            <p className={styles.para}>Save projects into a collection, choose an existing collection or create a new one</p>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.userCollectionContainer} style={{ paddingTop: '24px', width: '100%' }}>
                        <div
                            className={styles.userCreateCollection}
                            onClick={() => setCreateCollection(true)}
                        >
                            <PlusWithCircle />
                            <div className={styles.userProjectContainer}>
                                <Button
                                    height='20px'
                                    bgColor='#fff'
                                    // color='#424542'
                                    radius='100px'
                                    borderColor='#fff'
                                    // text='Projects - '
                                    // length={4}
                                    lengthArea='15px'
                                    lengthSize='10px'
                                />
                                <p>Create Collection</p>
                            </div>
                        </div>
                        {collections?.map((data, index) => {
                            return (
                                <div
                                    className={styles.userCollection}
                                    onClick={() => setSelectedCollection(data)}
                                    key={index}
                                    style={{borderColor: data?.id === selectedCollection?.id ? "#1E232E" : "#EDF0EF"}}
                                >
                                    <ShareColection />
                                    <div className={styles.userProjectContainer}>
                                        <Button
                                            height='20px'
                                            bgColor='#fff'
                                            color='#424542'
                                            radius='100px'
                                            borderColor='#fff'
                                            text='Projects - '
                                            length={data?.projectCount}
                                            lengthArea='15px'
                                            lengthSize='10px'
                                        />
                                        <p>{data?.title}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            <div className={styles.buttonMainContainer}>
                <Button
                    height="40px"
                    bgColor="#1F443A"
                    color="#fff"
                    radius="100px"
                    borderColor="#1F443A"
                    text="Continue"
                    // disable={disable}
                    icon1={<ArrowForward fill="#fff"/>}
                    handleClick={() => setCount(count + 1)}
                />
            </div>

            <CreateCollectionsModal
                open={createCollection}
                setOpen={setCreateCollection}
                getAllCollections={getAllCollection}
            />
        </div>
    );
};

export default Collections;
