import { useRef, useEffect, useState, useImperativeHandle, forwardRef} from 'react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {useControl} from 'react-map-gl';
import DeleteProjectModal from '../Modals/DeleteProjectModal';
import { setIsDrawing } from '../Redux/reducers/gptOsmSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setPolygonCentroid } from "../Redux/reducers/substationSlice";
import { setDrawnLine, setDrawnPolygon } from "../Redux/reducers/filterSlice";

const DrawControl = forwardRef((props, ref) => {
    const drawRef = useRef(null);
    const dispatch = useDispatch();
    const currentStateRef = useRef({});
    const [currentMode, setCurrentMode] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const searchedPolygon = useSelector(state=>state.filters.drawnPolygon)
    const drawnLine = useSelector(state=>state.filters.drawnLine)
    MapboxDraw.constants.classes.CONTROL_BASE  = 'maplibregl-ctrl';
    MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-';
    MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group';

    useEffect(() => {
        currentStateRef.current = { searchedPolygon, drawnLine };
    }, [searchedPolygon, drawnLine]);

    const handleConfirm = () => {
        setOpenDeleteModal(false);
        if (currentMode === 'draw_polygon' && searchedPolygon) {
            dispatch(setDrawnPolygon(null));
            dispatch(setPolygonCentroid(null));
            drawRef.current.delete(searchedPolygon.id);
        } else if (currentMode === 'draw_line_string' && drawnLine) {
            drawRef.current.delete(drawnLine.id);
            dispatch(setDrawnLine(null));
        }
    };

    useControl(
        () => {
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    line_string: true,
                    polygon: true,
                    trash: true
                },
                defaultMode: 'simple_select'
            });
            drawRef.current = draw;
            return draw;
        },
        ({map}) => {
        map.on('draw.modechange', (e) => {
            const { searchedPolygon, drawnLine } = currentStateRef.current;
            const drawingModes = ['draw_line_string', 'draw_polygon'];
            // if (e.mode === 'draw_polygon' && searchedPolygon) {
            //     console.log('Existing polygon:', searchedPolygon);
            //     setIsModalOpen(true);
            //     setCurrentMode(e.mode);
            //     // const confirmDelete = window.confirm(`A previously drawn Polygon exists. Do you want to delete it and continue with a new drawing?`);
            //     // if (confirmDelete) {
            //     //     dispatch(setDrawnPolygon(null))
            //     //     dispatch(setPolygonCentroid(null))
            //     //     drawRef.current.delete(searchedPolygon.id);
            //     // } else {
            //     //     // Cancel new drawing or revert to previous mode
            //     //     console.log(`Cancelled new Polygon drawing.`);
            //     // }
            // } else if (e.mode === 'draw_line_string' && drawnLine) {
            //     console.log('Existing line:', drawnLine);
            //     setIsModalOpen(true);
            //     setCurrentMode(e.mode);
            //     // const confirmDelete = window.confirm(`A previously drawn Line exists. Do you want to delete it and continue with a new drawing?`);
            //     // if (confirmDelete) {
            //     //     drawRef.current.delete(drawnLine.id);
            //     //     // map.fire('draw.delete', props.onDelete);
            //     //     dispatch(setDrawnLine(null))
            //     //     console.log(`Deleted existing Line. Proceeding with new drawing.`, map);
            //     // } else {
            //     //     // Cancel new drawing or revert to previous mode
            //     //     console.log(`Cancelled new Line drawing.`);
            //     // }
            // }
            setOpenDeleteModal(true);
            setCurrentMode(e.mode);
            const isNowDrawing = drawingModes.includes(e.mode);
            dispatch(setIsDrawing(isNowDrawing))
        });
        map.on('draw.create', props.onCreate);
        map.on('draw.update', props.onUpdate);
        map.on('draw.delete', props.onDelete);
        },
        ({ map }) => {
            map.off('draw.create', props.onCreate);
            map.off('draw.update', props.onUpdate);
            map.off('draw.delete', props.onDelete);
        },
        {
            position: props.position
        }
    );
    useImperativeHandle(ref, () => ({
        // This method can be called by the parent to delete all drawings
        deleteAllDrawings: () => {
            if (currentMode === 'draw_polygon' && searchedPolygon) {
                drawRef.current.delete(searchedPolygon.id);
            } else if (currentMode === 'draw_line_string' && drawnLine) {
                drawRef.current.delete(drawnLine.id);
            }
        },
        handleMode: () => {
            drawRef.current.changeMode('simple_select');
        }
    }));
    return null;
        // <>
        //     <DeleteProjectModal
        //         open={openDeleteModal}
        //         onClose={() => setOpenDeleteModal(false)}
        //         handleDetele={handleConfirm}
        //         title={`Delete previous Drawing ${currentMode}?`}
        //         text={`Are you sure you want to delete the previous drawing ${currentMode} and start a new one?`}
        //     />
        // </>;
});

export default DrawControl;

// DrawControl.defaultProps = {
//     onCreate: () => { },
//     onUpdate: () => { },
//     onDelete: () => { },
// };
