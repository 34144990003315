import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  setRenewableActiveLayersArray } from "../Redux/reducers/datasetSlice";
import { Checkbox } from "@mui/material";

const energyOptions = [
    { layerName: 'abandoned-renewable', name: 'Abandoned', color: '#b61d14', checked: false },
    { layerName: 'apeal-lodged', name: 'Appeal Lodged', color: '#e502f9', checked: false },
    { layerName: 'apeal-refused', name: 'Appeal Refused', color: '#421986', checked: false },
    { layerName: 'apeal-withdrawn', name: 'Appeal Withdrawn', color: '#7f0e69', checked: false },
    { layerName: 'application-expired', name: 'Application Expired', color: '#ff837f', checked: false },
    { layerName: 'application-refused', name: 'Application Refused', color: 'red', checked: false },
    { layerName: 'application-submitted', name: 'Application Submitted', color: '#65b5f4', checked: false },
    { layerName: 'application-withdrawn', name: 'Application Withdrawn', color: '#9776cf', checked: false },
    { layerName: 'awaiting-construction', name: 'Awaiting Construction', color: '#83c586', checked: false },
    { layerName: 'decommissioned', name: 'Decommissioned', color: '#3e2425', checked: false },
    { layerName: 'finished', name: 'Finished', color: '#32691e', checked: false },
    { layerName: 'no-application-required', name: 'No Application Required', color: '#040200', checked: false },
    { layerName: 'operational', name: 'Operational', color: '#004c3f', checked: false },
    { layerName: 'planning-permission-expired', name: 'Planning Permission Expired', color: '#d55802', checked: false },
    { layerName: 'revised', name: 'Revised', color: '#4ECEE3', checked: false },
    { layerName: 'under-construction', name: 'Under Construction', color: '#04fed1', checked: false }
];

  const DevelopmentStatus = () => {
  const [options, setOptions] = useState(energyOptions);
  const [optionsVisible, setOptionsVisible] = useState(true); 
  const dispatch = useDispatch();

  const handleCheckboxChange = (index) => {
    const newOptions = [...options];
    newOptions[index].checked = !newOptions[index].checked;
    setOptions(newOptions);
  };
 
  useEffect(() => {
    const activeLayersRenewable = options.filter((option) => option.checked).map((option) => option.layerName);
    dispatch(setRenewableActiveLayersArray({ activeRenewableLayers: activeLayersRenewable }));
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

const handleSelectAllCheckboxChange = (isChecked) => {
  // const newOptions = options.map((option) => ({ ...option, checked: isChecked }));
  const newOptions = [...options];
  newOptions.forEach((option) => {
    option.checked = isChecked;
  });
  setOptions(newOptions);
};
  return (
    <div className="legend-main">
      {optionsVisible && (
        <>
          <div>
               <Checkbox
               checked={options.every((option) => option.checked)}
               onChange={(e) => handleSelectAllCheckboxChange(e.target.checked)}
                sx={{
                  color: '#1F443A',
                  '&.Mui-checked': {
                    color: '#1F443A',
                  },
              }}
              />
              <label>Select All</label>
            </div>
            { options.map((option, index) => (
          <div key={option.name} style={{width: '286px', display: 'flex', justifyContent: 'space-between',marginBottom: '2px', alignItems: 'center'}}>
          
            <div>
              <svg width="10" height="10" style={{marginRight: '4px'}}>
                <circle cx="5" cy="5" r="5" stroke="transparent" strokeWidth="0" fill={`${option.color}`} />
              </svg>
              {option.name}
            </div>
            <div style={{justifyContent: 'flex'}}>
              <Checkbox
                checked={option.checked}
                onChange={() => handleCheckboxChange(index)}
                sx={{
                  color: '#1F443A',
                  '&.Mui-checked': {
                    color: '#1F443A',
                  },
              }}
              />
            </div>
          </div>
        ))}
        </>
       
      )}
    </div>
  );
};

export default DevelopmentStatus;
