import React from "react";
import styles from "./styles.module.scss";
import AppCheckBox from "../../Common/CheckBox/CheckBox";
import AppTextBox from "../../Common/AppTextBox/AppTextBox";

const QuickSelectors = (props) => {
  const { title, firstText, firstChecked, secondText, secondChecked, firstHandler, secondHandler } = props;
  return (
    <div className={styles.quick_selectors}>
      {title && (
        <AppTextBox text="quick selectors" />
      )}
      <div className={styles.quick_selectors_checkbox}>
        <AppCheckBox
          text={firstText}
          checked={firstChecked}
          onChange={(event) => firstHandler(event.target.checked)}
        />
        <AppCheckBox
          text={secondText}
          checked={secondChecked}
          onChange={(event) => secondHandler(event.target.checked)}
        />
      </div>
    </div>
  );
};
export default QuickSelectors;
