import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { setPlanningData } from "../../Components/Redux/reducers/substationSlice";
import { fixed } from "../../Components/Utils/Formats";
import { PlanningCard } from "../../Components/Sidebar/RightSidebar/PlanningCard";
import Button from "../../Components/Common/Button/Button";
import { ElectricIcon } from "../../Components/SVG";
import MapCard from "../../Components/MapCard/index";
import { useLocation } from "react-router-dom";
import { setProjectsLoading } from "../../Components/Redux/reducers/projectsSlice";
import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import Loader from "../../Components/Common/Loader/Loader";
import axios from "axios";
import ProjectPasswordModal from "../../Components/Modals/ProjectPasswordModal";
import { TitleDescription } from "../../Components/Common/TitleDescription";
import { baseURL } from "../../Components/Api/instance";
import HeaderExternal from "../../Components/HeaderExternal";
import getCurrentSeasonUK from "../../Components/Utils/FindSeason";

const ExternalProjectDetail = () => {
  const location = useLocation(); // Access the dynamic parameter from the URL

  const queryString = location.search;
  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(queryString);
  const token = queryParams.get("token");

  const dispatch = useDispatch();
  const { projectsLoading } = useSelector((state) => state.projects);

  const [radiusUnit, setRadiusUnit] = useState("");
  const [distance, setDistance] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [mapLoad, setmapLoad] = useState(true);
  const [finalRadius, setFinalRadius] = useState("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [password, setPassword] = useState("");

  function convertDateFormat(dateString) {
    const dateParts = dateString?.split("-"); // split the date into [YYYY, MM, DD]
    return `${dateParts?.[2]}/${dateParts?.[1]}/${dateParts?.[0]}`; // rearrange to MM/DD/YYYY
  }

  const fetchProjectDetail = () => {
    dispatch(setProjectsLoading(true));
    const body = {
      token: token,
      password: password,
    };

    axios
      .post(`${baseURL}/project/validateSharableLink`, body)
      .then((res) => {
        // console.log('res', res)
        if (res.code === 400) {
          ToastNotification("error", res.message);
        }
        if (res?.data?.success) {
          setSelectedProject(res?.data?.data?.project);
          dispatch(setPlanningData(res?.data?.data?.project?.planningData));
          setDistance(res?.data?.data?.project?.selectedSubstation?.distance);
          setRadiusUnit(res?.data?.data?.project?.radiusUnit);
          // setMinRadius(res?.data?.minRadius);
          // setMaxRadius(res?.data?.maxRadius);
          setFinalRadius(
            `${res?.data?.data?.project?.minRadius} - ${res?.data?.data?.project?.maxRadius} ${res?.data?.data?.project?.radiusUnit}`
          );
          dispatch(setProjectsLoading(false));
          setTimeout(() => {
            setmapLoad(false);
          }, 2000);
        }
      })
      .catch((err) => {
        // console.log("err", err)
        if (err) {
          dispatch(setProjectsLoading(false));
          if (err?.response?.data?.message === "Password required for password-protected sharable link.") {
            setOpenPasswordModal(true);
            // ToastNotification("error", err?.response?.data?.message);
          } else if (
            err?.response?.data?.message ===
            "Invalid password for the sharable link."
          ) {
            setOpenPasswordModal(true);
            ToastNotification("error", err?.response?.data?.message);
          } else {
            ToastNotification("error", err?.response?.data?.message);
          }
        }
      });
  };

  useEffect(() => {
    if (token?.length > 0) fetchProjectDetail();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch data for the project using the project id
  return (
    <>
      {projectsLoading ? (
        <Loader />
      ) : (
        <>
          <HeaderExternal />
          {selectedProject?.hasOwnProperty("projectName") && (
          <div className={styles.main_container}>
            <div>
              <div className={styles.substation_name_container}>
                <p>
                  Last edited on: {convertDateFormat(selectedProject?.date)}{" "}
                </p>
                <h1>{selectedProject?.projectName}</h1>
                <h2>{selectedProject?.comment}</h2>
              </div>
            </div>

            <div className={styles.substation_info}>
              <div className={styles.substation_info_container}>
                <h1>Substation Information</h1>
                <div className={styles.substation_info_div}>
                  <TitleDescription
                    title="Substation"
                    des={selectedProject?.selectedSubstation?.Name}
                  />

                  <TitleDescription
                    title="Status"
                    des={selectedProject?.selectedSubstation?.[getCurrentSeasonUK()]}
                  />

                  <TitleDescription
                    title="Coordinates"
                    des={`${fixed(
                      selectedProject?.selectedSubstation?.Lattitude,
                      4
                    )} , ${fixed(
                      selectedProject?.selectedSubstation?.Longitute,
                      4
                    )}`}
                  />

                  <TitleDescription title="DNO" des={selectedProject?.dno} />
                </div>
              </div>

              <div className={styles.site_voltage_container}>
                <div className={styles.volt_btn_div}>
                  <Button icon={<ElectricIcon />} bgColor="#fff" />
                </div>
                <div className={styles.volt__inner_div}>
                  <p>Site Voltages</p>
                  <h1>{selectedProject?.voltageLine}KV</h1>
                </div>
              </div>

              <div className={styles.demand_headroom_container}>
                <div>
                  <Button icon={<ElectricIcon />} bgColor="#fff" />
                </div>
                <div className={styles.demand_inner_div}>
                  <p>Demand Headroom</p>
                  <h1>{fixed(selectedProject?.demandHeadroom, 2)}MW</h1>
                </div>
              </div>

              <div className={styles.demand_headroom_container}>
                <div>
                  <Button icon={<ElectricIcon />} bgColor="#fff" />
                </div>
                <div className={styles.demand_inner_div}>
                  <p>Generation Headroom</p>
                  <h1>{fixed(selectedProject?.generationHeadroom, 2)}MW</h1>
                </div>
              </div>
            </div>

            <div className={styles.last_main_container}>
              <div className={styles.score_container}>
                <div className={styles.score_card_div}>
                  <h1>IQ Score</h1>
                  <div className={styles.progress_div}>
                    <progress
                      id="file"
                      value={selectedProject?.iqScore}
                      max="100"
                    >
                      {" "}
                      {selectedProject?.iqScore}
                    </progress>
                    <label className={styles.label} for="file">
                      {fixed(selectedProject?.iqScore, 1)}%
                    </label>
                  </div>
                </div>
                <div className={styles.score_card_div}>
                  <h1>Risk Score</h1>
                  <div
                    className={
                      selectedProject?.riskScore > 50
                        ? styles.danger_progress_bar
                        : styles.progress_div
                    }
                  >
                    <progress
                      id="file"
                      value={selectedProject?.riskScore}
                      max="100"
                    >
                      {selectedProject?.riskScore}
                    </progress>

                    <label className={styles.label} for="file">
                      {fixed(selectedProject?.riskScore, 1)}%
                    </label>
                  </div>
                </div>
                <div className={styles.planning__section}>
                  {selectedProject?.planningData !== null &&
                    typeof selectedProject?.planningData !== "undefined" &&
                    Object.keys(selectedProject?.planningData).length > 0 && (
                      <PlanningCard text="Location Planning" />
                    )}
                </div>
              </div>

              <div className={styles.map__container}>
                {!mapLoad && (
                  <MapCard
                    project={selectedProject}
                    distance={distance}
                    radiusUnit={radiusUnit}
                    height="610px"
                    width="38%"
                    borderRadius="16px"
                    zIndex={10}
                    minZoom={10} //to stop the map zoom in and zoom out functionality
                    maxZoom={10} //to stop the map zoom in and zoom out functionality
                    drag={false} //disable the drag functionality
                  />
                )}
              </div>

              <div className={styles.search_criteria_container}>
                <div>
                  <h1>Search Criteria</h1>
                </div>

                <div className={styles.search_info_div}>
                  <TitleDescription
                    title="Location"
                    des={selectedProject?.searchInput}
                  />
                  <TitleDescription title="Radius" des={finalRadius} />
                  <TitleDescription
                    title="Asset Type"
                    des={selectedProject?.assetType}
                  />
                  <TitleDescription
                    title="Project Capacity"
                    des={
                      selectedProject?.capacityUnit === "kW"
                        ? parseFloat(selectedProject?.minCapacity) * 1000 +
                          "kW+ Capacity"
                        : selectedProject?.minCapacity + "MW+ Capacity"
                    }
                  />
                  <TitleDescription
                    title="Quick Selectors"
                    des={
                      selectedProject?.demand && selectedProject?.generation
                        ? "Demand - Generation"
                        : selectedProject?.demand
                        ? "Demand"
                        : selectedProject?.generation
                        ? "Generation"
                        : ""
                    }
                  />
                  <TitleDescription
                    title="Filters"
                    des={
                      selectedProject?.riskAndIQ?.[0] +
                      "-" +
                      selectedProject?.riskAndIQ?.[1]
                    }
                  />
                  <TitleDescription
                    title="Date Created"
                    des={convertDateFormat(selectedProject?.date)}
                  />
                </div>
              </div>
            </div>
          </div>
          )}
          </>
        )}
      <ProjectPasswordModal
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
        password={password}
        setPassword={setPassword}
        fetchProjectDetail={fetchProjectDetail}
      />
    </>
  );
};

export default ExternalProjectDetail;
