import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button/Button";
import styles from "./styles.module.scss";
import { Share, Trash, Move, EditIcon } from "../SVG";
import MoveProjectModal from "../Modals/MoveProjectModal";
import ShareProjectModal from "../Modals/ShareProjectModal";
import RestoreProjectModal from "../Modals/RestoreProjectModal";
import MapCard from "../MapCard";

import RevokeLinkAccessModal from "../Modals/RevokeLinkAccessModal";
import UpdateExpiryDaysModal from "../Modals/UpdateExpiryDaysModal";

const ProjectCard = (props) => {
  const {
    project,
    mapedDate,
    showButton,
    deletet,
    link,
    remainingDays,
    getAllCollection,
    getAllSharedWithUrlProjects,
    selectedProject,
    setSelectedProject,
    setOpenDeleteModal
  } = props;

  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [openRevokeAccessModal, setOpenRevokeAccessModal] = useState(false);
  const [openUpdateExpiryDaysModal, setOpenUpdateExpiryDaysModal] = useState(false);

  const [selectedID, setSelectedID] = useState();
  // const [selectedProject, setSelectedProject] = useState();

  const navigate = useNavigate();

  const handleProjectDetail = (project) => {
    navigate(`/project/${project.id}`);
  };

  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.dateContainer}>
          {project?.date && <p>{mapedDate}</p>}
          <Button
            height="25px"
            bgColor={project?.ProjectStatus?.bgColor}
            color="#fff"
            radius="100px"
            borderColor={project?.ProjectStatus?.bgColor}
            text={project?.ProjectStatus?.name}
          />
        </div>
        <MapCard
          project={project}
          distance={project?.selectedSubstation?.distance}
          radiusUnit={project?.radiusUnit}
          marginTop="10px"
          height="150px"
          width="400px"
          borderRadius="5px"
          zIndex={1}
        />

        <h1 className={styles.heading}> {project?.projectName}</h1>
        <div className={styles.assetContainer}>
          <p>{project?.assetType}</p>
          <div className={styles.dot} />
          {/* <p>{project?.maxRadius}Mi Radius</p> */}
          <p>
            {project?.capacityUnit === "kW"
              ? parseFloat(project?.minCapacity) * 1000 + "kW"
              : project?.minCapacity + "MW"}
          </p>
          <div className={styles.dot} />
          <p>
            {project?.demand && project?.generation
              ? "Demand & Generation"
              : project?.demand
              ? "Demand"
              : project?.generation
              ? "Generation"
              : ""}
          </p>
        </div>
        {deletet === true ? (
          <div className={styles.buttonContainer}>
            <Button
              height="45px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Restore Project"
              handleClick={() => {
                setOpenRestoreModal(true);
                setSelectedProject(project);
              }}
            />
            <Button
              height="45px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="8px"
              borderColor="#F3F4F6"
              text={remainingDays === 0 ? 'Last Day' : remainingDays === 1 ? '1 Day Left' : `${remainingDays} Days Left`}
            />
          </div>
        ) : link === true ? (
          <div className={styles.buttonContainer}>
            <Button
              height="45px"
              bgColor="#FFF5F6"
              color="#EA374A"
              radius="100px"
              borderColor="#FFF5F6"
              text="Remove Link Access"
              handleClick={() => {
                setOpenRevokeAccessModal(true);
                setSelectedProject(project);
              }}
            />
            <div className={styles.buttonContainer__inner}>
              <Button
                height="45px"
                bgColor="#F3F4F6"
                color="#1E232E"
                radius="8px"
                borderColor="#F3F4F6"
                text={remainingDays}
              />
              <Button
                text="Edit"
                icon={<EditIcon fill="#1E232E" />}
                bgColor="#F3F4F6"
                color="#1E232E"
                radius="8px"
                borderColor="#F3F4F6"
                height="45px"
                handleClick={() => {
                  setOpenUpdateExpiryDaysModal(true);
                  setSelectedProject(project);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              height="38px"
              bgColor="#fff"
              color="#1F443A"
              radius="100px"
              borderColor="#1F443A"
              text="Open Project"
              handleClick={() => {
                handleProjectDetail(project);
              }}
            />
            {showButton && (
              <div className={styles.buttonContainer__inner}>
                <Button
                  bgColor="#F3F4F6"
                  radius="100px"
                  icon={<Move />}
                  borderColor="#F3F4F6"
                  handleClick={() => {
                    setSelectedProject(project);
                    setOpenMoveModal(true);
                  }}
                />
                <Button
                  bgColor="#F3F4F6"
                  radius="100px"
                  icon={<Share />}
                  borderColor="#F3F4F6"
                  handleClick={() => {
                    setSelectedProject(project);
                    setOpenShareModal(true);
                  }}
                />
                <Button
                  bgColor="#FFF5F6"
                  radius="100px"
                  icon={<Trash />}
                  borderColor="#FFF5F6"
                  handleClick={() => {
                    setSelectedProject(project);
                    setOpenDeleteModal(true);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <MoveProjectModal
          open={openMoveModal}
          setOpen={setOpenMoveModal}
          projectId={selectedProject?.id}
          getAllCollection={getAllCollection}
        />
        <ShareProjectModal
          open={openShareModal}
          setOpen={setOpenShareModal}
          projectId={selectedProject?.id}
        />
        <RestoreProjectModal
          open={openRestoreModal}
          setOpen={setOpenRestoreModal}
          projectId={selectedProject?.id}
        />
        <RevokeLinkAccessModal
          open={openRevokeAccessModal}
          setOpen={setOpenRevokeAccessModal}
          selectedProject={selectedProject}
          getAllSharedWithUrlProjects={getAllSharedWithUrlProjects}
        />
        <UpdateExpiryDaysModal
          open={openUpdateExpiryDaysModal}
          setOpen={setOpenUpdateExpiryDaysModal}
          selectedProject={selectedProject}
          getAllSharedWithUrlProjects={getAllSharedWithUrlProjects}
          remainingDays={remainingDays}
        />
      </div>
    </>
  );
};

export default ProjectCard;
