export const  capitalizeFirstLetter = (value)=> {
    if (typeof value === 'string') {
        // Replace all hyphens and underscores with spaces
        let formattedString = value.replace(/[-_]+/g, ' ');
        // Split the string into an array of words, capitalize the first letter of each, and join with a space
        return formattedString
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      } else {
        // Return the value as is if it's not a string
        return value;
      }
}