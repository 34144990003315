function ConvertToTitleCase(str) {

  const separator = /[_-]/.test(str) ? /[_-]/ : ' ';
  return str
    .split(separator)
    .map((word) => {
      // Check if the word is all lowercase
      if (/^[a-z]+$/.test(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word; // If the word is already uppercase or mixed case, leave it as is
      }
    })
    .join(" ");
}
export default ConvertToTitleCase;
