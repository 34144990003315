import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { setLeftSideBarOpen } from "../../Components/Redux/reducers/projectsSlice";
import styles from "./styles.module.scss";
import UploadFile from "./UploadFile";
import MappingProject from "./MappingProject";
import Collections from "./Collections";
import LoadingText from "./LoadingText";
import Congratulation from "./Congratulation";
import Analysis from "./Analysis";

const BulkImportProject = () => {
  const [csvData, setCsvData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const dispatch = useDispatch();

  const steps = [
    'UPLOAD FILE',
    'MAPPING',
    'COLLECTION',
    'SUMMARY',
  ];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      // top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1F443A"
        // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1F443A"
        // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? '#1F443A' : '#EDF0EF',
      borderRadius: 1,
    },
  }));

  useEffect(() => {
    dispatch(setLeftSideBarOpen(false));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.feedbackContainer}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={count} alternativeLabel connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {count === 0 && (
        <>
          {showAnalysis ? (
            <Analysis
              csvData={csvData}
              setCsvData={setCsvData}
              setFileName={setFileName}
              count={count}
              setCount={setCount}
              setShowAnalysis={setShowAnalysis}
            />
          ) : (
            <UploadFile
              csvData={csvData}
              setCsvData={setCsvData}
              fileName={fileName}
              setFileName={setFileName}
              count={count}
              setCount={setCount}
              loading={loading}
              setLoading={setLoading}
              setShowAnalysis={setShowAnalysis}
            />
          )}
        </>
      )}
      {count === 1 && (
        <MappingProject
          csvData={csvData}
          count={count}
          setCount={setCount}
        />
      )}
      {count === 2 && (
        <Collections
          count={count}
          setCount={setCount}
          loading={loading}
          setLoading={setLoading}
          selectedCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
        />
      )}

      {count === 3 && (
        <LoadingText
          csvData={csvData}
          count={count}
          setCount={setCount}
          loading={loading}
          setLoading={setLoading}
          selectedCollection={selectedCollection}
        />
      )}

      {count === 4 && (
        <Congratulation
          setCount={setCount}
          setCsvData={setCsvData}
          setFileName={setFileName}
          setShowAnalysis={setShowAnalysis}
        />
      )}
    </div>
  );
};
export default BulkImportProject;
