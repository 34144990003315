import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import PolygonFeatureModal from "./PolygonFeatureModal";

const style = {
  position: "absolute",
  width: 230,
  height: 330,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  overflow: "auto",
  borderRadius: 1,
};

const PolygonPopup = (props) => {
  const { setOpen, open, data, topPosition, leftPosition } = props;
  const [polygonFeatureModal, setPolygonFeatureModal] = useState(false);

  const handleClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handlePolygonLayer = () => {
    setPolygonFeatureModal(!polygonFeatureModal);
  };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{ ...style, top: `${topPosition}px`, left: `${leftPosition}px` }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div onClick={handleClose}>
              <CancelIcon />
            </div>
          </div>

          <Typography
            variant="h6"
            component="h6"
            sx={{ mb: 1, fontWeight: "bold", pl: 1 }}
          >
            1 feature selected
          </Typography>
          <Box
            sx={{
              display: "flex",
              pl: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                height: "50px",
                width: "8px",
                background: "blue",
              }}
            ></Box>
            <Box sx={{ mb: 2 }}>
              {data?.dataset}
              <Typography
                onClick={handlePolygonLayer}
                sx={{
                  cursor: "pointer",
                  mt: 0.5,
                  textDecoration: "underline",
                  color: "#123499",
                  fontWeight: "500",
                }}
              >
                {data?.entity}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
      <PolygonFeatureModal
        open={polygonFeatureModal}
        setOpen={setPolygonFeatureModal}
        data={data}
      />
    </div>
  );
}
export default PolygonPopup