import React from "react";
import ReactDOM from "react-dom/client";
// import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./Components/Redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // <Auth0Provider
  //   // domain="dev-vdpo21kx7ymhb74j.us.auth0.com"
  //   clientId="DIvcSAjNEExGycXH7OsxvxS7XZD2F9lZ"
  //   domain="login.voltquant.uk"
  //   // clientId="4071f22241761903c11460c949ab5b4340f2dd5a979f8b87cea55028f4a337ac"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin,
  //   }}
  // >
  <Provider store={Store}>
    <App />
  </Provider>
  // </Auth0Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// ia @IA
