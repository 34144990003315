import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { setActiveLayersArray } from "../Redux/reducers/datasetSlice";
import { useSelector } from "react-redux";

const SpeedLegend = () => {
  const activeLayers = useSelector((state) => state.dataset.activeLayers);
  const [selectedOption, setSelectedOption] = useState("roadspeed_indicative");
  const dispatch = useDispatch();
  const isSpeedLimit = activeLayers.includes("roadspeed_indicative");
  const legendData = [
    { color: "#24c91c", label: isSpeedLimit ? "20mph" : "0-20mph" },
    { color: "#50a116", label: isSpeedLimit ? "30mph" : "21-30mph" },
    { color: "#7c7911", label: isSpeedLimit ? "40mph" : "31-40mph" },
    { color: "#a8500b", label: isSpeedLimit ? "50mph" : "41-50mph" },
    { color: "#d32806", label: isSpeedLimit ? "60mph" : "50-60mph" },
    { color: "#ff0000", label: isSpeedLimit ? ">60mph" : "60-100mph" },
    // Add more legend items as neede
  ];
  const roadSpeedLayers = [
    "roadspeed_indicative",
    "roadspeed_avgday",
    "roadspeed_avgnight",
  ];
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const updatedActiveLayers = activeLayers.map((layer) =>
      roadSpeedLayers.includes(layer) ? event.target.value : layer
    );
    dispatch(setActiveLayersArray({ activeLayersArray: updatedActiveLayers }));
  };
  const items = [
    { name: "Speed Limit", layerName: "roadspeed_indicative" },
    { name: "Day Avg Speed", layerName: "roadspeed_avgday" },
    { name: "Night Avg Speed", layerName: "roadspeed_avgnight" },
  ];
  return (
    <>
      <div>
        {items.map((item, i) => (
          <div>
            <label>
              <input
                type="radio"
                name="options"
                value={item.layerName}
                checked={selectedOption === item.layerName}
                onChange={handleOptionChange}
              />
              {item.name}
            </label>
          </div>
        ))}
      </div>
      {legendData.map((item, index) => (
        <div key={index} className="legend-item">
          <div
            className="legend-color"
            style={{ backgroundColor: item.color }}
          ></div>
          <div className="legend-label">{item.label}</div>
        </div>
      ))}
    </>
  );
};

export default SpeedLegend;