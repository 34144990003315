import React, { useEffect, useRef, useState } from "react";
// import { useControl } from 'react-map-gl';
import { FormControlLabel, Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import './myMap.css';

const LayerToggleControl = ({ onMapLoad, visible, setVisible }) => {
    const modalRef = useRef(null);

    const control = {
        onAdd(map) {
            this._map = map;
            this._container = document.createElement('div');
            this._container.className = 'mapboxgl-ctrl';
            const button = document.createElement('button');
            button.classList.add('togglerButton');
            button.textContent = visible ? 'Hide VQ Layer' : 'Show VQ Layer';
            button.onclick = () => {
                // onMapLoad();
                setVisible((prev) => !prev);
                // const mapLayers = map.getStyle().layers;
                // console.log(mapLayers);  
                // mapLayers.forEach(layer => {
                //     if (layer.id.startsWith('power_') || layer.id.startsWith('petro')) {
                //     map.setLayoutProperty(layer.id, 'visibility', visible ? 'none' : 'visible');
                //     }
                // });
                if (button.textContent === 'Hide VQ Layer') {
                    button.textContent = 'Show VQ Layer';
                } else {
                    button.textContent = 'Hide VQ Layer';
                }
            };
            this._container.appendChild(button);
            return this._container;
        },
        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        }
    };

    // Use the useControl hook to add/remove the control
    // useControl(() => control);

    const CustomColorSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#13D92A",
            "&:hover": {
                backgroundColor: alpha("#13D92A", theme.palette.action.hoverOpacity),
            },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#13D92A",
        },
    }));

    // Custom styled FormControlLabel with background
    const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
        width: "160px",
        height: '30px',
        backgroundColor: "#fff",
        padding: 1,
        borderRadius: '8px',
        marginTop: "15px",
        marginLeft: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        "& .MuiFormControlLabel-label": {
            fontFamily: 'Eudoxus Sans', // Set font family
            fontSize: "12px", // Set font size to 12px
            fontWeight: 500, // Set font weight
            lineHeight: "16px", // Set line height
            textAlign: "left", // Set text align
        },
    }));

    const handleEVChange = (event) => {
        setVisible((prev) => !prev);
        // if (event.target.checked) {
        //   dispatch(appendNewLayer({ layerName: "ncpr" }));
        // } else {
        //   dispatch(removeLayer({ layerName: "ncpr" }));
        // }
    };

    return (
        <div ref={modalRef}>
            <div className="togglerButton">
                <CustomFormControlLabel
                    control={
                        <CustomColorSwitch defaultChecked={visible} onChange={handleEVChange} />
                    }
                    label="Show VQ Layer"
                    labelPlacement="start"
                />
            </div>
        </div>
    );
};

export default LayerToggleControl;
