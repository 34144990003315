import React, { useState } from 'react';
import styles from "./styles.module.scss";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';

// import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MapLayersButtons = (props) => {
  const {
    layersButtonsArray,
    handleMapLayerButtonClick,
    buttonText,
    setButtonId,
  } = props;

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  return (
    <div>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>Active</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.layers_buttons_Container}>
            <div className={styles.layers_buttons}>
              <button
                onClick={(event) => {
                  handleMapLayerButtonClick(event, 'Active');
                  setButtonId('ACTIVE');
                }}
                className={
                  buttonText === 'Active'
                    ? styles.selectedButton
                    : styles.unselectedButton
                }
                id={'ACTIVE'}
              >
                Active
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>Land</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.layers_buttons_Container}>
            <div className={styles.layers_buttons}>
              {layersButtonsArray.map((item) => (
                (item.text === 'England' || item.text === 'Scotland' || item.text === 'Wales') && (
                  <button
                    style={{}}
                    key={item.id}
                    onClick={(event) => {
                      handleMapLayerButtonClick(event, item.text);
                      setButtonId(item.id);
                    }}
                    className={
                      item.text === buttonText
                        ? styles.selectedButton
                        : styles.unselectedButton
                    }
                    id={item.id}
                  >
                    {item.text}
                  </button>
                )
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>Infrastructure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.layers_buttons_Container}>
            <div className={styles.layers_buttons}>
              {layersButtonsArray.map((item) => (
                (item.text !== 'England' && item.text !== 'Scotland' && item.text !== 'Wales' && item.text !== 'Active') && (
                  <button
                    style={{}}
                    key={item.id}
                    onClick={(event) => {
                      handleMapLayerButtonClick(event, item.text);
                      setButtonId(item.id);
                    }}
                    className={
                      item.text === buttonText
                        ? styles.selectedButton
                        : styles.unselectedButton
                    }
                    id={item.id}
                  >
                    {item.text}
                  </button>
                )
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div >
  )
};

export default MapLayersButtons;