import { createSlice } from "@reduxjs/toolkit";

const popUpStationSlice = createSlice({
    name: " popupSubStation",
    initialState: {
      
      popupSubStation: null,
     
    },
    reducers: {
      setPopupSubStation: (state, action) => {
        return {
          ...state,
          popupSubStation: action.payload
        }
      },
    
    },
  });
  export const {
    setPopupSubStation,
  } = popUpStationSlice.actions;
  export default popUpStationSlice.reducer;