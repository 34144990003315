import React, { useState } from 'react'
import styles from './styles.module.scss';
import { useDispatch, useSelector } from "react-redux";

import Button from '../../Common/Button/Button'
import { Plus, Setting } from '../../SVG'
import ManageCollectionsModal from '../../Modals/ManageCollectionsModal';
import CreateCollectionsModal from '../../Modals/CreateCollectionsModal';
import { setSelectedCollections } from "../../Redux/reducers/projectsSlice";
const TabButtons = ({ getAllCollection }) => {
    const [collectionModal, setCollectionModal] = useState(false)
    const [createCollectionModal, setCreateCollectionModal] = useState(false)
    const dispatch = useDispatch();

    const { collections, selectedCollection } = useSelector((state) => state.projects);

    return (
        <>
            <div className={styles.tabContainer}>
                <div className={styles.tabContainer__collection}>
                    {collections?.map((data, index) => {
                        return (
                            <Button
                                key={index}
                                height='45px'
                                bgColor={data?.title === selectedCollection?.title ? '#F3F4F6' : '#fff'}
                                color={data?.title === selectedCollection?.title ? '#1F443A' : '#1E232E'}
                                radius='100px'
                                borderColor={data?.title === selectedCollection?.title ? '#F3F4F6' : '#DCDFE5'}
                                text={data?.title} 
                                length={data?.projectCount}
                                handleClick={() => dispatch(setSelectedCollections(data))}  
                            />
                                
                        )
                    })}
                    {/* <Button height='45px' bgColor='#F3F4F6' color='#1F443A' radius='100px' borderColor='#F3F4F6' text='Default Collection' length={defaultCollection} /> */}
                    {/* <Button height='45px' bgColor='#fff' color='#1E232E' radius='100px' borderColor='#DCDFE5' text='New Collection ' length={newCollection} /> */}
                </div>
                <div className={styles.tabContainer__tab}>
                    <Button height='45px' bgColor='#fff' color='#1E232E' radius='12px' borderColor='#DCDFE5' icon={<Plus fill='#1E232E' />} text='New Collection' handleClick={() => setCreateCollectionModal(true)} />
                    <Button height='45px' bgColor='#fff' color='#1E232E' radius='12px' borderColor='#DCDFE5' icon={<Setting />} text='Manage Collections' handleClick={() => setCollectionModal(true)} />
                </div>
            </div>
            <ManageCollectionsModal
                open={collectionModal}
                setOpen={setCollectionModal}
            />
            <CreateCollectionsModal
                open={createCollectionModal}
                setOpen={setCreateCollectionModal}
                getAllCollections={getAllCollection}
            />
        </>
    )
}

export default TabButtons
