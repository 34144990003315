import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import PaymentIcon from "@mui/icons-material/Payment";
import BugReportIcon from "@mui/icons-material/BugReport";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { ToastNotification } from "../Utils/ToastNotifications";
import FeedbackModal from "../Modals/Feedback";
import BugReportModal from "../Modals/BugReport";
import ProfileModal from "../Modals/ProfileModal";
import { modifyPlan } from "../Api/API";
import { useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [stripe, setStripe] = useState(null);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openBugReport, setOpenBugReport] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const open = Boolean(anchorEl);
  const { userDetail } = useSelector((state) => state.users);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileModal = () => {
    handleClose();
    setOpenProfileModal(true);
  };

  const bugModal = () => {
    handleClose();
    setOpenBugReport(!openBugReport);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const feedbackModal = () => {
    handleClose();
    setOpenFeedback(!openFeedback);
  };

  const handleSubscription = () => {
    const email = userDetail.user.email;
    let body = { email: email };
    modifyPlan(body)
      .then((response) => {
        if (response.session?.url?.length > 0) {
          handleClose();
          const newTab = window.open(response.session.url, "_blank");
          newTab.focus();
        }
      })
      .catch((err) => {
        console.error("Error subscription:", err);
        ToastNotification("error", "Error: Subscription");
      });
  };

  const handleLogout = () => {
    window.location.href = "/login";
    window.localStorage.clear();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        onClick={handleClick}
        style={{
          // textTransform: "capitalize",
          color: "#fff",
          fontSize: "14px",
          // background: 'rgb(227, 245, 230)',
          background: "#1F443A",
          border: "1px solid #659A7F",
          height: "45px",
          borderRadius: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer"
        }}
      >
        <img
          src={
            userDetail?.user?.image ||
            "https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"
          }
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "40px",
            marginRight: "8px",
          }}
          alt="..."
        />
        {userDetail?.user?.first_name ? (
          <div className="sub-title-2">{userDetail?.user?.first_name}</div>
        ) : (
          "Profile Name"
        )}
        <KeyboardArrowDownIcon />
      </button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={profileModal}>
          <AccountCircleSharpIcon />
          Account
        </MenuItem>
        <>
          <MenuItem onClick={handleSubscription}>
            <PaymentIcon />
            Subscription
          </MenuItem>
          {/* <MenuItem onClick={bugModal}>
            <BugReportIcon />
            Report a Bug
          </MenuItem>
          <MenuItem onClick={feedbackModal}>
            <FeedbackIcon />
            Feedback
          </MenuItem> */}
          <MenuItem onClick={handleLogout} disableRipple>
            <LogoutSharpIcon />
            Logout
          </MenuItem>
        </>
      </StyledMenu>
      <ProfileModal
        openProfileModal={openProfileModal}
        setOpenProfileModal={setOpenProfileModal}
      />
      <BugReportModal
        openBugReportModal={openBugReport}
        setOpenBugReportModal={setOpenBugReport}
      />
      <FeedbackModal
        openFeedbackModal={openFeedback}
        setOpenFeedbackModal={setOpenFeedback}
      />
    </div>
  );
};
export default Profile;
