import React from 'react';
import styles from './styles.module.scss';
const LoginRightSide = () => {
    return (
        <div className={styles.signup__rightSide}>
            <img src='https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/login_circle.svg' alt='LoginCircle' />
            <p className={styles.signup__rightSide__para}>© {new Date().getFullYear()} powered and secured by VOLTQUANT.</p>
        </div>
    );
};

export default LoginRightSide;
