import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Close, Private, Public } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "../../Utils/ToastNotifications";
import styles from "./styles.module.scss";
import { shareProject, generateSharableLink } from "../../Api/API";
import CustomSelect from "../../Common/CustomSelect";
import InputSelect from "../../Common/InputSelect";
import TextInput from "../../Common/TextInput";
import { ConvertDateIntoISOString } from "../../Utils/Formats";

const ShareProjectModal = (props) => {
  const { open, setOpen, projectId } = props;
  const [permissionUrl, setPermissionUrl] = useState('Public Access');
  const [expiryDate, setExpireDate] = useState();
  const [password, setPassword] = useState('');
  const [projectUrl, setProjectUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const { protocol, hostname, port } = window.location;
  const environmentURL = `${protocol}//${hostname}${port ? `:${port}` : ''}` //environment url


  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const handleClose = (e) => {
    setOpen(false);
    setExpireDate('')
    setPassword('')
    setProjectUrl('')
    setSelectedOption(null);
    e.stopPropagation();
  };

  const handleShareProject = () => {
    if (selectedOption === null) {
      ToastNotification("warn", "Please select at least one user");
      return;
    } else {
      const userEmails = selectedOption.map((user) => user.value);
      const body = {
        emails: userEmails,
      };
      shareProject(projectId, body).then((res) => {
        // if (res.success) {
        if (res.message) {
          setOpen(false);
          setSelectedOption(null);
          ToastNotification("success", "Project shared");
        }
      });
    }
  };

  const handleGenerateSharableLink = () => {
    if (expiryDate?.trim() === '') {
      ToastNotification("warn", "Please select an expiry date");
      return;
    } else if (permissionUrl === "Restricted Access" && password === '') {
      ToastNotification("warn", "Please enter a password");
      return;
    } else if (permissionUrl === "Restricted Access" && password.length < 6) {
      ToastNotification("warn", "Password must be at least 6 characters");
      return;
    } else {
      const linkType = permissionUrl === "Public Access" ? "public" : "password"
      const linkPassword = permissionUrl === "Restricted Access" ? password : null

      const date = ConvertDateIntoISOString(expiryDate)

      const body = {
        linkExpiry: date, // ( days )
        linkType: linkType, // public || password
        projectId: projectId,
        linkPassword: linkPassword,
        path: environmentURL
      };

      generateSharableLink(body).then((res) => {
        console.log('res', res)
        if (res.success) {
          setProjectUrl(res?.data)
          ToastNotification("success", res?.message);
        }
      });
    }
  }

  useEffect(() => {
    if (open) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      const formattedDate = date.toISOString().split('T')[0];

      setExpireDate(formattedDate)
    }
  }, [open])

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1);
  const minDateString = minDate.toISOString().split('T')[0];
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const maxDateString = maxDate.toISOString().split('T')[0];

  return (
    <div>
      {/* {copied && ToastNotification("success", "URL Copied")} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.shareProjectContainer}>
          <div style={{ padding: '20px' }}>
            <div className={styles.headingContainer}>
              <h1 className={styles.heading}>Share Project</h1>
              <Close onClick={handleClose} />
            </div>
            <p className={styles.para}>Share <span>My Project</span> by email or link</p>
            <div className={styles.selectContainer}>
              <div style={{ width: "450px" }}>
                <CustomSelect
                  // options={options}
                  onChange={handleChange}
                  isMulti={true}
                  isClearable={true}
                  placeholder="Email, comma separated"
                />
              </div>
              <Button
                height="40px"
                bgColor="#1F443A"
                color="#fff"
                radius="12px"
                borderColor="#1F443A"
                handleClick={handleShareProject}
                text="Invite"
                padding='19px 32px'
                disable={true}
              />
            </div>
          </div>
          <div className={styles.line} />
          <div style={{ padding: '20px' }}>
            <div className={styles.headingContainer}>
              <h1 className={styles.heading} style={{ fontSize: '18px' }}>Link Access</h1>
            </div>
            <div className={styles.permissionContainer}>
              <div className={styles.accessContainer}>
                <Button
                  bgColor="#F3F4F6"
                  radius="100px"
                  icon={permissionUrl === "Public Access" ? <Public /> : <Private />}
                  borderColor="#F3F4F6"
                  padding='8px'
                />
                <div>
                  <InputSelect
                    width="170px"
                    m={0}
                    variant="standard"
                    value={permissionUrl}
                    handler={(e) => {
                      setPermissionUrl(e.target.value);
                      setProjectUrl('')
                    }}
                    option={["Public Access", "Restricted Access"]}
                  />
                  {permissionUrl === "Public Access" ?
                    <p className={styles.permission_detail}>Anyone with the link can access</p>
                    :
                    <p className={styles.permission_detail}>Anyone with the link and password can access</p>
                  }
                </div>
              </div>
              {/* {permission === "Restricted Access" && (
                <p onClick={() => setShowPasswordField(true)} className={styles.setPassword}>Set Password</p>
              )} */}
            </div>
            <div className={styles.passwordContainer}>
              {permissionUrl === "Restricted Access" ?
                <div style={{ width: '330px' }}>
                  <TextInput
                    type="password"
                    value={password}
                    setValue={setPassword}
                    placeholder="Password"
                    margin='0px'
                  />
                </div>
                :
                <div style={{ width: '330px' }} />
              }
              <div style={{ width: '120px' }}>
                <TextInput
                  type="date"
                  min={minDateString}
                  max={maxDateString}
                  value={expiryDate}
                  setValue={setExpireDate}
                  placeholder="Expires in days"
                  margin='0px'
                />
              </div>
              <Button
                height="40px"
                bgColor="#F3F4F6"
                color="#1F443A"
                radius="12px"
                borderColor="#DCDFE5"
                handleClick={handleGenerateSharableLink}
                text="Set"
                padding='19px 32px'
              />
            </div>
            <div className={styles.passwordContainer}>
              <TextInput
                type="text"
                value={projectUrl}
                placeholder="url"
                margin='0px'
                readOnly={true}
              />
              <CopyToClipboard text={projectUrl} onCopy={() => ToastNotification("success", "URL Copied")}>
                <button disabled={projectUrl?.length === 0} style={{ cursor: projectUrl?.length === 0 ? "not-allowed" : "pointer" }}>Copy</button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ShareProjectModal;