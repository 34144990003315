import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Button from '../../Common/Button/Button'
import styles from "./styles.module.scss";
// import { Trash } from "../../SVG";
import SearchInput from "../../Common/SearchInput";
import { ColumnView, ListView, Setting } from "../../SVG";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { getProjectStatuses } from "../../Api/API";
import { Checkbox } from "@mui/material";
import TextInput from "../../Common/TextInput";

const Filters = (props) => {
  const {
    searchByName,
    setSearchByName,
    setSelectedAssetType,
    selectedAssetType,
    selectedAlphabeticSorting,
    setSelectedAlphabeticSorting,
    handleFiltersProject,
    columnView,
    setColumnView,
    listView,
    setListView,
    headromType,
    setHeadromType,
    status,
    setStatus,
    headCells,
    setHeadCells,
    minCapacity,
    setMinCapacity,
    maxCapacity,
    setMaxCapacity,
    minRadius,
    setMinRadius,
    maxRadius,
    setMaxRadius,
    setRowsPerPage
  } = props;
  const location = useLocation();
  // const navigate = useNavigate();
  const [statuses, setStatuses] = useState([]);

  const [columnMenu, setColumnMenu] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);

  const open = Boolean(columnMenu);
  const openFilter = Boolean(filterMenu);

  const handleClick = (event) => {
    setColumnMenu(event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setFilterMenu(event.currentTarget);
  };

  const handleClose = () => {
    setColumnMenu(null);
  };

  const handleFilterClose = () => {
    setFilterMenu(null);
  };

  const assetTypes = [
    { value: "None", label: "None" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Renewable Energy", label: "Renewable Energy" },
    { value: "EV Infrastructure", label: "EV Infrastructure" },
    { value: "Energy Storage", label: "Energy Storage" },
  ];

  const alphabeticSorting = [
    { value: "None", label: "None" },
    { value: "A - Z", label: "A - Z" },
    { value: "Z - A", label: "Z - A" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  const headromArray = [
    { value: "None", label: "None" },
    { value: "Demand", label: "Demand" },
    { value: "Generation", label: "Generation" },
    { value: "Both", label: "Both" }
  ];

  const fetchProjectStatus = () => {
    getProjectStatuses()
      .then((res) => {
        if (res.code === 400) {
          ToastNotification("error", "Error: Getting status");
        }
        if (res.success) {
          const responseData = res?.data?.premadeStatuses;
          setStatuses(responseData);
          // handleProjectStatusClick(responseData);
        }
      })
      .catch((err) => {
        if (err) {
          ToastNotification("error", "Error: Getting status");
        }
      });
  };

  const handleUpdateColumn = (data, index) => {

    const filterColumn = headCells?.filter((item) => item.label === data.label)

    const updateColumn = {
      id: filterColumn?.[0]?.id,
      numeric: filterColumn?.[0]?.numeric,
      disablePadding: filterColumn?.[0]?.disablePadding,
      checked: (filterColumn?.[0]?.checked === true ? false : true),
      label: filterColumn?.[0]?.label,
      minWidth: filterColumn?.[0]?.minWidth
    }

    const updatedArray = headCells?.map(item => item.id === filterColumn?.[0]?.id ? updateColumn : item)
    setHeadCells(updatedArray)
  }

  useEffect(() => {
    fetchProjectStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <div className={styles.filterContainer}>
        <Button
          height='45px'
          bgColor='#fff'
          color='#1E232E'
          radius='12px'
          borderColor='#DCDFE5'
          // icon={<Setting />}
          text='Filter By'
          handleClick={handleFilterClick}
        />
        {location.pathname === "/projects" && (
          <div className={styles.viewContainer}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setColumnView(true);
                setListView(false);
                setRowsPerPage(9)
              }}>
              <ColumnView
                fill={columnView ? '#1F443A' : 'white'}
                stroke={columnView ? 'white' : '#1F443A'}
                border={columnView ? '#1F443A' : '#DCDFE5'}
              />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setColumnView(false);
                setListView(true)
              }}>
              <ListView
                fill={listView ? '#1F443A' : 'white'}
                stroke={listView ? 'white' : '#1F443A'}
                border={columnView ? '#1F443A' : '#DCDFE5'}
              />
            </div>
            {listView &&
              <Button
                height='45px'
                bgColor='#fff'
                color='#1E232E'
                radius='12px'
                borderColor='#DCDFE5'
                icon={<Setting />}
                text='Customize Columns'
                handleClick={handleClick}
              />
            }
          </div>
        )}
      </div>

      <Menu
        id="basic-menu1"
        anchorEl={filterMenu}
        open={openFilter}
        onClose={handleFilterClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div style={{ padding: '10px' }}>
          <SearchInput
            value={searchByName}
            setValue={setSearchByName}
            placeholder="Search Project"
            width="226px"
          />
          <FormControl sx={{ mt: 2, width: '257.5px' }} size="small">
            <InputLabel id="demo-select-small-label">Asset Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedAssetType}
              label="Asset Type"
              onChange={(e) => setSelectedAssetType(e.target.value)}
              sx={{ borderRadius: '8px', height: '42px' }}
            >
              {assetTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl sx={{ mt: 2, width: '257.5px' }} size="small">
            <InputLabel id="demo-select-small-label">
              Headrom Type
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={headromType}
              label="Headrom Type"
              onChange={(e) => setHeadromType(e.target.value)}
              sx={{ borderRadius: '8px', height: '42px' }}
            >
              {headromArray.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl sx={{ mt: 2, width: '257.5px' }} size="small">
            <InputLabel id="demo-select-small-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
              sx={{ borderRadius: '8px', height: '42px' }}
            >
              <MenuItem value="None">
                None
              </MenuItem>
              {statuses?.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl sx={{ mt: 2, mb: 2, width: '257.5px' }} size="small">
            <InputLabel id="demo-select-small-label">
              Sort By
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedAlphabeticSorting}
              label="Sort By"
              onChange={(e) => setSelectedAlphabeticSorting(e.target.value)}
              sx={{ borderRadius: '8px', height: '42px' }}
            >
              {alphabeticSorting.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <div style={{ width: '257.5px' }}>
            <TextInput
              type="text"
              value={minCapacity}
              setValue={setMinCapacity}
              placeholder="Minimum Project Capacity"
              borderRadius='8px'
            />
            <TextInput
              type="text"
              value={maxCapacity}
              setValue={setMaxCapacity}
              placeholder="Maximum Project Capacity"
              borderRadius='8px'
            />
            <TextInput
              type="text"
              value={minRadius}
              setValue={setMinRadius}
              placeholder="Minimum Distance"
              borderRadius='8px'
            />
            <TextInput
              type="text"
              value={maxRadius}
              setValue={setMaxRadius}
              placeholder="Maximum Distance"
              borderRadius='8px'
            />

          </div>
          {(location.pathname === "/projects" || location.pathname === "/shared-projects" || location.pathname.split("/")[1] === "share-collection") && (
            <Button
              height='42px'
              bgColor='#1F443A'
              color='#fff'
              radius='10px'
              borderColor='#1F443A'
              text='Apply Filter'
              handleClick={() => {
                handleFiltersProject();
                setFilterMenu(null);
              }}
            />
          )}
        </div>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={columnMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {headCells?.map((item, index) => {
          return (
            <MenuItem
              key={index}
            // onClick={() => handleUpdateStatue(item)}
            >
              <div onClick={() => handleUpdateColumn(item, index)}>
                <Checkbox
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={item?.checked}
                  // onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
                {item.label}
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  );
};
export default Filters;
