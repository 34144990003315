import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Logo from "./../../../Images/Logo1.svg";
import { ToastNotification } from "../../../Components/Utils/ToastNotifications";
import LoginRightSide from "./LoginRightSide";
import Footer from "./Footer";
import styles from "./styles.module.scss";
import { setUserDetail } from "../../../Components/Redux/reducers/usersSlice";
import { baseURL } from "../../../Components/Api/instance";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required*"),
    password: Yup.string().required("Password is Required*"),
  });

  const loginUser = (values) => {
    const URL = `${baseURL}/user/login`;
    // const URL="http://localhost:3001/api/v1/user/login"
    values.email=values?.email?.toLowerCase()
    axios
      .post(URL, values)
      .then((response) => {
        ToastNotification("success", "Login completed");
        localStorage.setItem("token", JSON.stringify(response?.data?.data));
        dispatch(setUserDetail(response?.data?.data));

        console.log("response", response);
        navigate("/");
      })
      .catch((e) => {
        ToastNotification("error", e?.response?.data?.message);
        console.log("error", e);
      });
  };

  return (
    <div className={styles.signup}>
      <div className={styles.signup__container}>
        <img src={Logo} alt="logo vector" />

        <h1 className={styles.signup__container__heading}>
          Sign in to your account
        </h1>
        <p className={styles.signup__container__para}>
          Don't have an account? &nbsp;
          <NavLink to="/signup">Sign Up</NavLink>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={loginUser}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
            } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  value={values.email}
                  onBlur={handleBlur}
                  error={errors.email}
                  required={false}
                  style={{
                    borderColor:
                      errors.email && touched.email ? "red" : "#C5CAD4",
                  }}
                />
                {errors.email && touched.email && (
                  <div className={styles.formikError}>{errors.email}</div>
                )}

                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  error={errors.password}
                  style={{
                    borderColor:
                      errors.password && touched.password ? "red" : "#C5CAD4",
                  }}
                />
                {errors.password && touched.password && (
                  <div className={styles.formikError}>{errors.password}</div>
                )}
                <button type="submit">Sign In</button>
              </form>
            );
          }}
        </Formik>
        <p className={styles.signup__container__link}>
          <NavLink to="/reset-password">
            Forgot Password?
          </NavLink>
        </p>
        <Footer />
      </div>
      <LoginRightSide />
    </div>
  );
};

export default LoginForm;
