import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ handleLocation, searchValue,onKeyPress, placeholder,searchType }) => {
  const geocodeLocation = (e) => {
    const { value } = e.target;
    handleLocation(value);
  };
  return (
    <Paper
      component="form"
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#FDFFFD",
        borderRadius: "8px",
        border: "1px solid rgba(0, 0, 0, 0.3)",
      }}
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 305.5 }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={searchValue}
        onChange={geocodeLocation}
        onKeyDown={(e)=>onKeyPress(e)}
        placeholder={placeholder}
        inputProps={{ "aria-label": "search google maps" }}
        disabled={searchType==='Draw Polygon'}
      />
    </Paper>
  );
}
export default SearchBar