import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    appButtonColor: "#1F443A", // Green color of figma design
    appButtonColorDisable: "#FDFFFD", // Off white color of figma design
    appButtonOutlineColor: "#4B4F58", // Grey color for outline
  },
  typography: {
    fontFamily: "Eudoxus sans",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
  },
});

const AppButton = ({ color, text, onClick, variant, id, isSelected }) => {
  const { palette, typography } = theme;
  const buttonColor = color || "secondary";

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const CustomButton = ({ variant }) => {
    const paddingSize = variant === "large" ? "15px 50px" : "10px 17px";
    const fontSizeLarge = variant === "large" ? 16 : typography.fontSize;
    const backgroundColorLarge =
      variant === "large" ? "#F3F4F6" : palette.appButtonColorDisable;

    return (
      <Stack spacing={2} direction="row">
        <Button
          color={buttonColor}
          id={id}
          variant={variant || "contained"}
          isSelected={isSelected}
          style={{
            backgroundColor: isSelected
              ? palette.appButtonColor
              : backgroundColorLarge,
            color: isSelected
              ? palette.appButtonColorDisable
              : '#4B4F58',
            fontFamily: typography.fontFamily,
            fontSize: fontSizeLarge, // Set the font size here
            fontWeight: typography.fontWeight,
            textTransform: typography.textTransform,
            border: "none",
            boxShadow: "none",
            outline: `1px solid #F3F4F6`,
            padding: paddingSize,
            borderRadius: "12px",
            width: '100%'
          }}
          onClick={handleButtonClick}
          size={variant === "large" ? "large" : "medium"}
        >
          {text}
        </Button>
      </Stack>
    );
  };

  return <CustomButton variant={variant} />;
};

export default AppButton;
