import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Logo from "./../../../../Images/Logo1.svg";
import { ToastNotification } from "../../../../Components/Utils/ToastNotifications";
import LoginRightSide from "../LoginRightSide";
import styles from "../styles.module.scss";
import { baseURL } from "../../../../Components/Api/instance";

const SendEmail = (props) => {
  const { email, setEmail, count, setCount } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      ToastNotification("warn", "Email is required");
      return;
    } else if (!validateEmail(email)) {
      ToastNotification("warn", "Email is incorrect");
      return;
    } else {
      const formData = {
        username: email,
      };
      axios
        .post(
          `${baseURL}/user/forgot-password`,
          formData
        )
        .then((res) => {
          ToastNotification("success", "Email sent");
          setCount(count + 1);
        })
        .catch((e) => {
          ToastNotification("error", e?.response?.data?.message);
          console.log("error", e);
        });
    }
  };
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className={styles.signup}>
      <div className={styles.signup__container}>
        <img src={Logo} alt="logo vector" />

        <h1 className={styles.signup__container__heading}>
          Forgot your Password?
        </h1>
        <p className={styles.signup__container__para}>
          Enter your email, we will send you the confirmation code.
        </p>

        <form>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            value={email}
          />
          <button type="submit" onClick={handleSubmit}>
            Send email
          </button>
        </form>
        <p className={styles.signup__container__link}>
          <NavLink to="/login">
            Back to Sign In
          </NavLink>
        </p>
      </div>
      <LoginRightSide />
    </div>
  );
};

export default SendEmail;
