import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { revokeProjectLinkAccess } from "../../Api/API";
import { ToastNotification } from "../../Utils/ToastNotifications";

const RevokeLinkAccessModal = (props) => {
  const { open, setOpen, selectedProject, getAllSharedWithUrlProjects } = props;

  const revokeAccess = () => {
    revokeProjectLinkAccess(selectedProject?.id).then((res) => {
      if (res.success) {
        getAllSharedWithUrlProjects()
        setOpen(false);
        ToastNotification("success", "Project access revoked successfully");
      }
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.deleteContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>
              Are you sure you want to revoke access to this project?
            </h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>
            This action will revoke the access of this project permanently.
          </p>
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={() => setOpen(false)}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Revoke Access"
              handleClick={revokeAccess}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default RevokeLinkAccessModal;
