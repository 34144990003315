import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, isPaidUser }) => {
  //   console.log(isAuthenticated,isPaidUser,"private")
  if (isAuthenticated && !isPaidUser) {
    console.log(isAuthenticated, isPaidUser, "private");

    return <Navigate to="/submitRequest" replace />;
  }
  if (!isAuthenticated || !isPaidUser) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
