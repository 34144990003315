import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import {
  setProjects,
  setProjectsLoading,
} from "../../Components/Redux/reducers/projectsSlice";
import Filters from "../../Components/Projects/Filters";
import Loader from "../../Components/Common/Loader/Loader";
import {
  fetchCollectionProjects,
} from "../../Components/Api/API";
// import TabButtons from "../../Components/Projects/TabButtons";
import styles from "./styles.module.scss";
import ProjectCard from "../../Components/ProjectCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const SharedCollection = () => {
  const location = useLocation();
  const collection = location?.state;

  const [searchByName, setSearchByName] = useState("");
  const [columnView, setColumnView] = useState(true);
  const [listView, setListView] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState("None");
  const [selectedAlphabeticSorting, setSelectedAlphabeticSorting] =
    useState("None");
  const [headromType, setHeadromType] = useState("None");
  const [status, setStatus] = useState("None");
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");
  const [minRadius, setMinRadius] = useState("");
  const [maxRadius, setMaxRadius] = useState("");

  const { projects, projectsLoading, fetchProjects } = useSelector(
    (state) => state.projects
  );
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (projects?.projects?.length === 0 && pageNumber > 1) {
      setPageNumber(1); // Reset page number to 1 if projects are empty and page number is greater than 1
    }
  }, [projects]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAllProjects = (id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius) => {

    dispatch(setProjectsLoading(true));
    fetchCollectionProjects(id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius).then((res) => {
      dispatch(setProjects([]));

      if (res.code === 400 && collection?.collectionProjects === 0) {
        ToastNotification("error", "Please save a project from Map View.");
        dispatch(setProjectsLoading(false));
      }
      if (res.success) {
        if (res?.data?.projects?.length === 0 && collection?.collectionProjects === 0) {
          ToastNotification("error", "Please save a project from Map View.");

          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length === 0 && collection?.collectionProjects !== 0 && (searchByName?.length > 0 || selectedAssetType?.length > 0 || selectedAlphabeticSorting?.length > 0 || headromType?.length > 0 || status?.length > 0)) {
          ToastNotification("error", "Not saved any project against this filters");

          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  useEffect(() => {
    if (collection?.hasOwnProperty('id')) {
      getAllProjects(collection?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
    }
  }, [pageNumber, fetchProjects, collection]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersProject = () => {
    if (searchByName?.length > 0 || selectedAssetType?.length > 0 || selectedAlphabeticSorting?.length > 0) {
      getAllProjects(collection?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
    } else {
      ToastNotification("error", "Please enter any filter");

    }
  }

  const formatDateString = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1.
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className={styles.projectContainer}>
      <div className={styles.projects} >
        <h1 className={styles.projects__heading}>{collection?.title}</h1>
        {/* <TabButtons getAllCollection={getAllCollection} /> */}
        <Filters
          searchByName={searchByName}
          setSearchByName={setSearchByName}
          setSelectedAssetType={setSelectedAssetType}
          selectedAssetType={selectedAssetType}
          selectedAlphabeticSorting={selectedAlphabeticSorting}
          setSelectedAlphabeticSorting={setSelectedAlphabeticSorting}
          handleFiltersProject={handleFiltersProject}
          headromType={headromType}
          setHeadromType={setHeadromType}
          status={status}
          setStatus={setStatus}

          minCapacity={minCapacity}
          setMinCapacity={setMinCapacity}
          maxCapacity={maxCapacity}
          setMaxCapacity={setMaxCapacity}
          minRadius={minRadius}
          setMinRadius={setMinRadius}
          maxRadius={maxRadius}
          setMaxRadius={setMaxRadius}

          columnView={columnView}
          setColumnView={setColumnView}
          listView={listView}
          setListView={setListView}
        />
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          {projectsLoading ? (
            <Loader />
          ) : (
            <>
              {projects?.projects?.length > 0 ? (
                projects?.projects?.map((project) => {
                  const mapedDate = formatDateString(project?.date);
                  return (
                    <Grid item xs={4} key={project.id}>
                      <ProjectCard
                        project={project}
                        mapedDate={mapedDate}
                      // showButton={true}
                      // getAllCollection={getAllCollection}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center", width: "100%", p: 20 }}>
                  <Typography variant="h5">
                    There are no projects to show
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Grid>

      </div>
      <div
        className={styles.pagination}
      >
        {projects?.total > 9 && (
          <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Pagination
              page={pageNumber}
              count={Math.ceil((projects?.total || 0) / 9)}
              size="large"
              onChange={(event, value) => {
                setPageNumber(value);
              }}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#1F443A !important",
                  color: "white",
                },
              }}
            />
          </Stack>
        )}
      </div>
    </div>

  );
};

export default SharedCollection;
