const map_layers = [
    {
      "id": "green_belt",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "green_belt",
      "paint": {
        "fill-color": "#b6c293",
        "fill-opacity": 0.75,
        "fill-outline-color": "#85994b"
      }
    },
    {
      "id": "ancient_woodland",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "ancient_woodland",
      "paint": {
        "fill-color": "#cce2d8",
        "fill-opacity": 0.5,
        "fill-outline-color": "#00703c"
      }
    },
    {
      "id": "flood_risk_zone",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "flood_risk_zone",
      "paint": {
        "fill-color": ["match", ["get", "flood-risk"],
          "2", "#6683ae", 
          "#a9ddef"
        ],
        "fill-opacity": 1,
        "fill-outline-color": "#344ceb",
      }
    },
    {
      "id": "civil-parish",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "civil-parish",
      "paint": {
        "fill-color": "#9abfdf",
        "fill-opacity": 0.25,
        "fill-outline-color": "#5694ca"
      }
    },
    {
      "id": "heritage-at-risk",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "heritage-at-risk",
      "paint": {
        "fill-color": "#bbabcf",
        "fill-opacity": 0.5,
        "fill-outline-color": "#8d73af"
      }
    },
    {
      "id": "Registered_Parks_and_Gardens",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "Registered_Parks_and_Gardens",
      "paint": {
        "fill-color": "#008000",
        "fill-opacity": 0.75,
        "fill-outline-color": "#008000"
      }
    },
    {
      "id": "agriculture-land-classification",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "agriculture-land-classification",
      "paint": {
        "fill-color": ["match", ["get", "alc_grade"],
          "Exclusion", "#d5eeb2", 
          "Grade 1", "#5aa535",
          "Grade 2", "#22cb21", 
          "Grade 3", "#abdda4", 
          "Grade 4", "#ffffbf", 
          "Grade 5", "#fed790", 
          "Non Agricultural", "#fdae61", 
          "#fdfd61"
        ],
        "fill-opacity": 1,
        "fill-outline-color": "#232323"
      }
    },
    {
      "id": "agriculture-land-classification2",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "agriculture-land-classification2",
      "paint": {
        "fill-color": ["match", ["get", "alc_grade"],
          "Grade 1", "#5aa535",
          "Grade 2", "#22cb21", 
          "Grade 3a", "#abdda4", 
          "Grade 3b", "#d5eeb2", 
          "Grade 4", "#ffffbf", 
          "Grade 5", "#fed790", 
          "Not Surveyed", "#fdae61", 
          "#fdae61"
        ],
        "fill-opacity": 0.75,
        "fill-outline-color": "#232323"
      }
    },
    {
        "id": "area-of-outstanding-natural-beauty",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "area-of-outstanding-natural-beauty",
        "paint": {
            "fill-color": "#e688b3",
            "fill-opacity": 0.75,
            "fill-outline-color": "#d94c8d"
        }
    },
    {
        "id": "article-4-direction-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "article-4-direction-area",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.5,
            "fill-outline-color": "#003078"
        }
    },
    {
        "id": "brownfield-site",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "brownfield-site",
        "paint": {
            "fill-color": "#ac9a7f",
            "fill-opacity": 0.5,
            "fill-outline-color": "#745729"
        }
    },
    {
        "id": "central-activities-zone",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "central-activities-zone",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.5,
            "fill-outline-color": "#003078"
        }
    },
    {
        "id": "conservation-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "conservation-area",
        "paint": {
            "fill-color": "#aecc66",
            "fill-opacity": 0.5,
            "fill-outline-color": "#78aa00"
        }
    },
    {
        "id": "design-code-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "design-code-area",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#003078"
        }
    },
    {
        "id": "design-code-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "design-code-area",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#003078"
        }
    },
    {
      "id": "dmw-ancient-woodland",
      "type": "fill",
      "source": "vq_postgis",
      "source-layer": "dmw-ancient-woodland",
      "paint": {
        "fill-color": "#cce2d8",
        "fill-opacity": 0.5,
        "fill-outline-color": "#00703c"
      }
    },
    {
      "id": "exchange_assets",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "exchange_assets",
      "paint": {
      "circle-radius": 5,
      "circle-color": "#2e7d32"
      }
    },
    {
      "id": "pop_assets",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "pop_assets",
      "paint": {
      "circle-radius": 5,
      "circle-color": "#1976d2"
      }
    },
    {
      "id": "dmw-listed-buildings",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "dmw-listed-buildings",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#fbdd8f",
      }
    },
    {
        "id": "dmw-aonb",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-aonb",
        "paint": {
            "fill-color": "#e688b3",
            "fill-opacity": 0.75,
            "fill-outline-color": "#d94c8d"
        }
    },
    {
        "id": "dmw-conservation-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-conservation-area",
        "paint": {
            "fill-color": "#aecc66",
            "fill-opacity": 0.5,
            "fill-outline-color": "#78aa00"
        }
    },
    {
        "id": "dmw-floodriskfromrivers",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-floodriskfromrivers",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 1,
            "fill-outline-color": "#344ceb"
        }
    },
    {
        "id": "dmw-pre-assessed-areas-for-wind-energy",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-pre-assessed-areas-for-wind-energy",
        "paint": {
            "fill-color": "#6ed597",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0eb951"
        }
    },
    {
        "id": "dmw-ramsar",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-ramsar",
        "paint": {
            "fill-color": "#b2e1ff",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7fcdff"
        }
    },
    {
        "id": "dmw-scheduled-monuments",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-scheduled-monuments",
        "paint": {
            "fill-color": "#6fc4e9",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0f9cda"
        }
    },
    {
        "id": "dmw-seafloodriskfromsea",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-seafloodriskfromsea",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 1,
            "fill-outline-color": "#344ceb"
        }
    },
    {
        "id": "dmw-sssi",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-sssi",
        "paint": {
            "fill-color": "#83bccd",
            "fill-opacity": 0.75,
            "fill-outline-color": "#308fac"
        }
    },
    {
        "id": "dmw-world-heritage-sites",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "dmw-world-heritage-sites",
        "paint": {
            "fill-color": "#f378ef",
            "fill-opacity": 0.75,
            "fill-outline-color": "#eb1ee5"
        }
    },
    {
        "id": "enwl-county-polygons",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "enwl-county-polygons",
        "paint": {
            "fill-color": "#b2e1ff",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7fcdff"
        }
    },
    {
        "id": "enwl-general-boundaries",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "enwl-general-boundaries",
        "paint": {
            "fill-color": "#b2e1ff",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7fcdff"
        }
    },
    {
        "id": "heritage-coast",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "heritage-coast",
        "paint": {
            "fill-color": "#bd80b8",
            "fill-opacity": 0.5,
            "fill-outline-color": "#93308b"
        }
    },
    {
        "id": "hes-battlefields-inventory-boundary",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-battlefields-inventory-boundary",
        "paint": {
            "fill-color": "#dbd4d9",
            "fill-opacity": 0.75,
            "fill-outline-color": "#4d2942"
        }
    },
    {
        "id": "hes-conservation-areas",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-conservation-areas",
        "paint": {
            "fill-color": "#aecc66",
            "fill-opacity": 0.75,
            "fill-outline-color": "#78aa00"
        }
    },
    {
        "id": "hes-gardens-and-design-landscapes",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-gardens-and-design-landscapes",
        "paint": {
            "fill-color": "#6ed597",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0eb951"
        }
    },
    {
        "id": "hes-historic-protected-areas",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-historic-protected-areas",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7a8705"
        }
    },
    {
        "id": "hes-listed-buildings-boundaries",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-listed-buildings-boundaries",
        "paint": {
            "fill-color": "#fbdd8f",
            "fill-opacity": 0.85,
            "fill-outline-color": "#f9c744"
        }
    },
    {
        "id": "hes-scheduled-monuments",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-scheduled-monuments",
        "paint": {
            "fill-color": "#6fc4e9",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0f9cda"
        }
    },
    {
        "id": "hes-world-heritage-sites",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "hes-world-heritage-sites",
        "paint": {
            "fill-color": "#f378ef",
            "fill-opacity": 0.75,
            "fill-outline-color": "#eb1ee5"
        }
    },
    {
        "id": "local-authority-district",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "local-authority-district",
        "paint": {
            "fill-color": "#e7e7e7",
            "fill-opacity": 0.25,
            "fill-outline-color": "#0b0c0c"
        }
    },
    {
        "id": "local-planning-authority",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "local-planning-authority",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.5,
            "fill-outline-color": "#4c6ea0"
        }
    },
    {
        "id": "locally-listed-building",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "locally-listed-building",
        "paint": {
            "fill-color": "#fbdd8f",
            "fill-opacity": 0.75,
            "fill-outline-color": "#f9c744"
        }
    },
    {
        "id": "national-gas-pipe",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "national-gas-pipe",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0000FF"
        }
    },
    {
        "id": "national-gas-site",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "national-gas-site",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0000FF"
        }
    },
    {
        "id": "national-grid-substations",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "national-grid-substations",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0000FF"
        }
    },
    {
        "id": "national-park",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "national-park",
        "paint": {
            "fill-color": "#8bc980",
            "fill-opacity": 0.75,
            "fill-outline-color": "#5bb34d"
        }
    },
    {
        "id": "park-and-garden",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "park-and-garden",
        "paint": {
            "fill-color": "#6ed597",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0eb951"
        }
    },
    {
        "id": "protected-wreck-site",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "protected-wreck-site",
        "paint": {
            "fill-color": "#6d6d6d",
            "fill-opacity": 0.5,
            "fill-outline-color": "#0b0c0c"
        }
    },
    {
        "id": "ramsar-site",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "ramsar-site",
        "paint": {
            "fill-color": "#b2e1ff",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7fcdff"
        }
    },
    {
        "id": "region",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "region",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.25,
            "fill-outline-color": "#40649a"
        }
    },
    {
        "id": "registered-battlefields",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "registered-battlefields",
        "paint": {
            "fill-color": "#dbd4d9",
            "fill-opacity": 0.75,
            "fill-outline-color": "#4d2942"
        }
    },
    {
        "id": "scheduled-monument",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "scheduled-monument",
        "paint": {
            "fill-color": "#6fc4e9",
            "fill-opacity": 0.75,
            "fill-outline-color": "#0f9cda"
        }
    },
    {
        "id": "site-of-special-scientific-interest",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "site-of-special-scientific-interest",
        "paint": {
            "fill-color": "#83bccd",
            "fill-opacity": 0.75,
            "fill-outline-color": "#308fac"
        }
    },
    {
        "id": "special-area-of-conservation",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "special-area-of-conservation",
        "paint": {
            "fill-color": "#afb769",
            "fill-opacity": 0.75,
            "fill-outline-color": "#afb769"
        }
    },
    {
        "id": "special-protection-area",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "special-protection-area",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#7a8705"
        }
    },
    {
        "id": "tree-preservation-zone",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "tree-preservation-zone",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#003078"
        }
    },
    {
        "id": "ukpn-licence-are-grid-supply-points-areas",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "ukpn-licence-are-grid-supply-points-areas",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#40649a"
        }
    },
    {
        "id": "ukpn-primary-substation-distribution-areas",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "ukpn-primary-substation-distribution-areas",
        "paint": {
            "fill-color": "#6683ae",
            "fill-opacity": 0.75,
            "fill-outline-color": "#40649a"
        }
    },
    {
        "id": "world-heritage-site",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "world-heritage-site",
        "paint": {
            "fill-color": "#f378ef",
            "fill-opacity": 0.75,
            "fill-outline-color": "#eb1ee5"
        }
    },
    {
        "id": "world-heritage-site-buffer-zone",
        "type": "fill",
        "source": "vq_postgis",
        "source-layer": "world-heritage-site-buffer-zone",
        "paint": {
            "fill-color": "#f378ef",
            "fill-opacity": 0.5,
            "fill-outline-color": "#eb1ee5"
        }
    },
    {
      "id": "enwl-11kv-overhead",
      "type": "line",
      "source": "mySource",
      "source-layer": "enwl-11kv-overhead",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "enwl-6point6kv-conductor-headroom",
      "type": "line",
      "source": "mySource",
      "source-layer": "enwl-6point6kv-conductor-headroom",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "enwl-11kv-underground",
      "type": "line",
      "source": "mySource",
      "source-layer": "enwl-11kv-underground",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "network_cables",
      "type": "line",
      "source": "vq_postgis",
      "source-layer": "network_cables",
      "layout": {},
      "paint": {
        "line-color": "#ac5b31",
        "line-width": 2
      }
    },
    {
      "id": "national-grid-cables",
      "type": "line",
      "source": "mySource",
      "source-layer": "national-grid-cables",
      "layout": {},
      "paint": {
        "line-color": "#0000FF",
        "line-width": 2
      }
    },
    {
      "id": "national-grid-overheadlines",
      "type": "line",
      "source": "mySource",
      "source-layer": "national-grid-overheadlines",
      "layout": {},
      "paint": {
        "line-color": "#0000FF",
        "line-width": 2
      }
    },
    {
      "id": "nged-11kv-overheadlines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-11kv-overheadlines-new",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "nged-11kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-11kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "nged-132kv-overheadlines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-132kv-overheadlines-new",
      "layout": {},
      "paint": {
        "line-color": "#b55d00",
        "line-width": 2
      }
    },
    {
      "id": "nged-132kv-undergroundlines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-132kv-undergroundlines-new",
      "layout": {},
      "paint": {
        "line-color": "#b55d00",
        "line-width": 2
      }
    },
    {
      "id": "nged-33kv-overheadlines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-33kv-overheadlines-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "nged-33kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-33kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "nged-66kv-overhead-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-66kv-overhead-new",
      "layout": {},
      "paint": {
        "line-color": "#b59f10",
        "line-width": 2
      }
    },
    {
      "id": "nged-66kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "nged-66kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#b59f10",
        "line-width": 2
      }
    },
    {
      "id": "npg-132kv-overhead-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-132kv-overhead-new",
      "layout": {},
      "paint": {
        "line-color": "#b55d00",
        "line-width": 2
      }
    },
    {
      "id": "npg-132kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-132kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#b55d00",
        "line-width": 2
      }
    },
    {
      "id": "npg-33kv-overhead-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-33kv-overhead-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "npg-33kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-33kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "npg-66kv-overhead-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-66kv-overhead-new",
      "layout": {},
      "paint": {
        "line-color": "#b59f10",
        "line-width": 2
      }
    },
    {
      "id": "npg-66kv-underground-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "npg-66kv-underground-new",
      "layout": {},
      "paint": {
        "line-color": "#b59f10",
        "line-width": 2
      }
    },
    {
      "id": "ssen-11kv-cables-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ssen-11kv-cables-new",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "ssen-230to400v-cables-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ssen-230to400v-cables-new",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "ssen-33kv-cables-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ssen-33kv-cables-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "ukpn-11kv-hv-overhead-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ukpn-11kv-hv-overhead-new",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "ukpn-132kv-overhead-lines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ukpn-132kv-overhead-lines-new",
      "layout": {},
      "paint": {
        "line-color": "#b55d00",
        "line-width": 2
      }
    },
    {
      "id": "ukpn-33kv-overhead-lines-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ukpn-33kv-overhead-lines-new",
      "layout": {},
      "paint": {
        "line-color": "#55b555",
        "line-width": 2
      }
    },
    {
      "id": "ukpn-66kv-overhead-lines-shapefile-new",
      "type": "line",
      "source": "mySource",
      "source-layer": "ukpn-66kv-overhead-lines-shapefile-new",
      "layout": {},
      "paint": {
        "line-color": "#b59f10",
        "line-width": 2
      }
    },
    {
      "id": "ukpn-low-voltage",
      "type": "line",
      "source": "mySource",
      "source-layer": "ukpn-low-voltage",
      "layout": {},
      "paint": {
        "line-color": "#6e97b8",
        "line-width": 2
      }
    },
    {
      "id": "brownfield-land",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "brownfield-land",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#ac9a7f",
      }
    },
    {
      "id": "building-preservation-notice",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "building-preservation-notice",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#fb8fdd",
      }
    },
    {
      "id": "certificate-of-immunity",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "certificate-of-immunity",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#e8ad6e",
      }
    },
    {
      "id": "clearview",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "clearview",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#e77148",
      }
    },
    {
      "id": "ncpr",
      "type": "symbol",
      "source": "vq_postgis",
      "source-layer": "ncpr",
      "layout": {
          "icon-image": 'ncpr_image',
          "icon-size": 0.025,
          "icon-allow-overlap": true
      }
    },
    {
      "id": "tree",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "tree",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#898f8b",
      }
    },
    {
      "id": "data_centres",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "data_centres",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#fa8b39",
      }
    },
    {
      "id": "enwl-distribution-substation-headroom",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "enwl-distribution-substation-headroom",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#898f8b",
      }
    },
    {
      "id": "hes-listed-buildings",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "hes-listed-buildings",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#fbdd8f",
      }
    },
    {
      "id": "hes-properties-in-care",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "hes-properties-in-care",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#e8ad6e",
      }
    },
    {
      "id": "listed-building-outline",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "listed-building-outline",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#fbdd8f",
      }
    },
    {
      "id": "national-grid-towers",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "national-grid-towers",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#0000ff",
        // "circle-stroke-width": 1,
        // "circle-stroke-color": "#fff"
      }
    },
    {
      "id": "ukpn-secondary-sites",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "ukpn-secondary-sites",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#898f8b",
      }
    },
    {
      "id": "Advanced-conversion-technologies",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "Advanced-conversion-technologies",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#b81c1a",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "abandoned-renewable",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "abandoned-renewable",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#b81c1a",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "apeal-lodged",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "apeal-lodged",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#e601fa",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "apeal-refused",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "apeal-refused",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#4d138b",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "apeal-withdrawn",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "apeal-withdrawn",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#7c0f6b",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "application-expired",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "application-expired",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#ff876a",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "application-refused",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "application-refused",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#FF0000",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "application-submitted",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "application-submitted",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#65b5f4",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "application-withdrawn",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "application-withdrawn",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#9777cc",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "awaiting-construction",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "awaiting-construction",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#81c783",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "decommissioned",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "decommissioned",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#3e2521",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "efw-incineration",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "efw-incineration",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#004c3f",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "finished",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "finished",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#326823",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "flywheels",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "flywheels",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#81c783",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "fuel-cell-hydrogen",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "fuel-cell-hydrogen",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#326823",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "geothermal",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "geothermal",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#020000",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "hot-dry-rocks",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "hot-dry-rocks",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#d85609",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "hydrogen",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "hydrogen",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#000000",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "landfill-gas",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "landfill-gas",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#ff876a",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "large-hydro",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "large-hydro",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#3e2521",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "liquid-air-energy-storage",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "liquid-air-energy-storage",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#676500",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "no-application-required",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "no-application-required",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#020000",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "operational",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "operational",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#004c3f",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "planning-permission-expired",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "planning-permission-expired",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#d85609",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "pumped-storage-hydroelectricity",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "pumped-storage-hydroelectricity",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#04fed1",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "revised",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "revised",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#4fd0e5",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "sewage-sludge-digestion",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "sewage-sludge-digestion",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#281dff",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "shoreline-wave",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "shoreline-wave",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#e601fa",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "small-hydro",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "small-hydro",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#7c0f6b",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "solar-photovoltaics",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "solar-photovoltaics",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#f6c162",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "tidal-lagoon",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "tidal-lagoon",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#000103",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "tidal-stream",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "tidal-stream",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#000103",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "under-construction",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "under-construction",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#04fed1",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "wind-offshore",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "wind-offshore",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#626ccf",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "wind-onshore",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "wind-onshore",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#466683",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "battery",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "battery",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#9777cc",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "hes-canmore-points",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "hes-canmore-points",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#898f8b",
      }
    },
    {
      "id": "biomass-cofiring",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "biomass-cofiring",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#65b5f4",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "biomass-dedicated",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "biomass-dedicated",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#00569b",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "compressed-air-energy-storage",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "compressed-air-energy-storage",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#4fd0e5",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    },
    {
      "id": "tidal-lagoon-renewable",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "tidal-lagoon-renewable",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#FFFFFF",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#FFFFFF"
      }
    },
    {
      "id": "anaerobic-digestion",
      "type": "circle",
      "source": "vq_postgis",
      "source-layer": "anaerobic-digestion",
      "paint": {
        "circle-radius": 5,
        "circle-color": "#4d138b",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#232323"
      }
    }];
    
    export default map_layers;