import React from 'react';
import styles from './styles.module.scss';

const AppTextBox=({text}) =>
{
  return (
    <div className={styles.container}>
      <p>{text}</p>
    </div>
  );
}
export default AppTextBox;