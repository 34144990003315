import React, { useState } from 'react'
import EnergyType from '../../Map/EnergyType'
import Button from '../../Common/Button/Button'
import DevelopmentStatus from '../../Map/DevelopmentStatus'

const SpyMode = () => {
    const tabs =['Energy Type', 'Development Status']
    const [selectedSpyMode, setSelectedSpyMode] = useState('Energy Type')
  return (
    
    <>
      <div style={{display: 'flex',justifyContent: 'space-around', marginTop: '10px'}}>
       { tabs.map((item,i)=>{
            return (
                <Button
                    key={i}
                    height='45px'
                    bgColor={item === selectedSpyMode ? '#F3F4F6' : '#fff'}
                    color={item === selectedSpyMode ? '#1F443A' : '#1E232E'}
                    radius='100px'
                    borderColor={item === selectedSpyMode ? '#F3F4F6' : '#DCDFE5'}
                    text={item} 
                    handleClick={() => setSelectedSpyMode(item)}  
                />
                    
            )
        })}
      </div>
     {selectedSpyMode === 'Energy Type' ? <EnergyType/> : <DevelopmentStatus/>}
    </>
  )
}

export default SpyMode
