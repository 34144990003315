import "./../Map/myMap.css";
import html2canvas from "html2canvas";
import { fixed } from "../Utils/Formats";
import "maplibre-gl/dist/maplibre-gl.css";
import { LngLatBounds } from "maplibre-gl";
import Loader from "../Common/Loader/Loader";
import { useState, useRef, useEffect } from "react";
import Map, {
  Marker,
  NavigationControl,
  Source,
  Layer,
} from "react-map-gl/maplibre";
import { pdf } from "@react-pdf/renderer";
import MyPDFDocument from "../../Pages/ProjectDetail/PDF-report";
import { saveAs } from "file-saver";
import selected_Project from "../../Images/Location2-01-01.png";
import reSearch from "../../Images/realestate_search.png";
import bsSearch from "../../Images/battery_search.png";
import evSearch from "../../Images/ev_search.png";
import rneSearch from "../../Images/renewable_search.png";
import { baseURL } from "../Api/instance";
import map_layers from "../Map/style/map_layers";
const MapCard = (props) => {
  const {
    setDownloading,
    downloading,
    project,
    distance,
    radiusUnit,
    height,
    marginTop,
    borderRadius,
    maxZoom = 18,
    minZoom = 7,
    drag = true,
  } = props;

  const proMapRef = useRef();

  const [line, setLine] = useState(null);

  const [loading, setLoading] = useState(true);

  const [viewport, setViewport] = useState({
    longitude: project ? project.userLocation.lng : -0.1275862,
    latitude: project ? project.userLocation.lat : 51.5072178,
    zoom: 12,
    bearing: 0,
    pitch: 0,
  });

  const myAPIKey = "irCAcfKXOE8yijupOexZaTBASOVGroLF";

  const mapStyle = {
    version: 8,
    sources: {
      "raster-tiles": {
        type: "raster",
        tiles: [
          "https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png",
        ],
        tileSize: 256,
      },
    },
    layers: [
      {
        id: "os-maps-zxy",
        type: "raster",
        source: "raster-tiles",
        layout: { visibility: "visible" },
        paint: { "raster-opacity": 1 },
      },
    ],
    sprite: "https://voltquant-images.s3.eu-west-2.amazonaws.com/vqSprites",
    glyphs:
      "https://voltquant-images.s3.eu-west-2.amazonaws.com/vq-hackathon/fonts/{fontstack}/{range}.pbf",
  };

  const exportMapAsPNG = (map) => {
    map.once("idle", async () => {
      const target = document.getElementById("printMapCanvas");
      const controls = document.querySelector(".maplibregl-control-container");
      html2canvas(target, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        ignoreElements: (element) => controls.contains(element),
      }).then(async (canvas) => {
        const imgSrc = canvas.toDataURL();
        const blob = await pdf(
          <MyPDFDocument mapPng={imgSrc} project={project} />
        ).toBlob();
        saveAs(blob, `${project?.projectName}.pdf`);
        setDownloading(false);
      });
    });
  };

  useEffect(() => {
    if (proMapRef.current && downloading === true) {
      const map = proMapRef.current.getMap();
      exportMapAsPNG(map);
    }
  }, [downloading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const lineString = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            properties: {},
            coordinates: [
              [project?.userLocation?.lng, project?.userLocation?.lat],
              [
                project?.selectedSubstation.Longitute,
                project?.selectedSubstation.Lattitude,
              ],
            ],
          },
        },
      ],
    };
    setLine(lineString);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMapLoad = () => {
    const map = proMapRef.current.getMap();
    // Now you can use the map object
    if (map.hasImage("ncpr_image") === false) {
      map.loadImage(
        'https://voltquant-images.s3.eu-west-2.amazonaws.com/ev-Icon-filled.png',
        (error, image) => {
            if (error) throw error;
              map.addImage('ncpr_image', image);
          }
      );
    }
    if (project.drawnLine) {
      const coordinates = project.drawnLine.geometry.coordinates;
      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        padding: 60,
      });
    } else if (line) {
      const coordinates = line.features[0].geometry.coordinates;
      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        padding: 60,
      });
    }
  };
  const wmsLayer = {
    id: "wms-geoserver-layer",
    type: "raster",
    source: "wms",
    paint: {},
  };
  return (
    <div
      id="printMapCanvas"
      style={{ width: "100%", height: height, marginTop: marginTop }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Map
          ref={proMapRef}
          onLoad={handleMapLoad}
          initialViewState={viewport}
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
          className="markercluster-map"
          minZoom={minZoom}
          maxZoom={maxZoom}
          dragPan={drag}
          // maxBounds={[
          //     [ -10.76418, 49.528423 ],
          //     [ 1.9134116, 61.331151 ]
          // ]}
          mapStyle={mapStyle}
          transformRequest={(url, resourceType) => {
            if (url.includes("voltquant-images"))
              return {
                url: url,
              };
            if (url.includes("openinframap"))
              return {
                url: url,
              };
            if (url.includes("/geoserver/"))
              return {
                url: url,
              };
            if (
              resourceType === "Tile" &&
              url.startsWith("https://api.os.uk/maps/raster/v1/zxy/Light_3857")
            ) {
              const match = url.match(/Light_3857\/(\d+)\/(\d+)\/(\d+)\.png/);
              if (match) {
                const z = match[1];
                const x = match[2];
                const y = match[3];
                const proxyUrl = `${baseURL}/map/map-tiles/${z}/${x}/${y}.png`;
                return { url: proxyUrl };
              }
            }
          }}
          style={{ borderRadius: borderRadius }}
        >
          <NavigationControl position={"top-left"} />
          {project?.selectedLayers?.length > 0 && (
            // <Source
            //   id="wms"
            //   type="raster"
            //   tileSize={256}
            //   tiles={[
            //     `https://backend.voltquant.uk/geoserver/volt-quant/wms?service=WMS&version=1.1.1&request=GetMap&layers=${project?.selectedLayers?.join(
            //       ","
            //     )}&styles=&format=image/png&transparent=true&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}`,
            //   ]}
            // >
            //   <Layer {...wmsLayer} />
            // </Source>
            map_layers.map((lyr, key)=>{
              if (project?.selectedLayers.includes(lyr.id)){
                let beforeLayerId = null;
                const map = proMapRef?.current?.getMap();
                const index = project?.selectedLayers.indexOf(lyr.id);
                if (index !== project?.selectedLayers.length - 1 && proMapRef?.current) {
                  if (map?.getLayer(project?.selectedLayers[index + 1])) {
                    beforeLayerId = project?.selectedLayers[index + 1];
                  }
                }
                // if (showSat === true) {
                //   if (beforeLayerId !== null) {
                //     return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                //   } else {
                //     return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                //   }
                // } else {
                  if (beforeLayerId !== null) {
                    return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                  } else if(map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                    return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={"OS/Railway_stations/Light Rapid Transit Station"} key={key} /></Source>;
                  } else {
                    return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                  }
                // }
              }
            })
          )}
          {project?.drawnPolygon && (
            <Source type="geojson" data={project?.drawnPolygon}>
              <Layer
                id={`aapolygon`}
                type="fill"
                paint={{
                  "fill-color": `#b0f542`,
                  "fill-opacity": 0.6,
                }}
              />
            </Source>
          )}
          {project?.drawnLine && (
            <Source type="geojson" data={project?.drawnLine}>
              <Layer
                id={`aaLine`}
                type="line"
                paint={{
                  "line-width": 4,
                  "line-color": "#008000",
                }}
              />
            </Source>
          )}
          {project?.userLocation && (
            <Marker
              longitude={project?.userLocation.lng}
              latitude={project?.userLocation.lat}
              anchor="center"
            >
              <img
                src={
                  project?.assetTypeUrl ===
                  "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/ev_search.png"
                    ? evSearch
                    : project?.assetTypeUrl ===
                      "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/battery_search.png"
                    ? bsSearch
                    : project?.assetTypeUrl ===
                      "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/renewable_search.png"
                    ? rneSearch
                    : reSearch
                }
                alt="Search location"
              />
            </Marker>
          )}
          {/* {project?.substations?.map((subs, index) => {
            return project?.substation === subs.Name ? ( */}
          <Marker
            // key={index}
            longitude={Number(project?.selectedSubstation?.Longitute)}
            latitude={Number(project?.selectedSubstation?.Lattitude)}
            anchor="center"
          >
            <img src={selected_Project} alt="Poject location" />
          </Marker>
          {/* 
           ) : (
              ""
            );
           })}
          */}
          {project?.userLocation && project?.selectedSubstation && line && (
            <>
              <Source type="geojson" data={line}>
                <Layer
                  id="lineLayer"
                  type="line"
                  paint={{
                    "line-width": 4,
                    "line-color": "#008000",
                  }}
                />
              </Source>
              <Marker
                longitude={
                  (project?.userLocation.lng +
                    Number(project?.selectedSubstation.Longitute)) /
                  2
                }
                latitude={
                  (project?.userLocation.lat +
                    Number(project?.selectedSubstation.Lattitude)) /
                  2
                }
              >
                <div id="userLocation" className="lineMarker">
                  {`${fixed(distance, 2)} ${
                    radiusUnit === "Miles" ? radiusUnit : "km"
                  }`}
                </div>
              </Marker>
            </>
          )}
        </Map>
      )}
    </div>
  );
};

export default MapCard;
