import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./../../../../Images/Logo1.svg";
import LoginRightSide from "../LoginRightSide";
import styles from "../styles.module.scss";
import { ResetSuccess } from "../../../../Components/SVG";

const Success = () => {

    return (
        <div className={styles.signup}>
            <div className={styles.signup__container}>
                <img src={Logo} alt="logo vector" />
                <div className={styles.successContainer}>
                    <ResetSuccess />
                    <h1 className={styles.signup__container__heading}>
                        Success
                    </h1>
                    <p className={styles.signup__container__para}>
                        Your password has been successfully changed
                    </p>
                    <form>
                    <NavLink to='/login'>
                        <button type="submit">Go back to Sign In</button>
                    </NavLink>
                    </form>
                </div>
            </div>
            <LoginRightSide />
        </div>
    );
};

export default Success;
