import React from 'react'
import styles from './styles.module.scss';

const Button = (props) => {
    const {
        type,
        height,
        width,
        bgColor,
        color,
        radius,
        icon,
        icon1,
        text,
        borderColor,
        handleClick,
        length,
        lengthSize,
        lengthArea,
        padding,
        fontSize,
        disable = false
    } = props;

    return (
        <button
            className={styles.buttonC}
            type={type}
            onClick={handleClick}
            style={{
                fontSize: fontSize,
                height: height,
                width: width,
                background: bgColor,
                color: color,
                borderRadius: radius,
                padding: padding,
                border: `1px solid ${borderColor}`,
                cursor: disable ? 'not-allowed' : 'pointer'
            }}
            disabled={disable}
        >
            {icon} {text} {icon1} {length > -1 && <div style={{ fontSize: lengthSize, height: lengthArea, width: lengthArea }}>{length}</div>}
        </button>
    )
}

export default Button
