import React, { useState } from "react";
import {
  power_tower, power_tower_transition, power_pole_transition, converter,
  power_pole, power_transformer, power_switch, power_compensator
} from "../SVG";

const energyOptions = [
  {
    name: "Tower/Pylon",
    image: power_tower,
  },
  {
    name: "Transition Tower",
    image: power_tower_transition,
  },

  {
    name: "Pole",
    image: power_pole,
  },

  {
    name: "Transition Pole",
    image: power_pole_transition,
  },
  {
    name: "Transformer",
    image: power_transformer,
  },
  {
    name: "Switch",
    image: power_switch,
  },
  {
    name: "Compensator",
    image: power_compensator,
  },
  {
    name: "Converter",
    image: converter,
  },

];

const PowerLegend = () => {

  const [optionsVisible, setOptionsVisible] = useState(true);
  const handleToggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="legend-main">
      <div
        onClick={handleToggleOptions}
        className='legend-heading'
      >
        <svg
          width="10"
          height="10"
          style={{
            marginRight: "4px",
            transform: optionsVisible ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <polygon points="0,0 10,5 0,10" fill="black" />
        </svg>
        Other Power
      </div>
      {optionsVisible && (
        <>
          {energyOptions.map((option, index) => {
            const SvgLogo = option.image
            return (
              <div
                key={option.name}
                style={{
                  width: "286px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: 'center',
                  marginBottom: '3px'
                }}
              >
                <div>{option.name}</div>
                <div style={{ justifyContent: "flex", marginRight: "5px"}}>
                  <SvgLogo />
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  );
};

export default PowerLegend;
