export const GeospatialRiskLayers = [
  "ancient_woodland",
  "area-of-outstanding-natural-beauty",
  "article-4-direction-area",
  "brownfield-land",
  "brownfield-site",
  "building-preservation-notice",
  "certificate-of-immunity",
  "conservation-area",
  "green_belt",
  "heritage-at-risk",
  "heritage-coast",
  "park-and-garden",
  "listed-building-outline",
  "locally-listed-building",
  "national-park",
  "protected-wreck-site",
  "ramsar-site",
  "registered-battlefields",
  "national-park",
  "scheduled-monument",
  "site-of-special-scientific-interest",
  "Registered_Parks_and_Gardens",
  "special-area-of-conservation",
  "special-protection-area",
  "tree",
  "tree-preservation-zone",
  "world-heritage-site",
  "world-heritage-site-buffer-zone",
  "hes-canmore-maritime",
  "hes-canmore-points",
  "hes-conservation-areas",
  "hes-gardens-and-design-landscapes",
  "hes-historic-protected-areas",
  "hes-listed-buildings",
  "hes-listed-buildings-boundaries",
  "hes-properties-in-care",
  "hes-scheduled-monuments",
  "hes-world-heritage-sites",
  "dmw-ancient-woodland",
  "dmw-aonb",
  "dmw-conservation-area",
  "dmw-floodriskfromrivers",
  "dmw-listed-buildings",
  "dmw-ramsar",
  "dmw-scheduled-monuments",
  "dmw-seafloodriskfromsea",
  "dmw-sssi",
  "dmw-world-heritage-sites",
];
