import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import TextArea from "../../Common/TextArea";
import TextInput from "../../Common/TextInput";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { createCollection, fetchAllCollection } from "../../Api/API";
import { setCollections } from "./../../Redux/reducers/projectsSlice";

const CreateCollectionsModal = (props) => {
  const { open, setOpen, getAllCollections } = props;

  const [title, setTitle] = useState("");
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  const { collections } = useSelector((state) => state.projects);

  useEffect(() => {
    if (open) {
      getAllCollection();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllCollection = () => {
    fetchAllCollection().then((res) => {
      if (res?.success) {
        if (res?.data?.length > 0) {
          dispatch(setCollections(res?.data));
        }
      }
    });
  };

  const handleCollection = () => {
    const collection = collections?.filter(
      (item) => item?.title === title.trim()
    );

    if (title.trim() === "") {
      ToastNotification("error", "Name is required");
    } else if (title?.length < 3 || title?.length > 20) {
      ToastNotification("error", "Collection name should be between 3 and 20 characters");
    } else if (collection?.length > 0) {
      ToastNotification("error", "This collection name already exist");
    } else {
      let body = {
        title: title,
        description: comments,
      };
      createCollection(body)
        .then((response) => {
          if (response.success) {
            getAllCollections();
            setOpen(false);
            setComments(""); // Clear the textarea content after sending
            setTitle(""); // Clear the title content after sending
            ToastNotification("success", "Collection created successfully!");
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err, "err");

            ToastNotification("error", "Error: Collection creation");
          }
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.createContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Create a new collection</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>Create collection and save project</p>

          <TextInput
            type="text"
            value={title}
            setValue={setTitle}
            placeholder="Collection Name"
          />
          <TextArea
            value={comments}
            setValue={setComments}
            placeholder="Detail"
          />

          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={() => setOpen(false)}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Add collection"
              handleClick={handleCollection}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default CreateCollectionsModal;
