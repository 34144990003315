import React from 'react'
import Button from "../../Common/Button/Button";
import styles from './styles.module.scss';

const ProjectType = (props) => {
    const { projectType, setProjectType } = props;
    const buttonArray = ['Real Estate', 'Renewable Energy', 'EV Infrastructure', 'Energy Storage']

    return (
        <div className={styles.recommendation}>
            <p className={styles.para1}>
                Which types of projects have you used VOLTQUANT for? <br /> (Select all that apply)
            </p>
            <div className={styles.recommendationButtons}>
                {buttonArray?.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            height='45px'
                            bgColor={projectType === item ? '#1F443A' : '#fff'}
                            color={projectType === item ? '#fff' : '#1F443A'}
                            radius='12px'
                            borderColor={projectType === item ? '#1F443A' : '#DCDFE5'}
                            text={item}
                            handleClick={() => setProjectType(item)}
                        />
                    )
                })}
            </div>

        </div>
    )
}

export default ProjectType
