import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { fixed } from "../../Components/Utils/Formats";
import getCurrentSeasonUK from "../../Components/Utils/FindSeason";

// Create styles

// Create Document Component
const MyPDFDocument = ({ mapPng, project }) => {
  function convertDateFormat(dateString) {
    const dateParts = dateString?.split("-"); // split the date into [YYYY, MM, DD]
    return `${dateParts?.[2]}/${dateParts?.[1]}/${dateParts?.[0]}`; // rearrange to MM/DD/YYYY
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    section1: {
      marginTop: 10,
      marginBottom: 10,
      // padding: 10,
    },
    section2: {
      marginTop: 460,
      marginBottom: 10,
      // padding: 10,
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      marginTop: 15,
    },
    subHeader: {
      fontSize: 18,
      color: "black",
      marginBottom: 20,
      // fontFamily:'Eudoxus Sans'
    },
    content: {
      fontSize: 12,
      marginBottom: 10,
      color: "#787B82",
    },
    headerImage: {
      width: "100%",
    },
    smallImage: {
      width: "40%",
    },
    smallImage2: {
      width: "20%",
    },
    mapImage: {
      width: "100%",
      height: "300px",
    },
    view1: {
      display: "flex",
      flexDirection: "row", // Align children in a row
      justifyContent: "space-between",
      marginBottom: 10,
      gap: 4,
    },
    view1_subsection: {
      backgroundColor: "white",
      width: "100%",
      height: "200px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
    },
    view1_subsection2: {
      width: "50%",
      height: "200px",
      backgroundColor: "white",
      borderRadius: "16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
    },
    view2: {
      display: "flex",
      flexDirection: "row", // Align children in a row
      justifyContent: "space-between",
      marginBottom: 10,
      gap: 4,
    },
    view2_subsection: {
      backgroundColor: "white",
      width: "100%",
      height: "200px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
    },
    view2_subsection1: {
      backgroundColor: "white",
      width: "100%",
      height: "300px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      // padding: "24px",
      marginTop: "10px",
    },
    view2_subsection2: {
      backgroundColor: "white",
      width: "100%",
      height: "300px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
      marginTop: "10px",
    },
    displayFlex: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    substationHeadView: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    substationHeadView1: {
      width: '50%',
    },
    substationHead: {
      width: "50%",
    },
    substationsInfo: {
      fontSize: 12,
      color: "#787B82",
      // marginBottom: "16px",
    },
    substationsInfoResponse: {
      fontSize: 12,
      color: "#1E232E",
      marginBottom: "16px",
    },
    siteVoltage: {
      fontSize: 12,
      color: "#787B82",
      marginTop: 10,
      marginBottom: 6,
    },
    siteVoltageRes: {
      fontSize: 18,
      color: "#1E232E",
      fontWeight: "bold",
      marginBottom: 10,
    },
    view3: {
      display: "flex",
      flexDirection: "row", // Align children in a row
      justifyContent: "space-between",
      marginBottom: 10,
      gap: 2,
    },
    view3Section: {
      width: "100%",
    },
    view3_subsection: {
      backgroundColor: "white",
      width: "95%",
      height: "100px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
      marginBottom: "10px",
    },
    view3_subsection2: {
      backgroundColor: "white",
      width: "130%",
      height: "210px",
      borderRadius: "16px",
      boxShadow: "5px 5px 5px 0 rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderWidth: 1, // Width of the border
      borderStyle: "solid", // Style of the border (solid, dashed, dotted)
      borderColor: "#DCDFE5",
      padding: "24px",
      marginBottom: "10px",
    },
    IQframeBar: {
      height: "10px",
      width: "70%",
      backgroundColor: "#F3FAF8",
      borderRadius: "99px",
    },
    IQprogressBar: {
      height: "10px",
      width: `${project?.iqScore}%`,
      backgroundColor: project?.iqScore < 50 ? "#0AED24" : "#0AED24",
      borderRadius: "99px",
    },
    RiskprogressBar: {
      height: "10px",
      width: `${project?.riskScore}%`,
      backgroundColor: project?.riskScore < 50 ? "#0AED24" : "#FF0000",
      borderRadius: "99px",
    },

    VerticleBar: {
      borderRightColor: "#000000",
      borderRightWidth: 1,
      height: "100%", // Adjust as needed
      marginHorizontal: 10,
    },
    substationsStatus: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    countInnerSection: {
      // display: "inline",
      width: "100%",
    },
    PlanningFrameBar: {
      height: "14px",
      width: "40px",
      backgroundColor: "#F3FAF8",
    },
    PlanningProgressBar_Text1: {
      fontSize: 12,
      color: "#434746",
      textAlign: "right",
      width: '150px', 
      height: '14px',
      // flexShrink: 1,
      // whiteSpace: 'nowrap',
      overflow: "hidden",
      // textOverflow: "ellipsis",
    },
    PlanningProgressBar_Text: {
      fontSize: 12,
      color: "#434746",
      textAlign: "right",
      // width: '100px', 
      // whiteSpace: 'nowrap',
      overflow: "hidden",
      // textOverflow: "ellipsis",
    },
    PlanningProgressBar: {
      height: "10px",
      width: "5px",
      backgroundColor: "#0AED24",
      // borderRadius: "99px",
    },
    IQ_text: {
      fontSize: 16,
      paddingLeft: 10,
      color: "#1E232E",
    },
    IQ_content: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    line: {
      height: "130px",
      width: "1px",
      backgroundColor: "black",
    },
    substationsPlanning: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: 3,
    },

    statuses: {
      width: "58%",
    },
    linesSection: {
      width: "2%",
    },
    countSection: {
      width: "40%",
    },
    countValue: {
      width: "40%",
    },
    countLoop: {
      width: "100%",
    },
    progress1: {
      height: "14px",
      backgroundColor: "#25d739",
    },
    progress2: {
      height: "14px",
      backgroundColor: "#77c980",
    },
    progress3: {
      height: "14px",
      backgroundColor: "#d8f4ce",
    },
    progress4: {
      height: "14px",
      backgroundColor: "#8b4242",
    },
    progress5: {
      height: "14px",
      backgroundColor: "#25d739",
    },
  });

  const getProgressBarStyle = (index, count) => {
    // const width = `${count + 5}px`;
    switch (index) {
      case 1:
        return {
          ...styles.progress1,
          width: `${count * 5}px` /* specific styles for progress1 */,
        };
      case 2:
        return {
          ...styles.progress2,
          width: `${count * 5}px` /* specific styles for progress2 */,
        };
      case 3:
        return {
          ...styles.progress3,
          width: `${count * 5}px` /* specific styles for progress2 */,
        };
      case 4:
        return {
          ...styles.progress4,
          width: `${count * 5}px` /* specific styles for progress2 */,
        };
      case 5:
        return {
          ...styles.progress5,
          width: `${count * 5}px` /* specific styles for progress2 */,
        };

      default:
        return { ...styles.progress1, width: `${count * 5}px` };
    }
  };
  console.log(project, "project");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            style={styles.headerImage}
            src="https://voltquant-images.s3.eu-west-2.amazonaws.com/topbanner.png" // Replace with your image path or URL
          />
          <Text style={styles.header}>{project?.projectName}</Text>
          <Text style={styles.content}>{project.comment}</Text>
          <Text style={styles.content}>{project?.ProjectStatus?.name}</Text>
          <View style={styles.view1}>
            <View style={styles.view1_subsection}>
              <Text style={styles.subHeader}>Substation Information</Text>
              <View style={styles.substationMainHead}>
                <View style={styles.substationHeadView}>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfo}>Substation</Text>
                  </View>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfoResponse}>
                      {project?.selectedSubstation?.Name}
                    </Text>
                  </View>
                </View>
                <View style={styles.substationHeadView}>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfo}>Status</Text>
                  </View>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfoResponse}>
                      {project?.selectedSubstation?.ConstrainedType || project?.selectedSubstation?.[getCurrentSeasonUK()]}
                    </Text>
                  </View>
                </View>
                <View style={styles.substationHeadView}>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfo}>Coordinates</Text>
                  </View>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfoResponse}>
                      {fixed(project?.selectedSubstation?.Lattitude, 4)},
                      {fixed(project?.selectedSubstation?.Longitute, 4)}
                    </Text>
                  </View>
                </View>
                <View style={styles.substationHeadView}>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfo}>DNO</Text>
                  </View>
                  <View style={styles.substationHeadView1}>
                    <Text style={styles.substationsInfoResponse}>
                      {project?.dno}
                    </Text>
                  </View>
                </View>

              </View>
            </View>
            <View style={styles.view1_subsection2}>
              <Image
                style={styles.smallImage}
                src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/volt-image.png" // Replace with your image path or URL
              />
              <Text style={styles.siteVoltage}>Site Voltage</Text>
              <Text style={styles.siteVoltageRes}>
                {project?.voltageLine} kV
              </Text>
            </View>
          </View>
          <View style={styles.view2}>
            {project?.demandHeadroom && (
              <View style={styles.view2_subsection}>
                <Image
                  style={styles.smallImage2}
                  src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/volt-image.png" // Replace with your image path or URL
                />
                <View>
                  <Text style={styles.siteVoltage}>Demand Headroom</Text>
                  <Text style={styles.siteVoltageRes}>
                    {fixed(project?.demandHeadroom, 2)} MW
                  </Text>
                </View>
              </View>
            )}
            {project?.generationHeadroom && (
              <View style={styles.view2_subsection}>
                <Image
                  style={styles.smallImage2}
                  src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/volt-image.png" // Replace with your image path or URL
                />
                <View>
                  <Text style={styles.siteVoltage}>Generation Headroom</Text>
                  <Text style={styles.siteVoltageRes}>
                    {fixed(project.generationHeadroom, 2)} MW
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.view3}>
            <View style={styles.view3Section}>
              <View style={styles.view3_subsection}>
                <Text style={styles.subHeader}>IQ Score</Text>
                <View style={styles.IQ_content}>
                  <View style={styles.IQframeBar}>
                    <View style={styles.IQprogressBar}></View>
                  </View>
                  <Text style={styles.IQ_text}>
                    {fixed(project?.iqScore, 1)}%
                  </Text>
                </View>
              </View>
              <View style={styles.view3_subsection}>
                <Text style={styles.subHeader}>Risk Score</Text>
                <View style={styles.IQ_content}>
                  <View style={styles.IQframeBar}>
                    <View style={styles.RiskprogressBar}></View>
                  </View>
                  <Text style={styles.IQ_text}>
                    {fixed(project?.riskScore, 1)}%
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.view3_subsection2}>
              <Text style={styles.subHeader}>Location Planning</Text>
              <View style={styles.substationsPlanning}>
                {project.planningData && (
                  <View style={styles.statuses}>
                    {Object.entries(project?.planningData).map(
                      ([status, count], index) => (
                        <View key={index}>
                          <View style={styles.substationsStatus}>
                            <Text style={styles.PlanningProgressBar_Text1} numberOfLines={1} ellipsizeMode="tail">
                              {status}
                            </Text>
                          </View>
                        </View>
                      )
                    )}
                  </View>
                )}
                <View style={styles.linesSection}>
                  <View style={styles.line}></View>
                </View>
                {project?.planningData && (
                  <View style={styles.countSection}>
                    {Object.entries(project?.planningData).map(
                      ([status, count], index) => (
                        <View key={index} style={styles.countLoop}>
                          <View style={styles.substationsStatus}>
                            <View style={styles.PlanningFrameBar}>
                              <View
                                key={index}
                                style={getProgressBarStyle(index + 1, count)}
                              ></View>
                            </View>
                            <View style={styles.countValue}>
                              <Text style={styles.PlanningProgressBar_Text}>
                                {count}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )
                    )}
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={styles.view2}>
            <View style={styles.view2_subsection1}>
              <Image
                style={styles.mapImage}
                src={mapPng} // Replace with your image path or URL
              />
            </View>
            <View style={styles.view2_subsection2}>
              <Text style={styles.subHeader}>Search Criteria</Text>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Location:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {project?.searchInput}
                  </Text>
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Radius:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {`${project?.minRadius} - ${project?.maxRadius} ${project?.radiusUnit}`}
                  </Text>
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Asset Type:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {project?.assetType}
                  </Text>
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Project Capacity:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {project?.capacityUnit === "kW"
                      ? parseFloat(project?.minCapacity) * 1000 + "kW+ Capacity"
                      : project?.minCapacity + "MW+ Capacity"}
                  </Text>
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Quick Selectors:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {project?.demand && project?.generation
                      ? "Demand - Generation"
                      : project?.demand
                        ? "Demand"
                        : project?.generation
                          ? "Generation"
                          : ""}
                  </Text>
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Filters:</Text>
                  {/* <Text style={styles.substationsInfo}>Date Created:</Text> */}
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {project?.riskAndIQ?.[0] + "-" + project?.riskAndIQ?.[1]}
                  </Text>
                  {/* <Text style={styles.substationsInfoResponse}>
                    {convertDateFormat(project?.date)}
                  </Text> */}
                </View>
              </View>
              <View style={styles.displayFlex}>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfo}>Date Created:</Text>
                </View>
                <View style={styles.substationHead}>
                  <Text style={styles.substationsInfoResponse}>
                    {convertDateFormat(project?.date)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section2}>
            <Image
              style={styles.headerImage}
              src="https://voltquant-images.s3.eu-west-2.amazonaws.com/bottombanner.png" // Replace with your image path or URL
            />
          </View>
        </View>
        {/* ... Add more Views as needed */}
      </Page>
    </Document>
  );
};

export default MyPDFDocument;
