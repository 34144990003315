import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "../../Common/Button/Button";
import styles from "./styles.module.scss";
import TextInput from "../../Common/TextInput";
import { ToastNotification } from "../../Utils/ToastNotifications";

const ProjectPasswordModal = (props) => {
  const { password, setPassword, open, setOpen, fetchProjectDetail } = props;

  const handleSubmit = () => {
    if (password.trim() === '') {
      ToastNotification("warn", "Password is required");
    } else {
      fetchProjectDetail()
      setOpen(false)
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Enter Password</h1>
          </div>
          <TextInput
            type="password"
            value={password}
            setValue={setPassword}
            placeholder="Enter Password"
          />
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Submit"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ProjectPasswordModal;
