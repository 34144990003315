import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import CustomSelect from "../../Common/CustomSelect";

const ShareCollectionsModal = (props) => {
  const { open, setOpen, setSelectedUsers, handleShareCollection } = props;
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.shareContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Share Collection</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>Share <span>My Collection</span> by email</p>
          <div className={styles.selectContainer}>
            <div style={{ width: "395px" }}>
              <CustomSelect
                onChange={(option) => setSelectedUsers(option)}
                isMulti={true}
                isClearable={true}
                placeholder="Email, comma separated"
              />
            </div>
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="12px"
              borderColor="#1F443A"
              handleClick={handleShareCollection}
              text="Invite"
              padding='19px 32px'
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ShareCollectionsModal;
