import { createSlice } from "@reduxjs/toolkit";

const selectedSubstationSlice = createSlice({
  name: "selectedSubstation",
  initialState: {
    substation: null,
  },
  reducers: {
    setSelectedSubstation: (state, action) => {
      state.substation = action.payload;
    },
  },
});

export const { setSelectedSubstation } = selectedSubstationSlice.actions;
export default selectedSubstationSlice.reducer;
