import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import {
  setProjects,
  setProjectsLoading,
  setCollections,
  setSelectedCollections,
  setFetchProjects,
} from "../../Components/Redux/reducers/projectsSlice";
import Filters from "../../Components/Projects/Filters";
import Loader from "../../Components/Common/Loader/Loader";
import {
  deleteProject,
  fetchAllCollection,
  fetchCollectionProjects,
  userRemovedSubstation,
} from "../../Components/Api/API";
import TabButtons from "../../Components/Projects/TabButtons";
import styles from "./styles.module.scss";
import ProjectCard from "../../Components/ProjectCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Button from "../../Components/Common/Button/Button";
import { Trash } from "../../Components/SVG";
import { useNavigate } from "react-router-dom";
import TableComponent from './../../Components/Table/index';
import DeleteProjectModal from "../../Components/Modals/DeleteProjectModal";

const Projects = () => {
  const [searchByName, setSearchByName] = useState("");
  const [columnView, setColumnView] = useState(true);
  const [listView, setListView] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [selectedAssetType, setSelectedAssetType] = useState("None");
  const [selectedAlphabeticSorting, setSelectedAlphabeticSorting] =
    useState("None");
  const [headromType, setHeadromType] = useState("None");
  const [status, setStatus] = useState("None");
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");
  const [minRadius, setMinRadius] = useState("");
  const [maxRadius, setMaxRadius] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [headCells, setHeadCells] = useState([
    {
      id: 'project_name',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Project Name',
      minWidth: '200px'
    },
    {
      id: 'asset_type',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Asset Type',
      minWidth: '100px'
    },
    {
      id: 'headrom_type',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Headrom Type',
      minWidth: '120px'
    },
    {
      id: 'radius',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Radius',
      minWidth: '150px'
    },
    {
      id: 'distance',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Distance',
      minWidth: '150px'
    },
    {
      id: 'project_capacity',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Project Capacity',
      minWidth: '150px'
    },
    {
      id: 'substation_name',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Substation Name',
      minWidth: '150px'
    },
    {
      id: 'site_voltage',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Site voltage',
      minWidth: '100px'
    },
    {
      id: 'demand_headrom',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Demand Headrom',
      minWidth: '150px'
    },
    {
      id: 'generation_headrom',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Generation Headrom',
      minWidth: '150px'
    },
    {
      id: 'constraint_type',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Constraint Type',
      minWidth: '150px'
    },
    {
      id: 'dno',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'DNO',
      minWidth: '100px'
    },
    {
      id: 'iq_score',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'IQ Score',
      minWidth: '100px'
    },
    {
      id: 'risk_score',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Risk Score',
      minWidth: '100px'
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Date',
      minWidth: '100px'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      checked: true,
      label: 'Status',
      minWidth: '180px'
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      checked: true,
      label: 'Action',
      minWidth: '60px'
    }
  ]);

  const { selectedCollection, projects, projectsLoading, fetchProjects } = useSelector(
    (state) => state.projects
  );
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllCollection();
    return () => dispatch(setFetchProjects(null));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects?.projects?.length === 0 && pageNumber > 1) {
      setPageNumber(1); // Reset page number to 1 if projects are empty and page number is greater than 1
    }
  }, [projects]) // eslint-disable-line react-hooks/exhaustive-deps

  const generateRandomString = (length = 10) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };


  const getAllCollection = () => {
    dispatch(setProjectsLoading(true));
    fetchAllCollection().then((res) => {

      if (res?.code === 400 && selectedCollection?.projectCount === 0) {
        ToastNotification("error", "Please save a project from Map View.");
        dispatch(setProjectsLoading(false));
      }
      if (res?.success) {
        if (res?.data?.length === 0) {
          ToastNotification("error", "Please save a project from Map View.");
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.length > 0) {
          // ToastNotification("success", res.message);
          dispatch(setCollections(res?.data));
          if (selectedCollection?.hasOwnProperty("id")) {
            const selected = res?.data?.filter((item) => item?.id === selectedCollection?.id)
            const randomString = generateRandomString(); // Default length is 10, adjust as needed

            dispatch(setFetchProjects(randomString))
            dispatch(setSelectedCollections(selected?.[0]));
            // getAllProjects(selectedCollection?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting);
          } else {
            dispatch(setSelectedCollections(res?.data?.[0]));
            const randomString = generateRandomString(); // Default length is 10, adjust as needed

            dispatch(setFetchProjects(randomString))
            // getAllProjects(res?.data?.[0]?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting);
          }
          // dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  const getAllProjects = (id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, listView, rowsPerPage, minCapacity, maxCapacity, minRadius, maxRadius) => {

    dispatch(setProjectsLoading(true));
    fetchCollectionProjects(id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, listView, rowsPerPage, minCapacity, maxCapacity, minRadius, maxRadius).then((res) => {
      dispatch(setProjects([]));

      if (res.code === 400 && selectedCollection?.projectCount === 0) {
        ToastNotification("error", "Please save a project from Map View.");
        dispatch(setProjectsLoading(false));
      }
      if (res.success) {
        if (res?.data?.projects?.length === 0 && selectedCollection?.projectCount === 0) {
          ToastNotification("error", "Please save a project from Map View.");

          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length === 0 && selectedCollection?.projectCount !== 0 && (searchByName?.length > 0 || selectedAssetType?.length > 0 || selectedAlphabeticSorting?.length > 0 || headromType?.length > 0 || status?.length > 0)) {
          ToastNotification("error", "Not saved any project against this filters");

          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  useEffect(() => {
    if (selectedCollection?.hasOwnProperty('id') && fetchProjects?.length > 0) {
      getAllProjects(selectedCollection?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, listView, rowsPerPage, minCapacity, maxCapacity, minRadius, maxRadius);
    }
  }, [selectedCollection, pageNumber, fetchProjects, rowsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersProject = () => {
    if (searchByName?.length > 0 || selectedAssetType?.length > 0 || selectedAlphabeticSorting?.length > 0) {
      getAllProjects(selectedCollection?.id, pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, listView, rowsPerPage, minCapacity, maxCapacity, minRadius, maxRadius);
    } else {
      ToastNotification("error", "Please enter any filter");

    }
  }

  const formatDateString = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1.
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDeleteProject = () => {
    deleteProject(selectedProject?.id).then((res) => {
      if (res.success) {
        ToastNotification("success", res.message);
        let body = {
          substationId: selectedProject?.selectedSubstation?.SubstationID
        }
        userRemovedSubstation(body).then((res) => {
          console.log("User saved substation")
        })
        setOpenDeleteModal(false);
        getAllCollection();
      }
    });
  };

  return (
    <div className={styles.projectContainer}>
      <div className={styles.projects} >
        <div className={styles.projects__container} >
          <h1 className={styles.projects__heading}>My Projects</h1>
          <Button
            height='45px'
            bgColor='#FFF5F6'
            color='#EA374A'
            radius='12px'
            borderColor='#FFF5F6'
            icon={<Trash />}
            text='Deleted Items'
            handleClick={() => navigate('/deleted-projects')}
          />
        </div>
        <TabButtons getAllCollection={getAllCollection} />
        <Filters
          searchByName={searchByName}
          setSearchByName={setSearchByName}
          setSelectedAssetType={setSelectedAssetType}
          selectedAssetType={selectedAssetType}
          selectedAlphabeticSorting={selectedAlphabeticSorting}
          setSelectedAlphabeticSorting={setSelectedAlphabeticSorting}
          handleFiltersProject={handleFiltersProject}
          headromType={headromType}
          setHeadromType={setHeadromType}
          status={status}
          setStatus={setStatus}

          minCapacity={minCapacity}
          setMinCapacity={setMinCapacity}
          maxCapacity={maxCapacity}
          setMaxCapacity={setMaxCapacity}
          minRadius={minRadius}
          setMinRadius={setMinRadius}
          maxRadius={maxRadius}
          setMaxRadius={setMaxRadius}

          headCells={headCells}
          setHeadCells={setHeadCells}
          columnView={columnView}
          setColumnView={setColumnView}
          listView={listView}
          setListView={setListView}
          setRowsPerPage={setRowsPerPage}
        />
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          {projectsLoading ? (
            <Loader />
          ) : (
            <>
              {projects?.projects?.length > 0 && columnView ? (
                projects?.projects?.map((project) => {
                  const mapedDate = formatDateString(project?.date);
                  return (
                    <Grid item xs={4} key={project.id}>
                      <ProjectCard
                        project={project}
                        mapedDate={mapedDate}
                        showButton={true}
                        getAllCollection={getAllCollection}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        setOpenDeleteModal={setOpenDeleteModal}
                      />
                    </Grid>
                  );
                })
              ) : projects?.projects?.length > 0 && listView ? (
                <div style={{ paddingLeft: '24px', width: '100%', boxSizing: 'border-box' }}>
                  <TableComponent
                    headCells={headCells}
                    projects={projects}
                    getAllCollection={getAllCollection}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setOpenDeleteModal={setOpenDeleteModal}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </div>
              ) : (
                <Box sx={{ textAlign: "center", width: "100%", p: 20 }}>
                  <Typography variant="h5">
                    There are no projects to show
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Grid>

      </div>
      <div
        className={styles.pagination}
      >
        {projects?.total > 9 && columnView && selectedCollection?.projectCount > 9 && (
          <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Pagination
              page={pageNumber}
              count={Math.ceil((projects?.total || 0) / 9)}
              size="large"
              onChange={(event, value) => {
                setPageNumber(value);
                // getAllProjects(selectedCollection?.id, value, searchByName, selectedAssetType, selectedAlphabeticSorting)
              }}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#1F443A !important",
                  color: "white",

                },
              }}
            />
          </Stack>
        )}
      </div>
      <DeleteProjectModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDetele={handleDeleteProject}
      />
    </div>

  );
};

export default Projects;
