import React, { useState, useEffect, useRef } from 'react';
import { useControl } from "react-map-gl";
import { map } from "leaflet";
import "./myMap.css";


const NpsControl = ({ mapZoom, setShowNPS, showNPS, setShowCOD, showCOD, showPrivate, setShowPrivate }) => {
    const [showModal, setShowModal] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    // const [mapZoom, setMapZoom] = useState(0);
    const modalRef = useRef(null);

    const handleCheckboxChange = (setter) => () => {
        setter(prev => !prev);
    };

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (mapZoom > 13) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [mapZoom]);

    const control = {
        onAdd(map) {
            this._map = map;
            // setMapZoom(map.getZoom());
            this._container = document.createElement("div");
            this._container.className = "mapboxgl-ctrl";
            const button = document.createElement("button");
            button.classList.add("npsButton");
            button.textContent = "Land Boundaries";

            button.onclick = () => {
                if (map.getZoom() >= 13) {
                    setShowButtons(true);
                } else {
                    setShowButtons(false);
                }
                setShowModal((prev)=> !prev);
            };

            this._container.appendChild(button);
            return this._container;
        },
        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        },
    };

    useControl(() => control);

    return (
        <div ref={modalRef}>
            {(showModal && !showButtons) && (
                <p className="npsModalWarning">
                    Zoom in further to see land ownership parcels.
                </p>
            )}
            {showModal && showButtons && (
                <div className="npsModal">
                    <div className="modalContent">
                        <label>
                            <input type="checkbox" checked={showNPS} onChange={handleCheckboxChange(setShowNPS)} />
                            All Land
                        </label>
                        <label>
                            <input type="checkbox" checked={showPrivate} onChange={handleCheckboxChange(setShowPrivate)} />
                            Privately owned Land
                        </label>
                        <label>
                            <input type="checkbox" checked={showCOD} onChange={handleCheckboxChange(setShowCOD)} />
                            Company-owned Land
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NpsControl;
