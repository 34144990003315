import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    color: "#fff",
    fontFamily: "Eudoxus sans",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
  },
});

const AppCheckBox = ({ text, checked, onChange }) => {
  return (
    <ThemeProvider theme={theme}>
      <div style={{
        background: checked ? '#1F443A' : '#fff',
        paddingRight: '16px',
        borderRadius: '12px',
        color: checked ? 'white' : '#4B4F58',
        border: '1px solid #DCDFE5'
      }}>
        <FormGroup>
          <FormControlLabel
            value="start"
            labelPlacement="start"
            control={<Checkbox style={{ color: checked ? "#fff" : '#1F443A' }} />}
            label={text}
            checked={checked}
            onChange={onChange}
          />
        </FormGroup>
      </div>
    </ThemeProvider>
  );
};

export default AppCheckBox;
