export const removeBrackets = (name) => {
    return name.replace(/\([^)]*\)/, '').trim();
  };

  export const findTitleFromLayerName = (linksObject, layersArray, targetValue) => {
    const foundKey = Object.keys(linksObject).find(key => linksObject[key] === targetValue);
  
    if (foundKey) {
      const matchingLayer = layersArray.find(layer => layer.name === foundKey);
  
      if (matchingLayer) {
        return matchingLayer.title;
      }
    }
  
    return null; 
  }