import React from "react";
import AppTextBox from "../../Common/AppTextBox/AppTextBox";
import AppButton from "../../Common/Button/AppButton";
import styles from "./styles.module.scss";

const Assets = ({ assetsButtonsArray, handleButtonClick, buttonText }) => {
  return (
    <div className={styles.assets_container}>
      <AppTextBox text="asset type" />
      <div className={styles.assets_buttons}>
        {assetsButtonsArray.map((item) => (
          <AppButton
            key={item.id}
            text={item.text}
            onClick={() => handleButtonClick(item.text)}
            isSelected={item.text === buttonText ? true : false}
            id={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Assets;
