import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "./../../Utils/ToastNotifications";
import { sendEmail } from "../../Api/API";
import TextArea from "../../Common/TextArea";
import styles from "./styles.module.scss";
import Recommendation from "./Recommendation";
import ProjectType from "./ProjectType";
import Rating from "./Rating";
import { useSelector } from "react-redux/es";

const FeedbackModal = (props) => {
  const { openFeedbackModal, setOpenFeedbackModal } = props;

  const { userDetail } = useSelector((state) => state.users);
  const [textareaContent, setTextareaContent] = useState("");
  const [count, setCount] = useState(1);
  const [recommendation, setRecommendation] = useState("😃  Very Likely");
  const [projectType, setProjectType] = useState("Real Estate");
  const [rating, setRating] = useState("5 (Excellent)  😃");

  const handleClose = () => setOpenFeedbackModal(false);
  const submitFeedback = async () => {
    if (textareaContent.trim() === "") {
      ToastNotification("error", "Feedback is required");
    } else {
      let body = {
        name: userDetail?.user?.first_name
          ? userDetail?.user?.first_name
          : "John",
        email: userDetail?.user.email,
        recomendation: recommendation,
        projectType: projectType,
        rating: rating,
        message: textareaContent,
        subject: "Feedback submitted",
      };
      sendEmail(body)
        .then((response) => {
          if (response.success) {
            setOpenFeedbackModal(false);
            setTextareaContent(""); // Clear the textarea content after sending
            ToastNotification("success", response.message);
          }
        })
        .catch((err) => {
          console.error("Error sending email:", err);
          ToastNotification("error", "Error: Sending feedback");
        });
    }
  };

  return (
    <div>
      <Dialog
        open={openFeedbackModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.feedbackContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Feedback</h1>
            <Close onClick={handleClose} />
          </div>
          <p className={styles.para}>
            Share your feedback or ideas with us! Your input is a key driver for
            our platform's evolution, helping us create a better experience.
          </p>
          {count === 1 && (
            <Recommendation
              recommendation={recommendation}
              setRecommendation={setRecommendation}
            />
          )}
          {count === 2 && (
            <ProjectType
              projectType={projectType}
              setProjectType={setProjectType}
            />
          )}
          {count === 3 && <Rating rating={rating} setRating={setRating} />}
          {count === 4 && (
            <>
              <p className={styles.para1}>
                Please share any additional comments or suggestions you have
                regarding your experience with VOLTQUANT.
              </p>
              <TextArea
                value={textareaContent}
                setValue={setTextareaContent}
                placeholder="Your comment"
              />
            </>
          )}

          <div className={styles.buttonContainer}>
            {count > 1 && (
              <Button
                height="40px"
                bgColor="#F3F4F6"
                color="#1E232E"
                radius="100px"
                borderColor="#F3F4F6"
                text="Back"
                handleClick={() => setCount(count - 1)}
              />
            )}
            {count === 4 ? (
              <Button
                height="40px"
                bgColor="#1F443A"
                color="#fff"
                radius="100px"
                borderColor="#1F443A"
                text="Submit"
                handleClick={submitFeedback}
              />
            ) : (
              <Button
                height="40px"
                bgColor="#1F443A"
                color="#fff"
                radius="100px"
                borderColor="#1F443A"
                text="Continue"
                handleClick={() => setCount(count + 1)}
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default FeedbackModal;
