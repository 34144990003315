import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Loader from '../../Components/Common/Loader/Loader';
import { saveBulkProject } from '../../Components/Api/API';
import { ToastNotification } from '../../Components/Utils/ToastNotifications';

const LoadingText = (props) => {
    const { csvData, count, setCount, loading, setLoading, selectedCollection } = props;

    const createBulkProject = async () => {
        setLoading(true)
        let body = {
            projects: csvData?.records,
            collectionId: selectedCollection?.id
        };
        saveBulkProject(body)
            .then((response) => {
                if (response.success) {
                    setCount(count + 1)
                    ToastNotification("success", response.message);
                    setLoading(false)
                }
            })
            .catch((err) => {
                ToastNotification("error", "Error: While creating bulk projects");
            });
    };

    useEffect(() => {
        createBulkProject()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className={styles.loadingText}>
            {loading && <Loader />}
            <div className={styles.loadingText}>
                <p>We are currently processing your data. Please allow us a few moments.</p>
            </div>
        </div>
    );
};

export default LoadingText;
