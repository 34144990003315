import React, { useState } from "react";
import {
  power_plant_coal, power_plant_battery, power_plant_biomass, power_plant_geothermal,
  power_plant_hydro, power_plant_nuclear, power_plant_wind, power_plant_solar,
  power_plant_oilgas
} from "../SVG";
import './myMap.css'
const powerPlantOptions = [
  {
    name: "Coal",
    image: power_plant_coal,
  },
  {
    name: "Battery",
    image: power_plant_battery,
  },

  {
    name: "Biomass",
    image: power_plant_biomass,
  },

  {
    name: "Geothermal",
    image: power_plant_geothermal,
  },
  {
    name: "Hydro",
    image: power_plant_hydro,
  },
  {
    name: "Nuclear",
    image: power_plant_nuclear,
  },
  {
    name: "Oil",
    image: power_plant_oilgas,
  },
  {
    name: "Gas",
    image: power_plant_oilgas,
  },
  {
    name: "Diesel",
    image: power_plant_oilgas,
  },
  {
    name: "Wind",
    image: power_plant_wind,
  },
  {
    name: "Solar",
    image: power_plant_solar,
  },
];

const PowerPlantLegend = () => {

  const [optionsVisible, setOptionsVisible] = useState(true);
  const handleToggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="legend-main">
      <div
        onClick={handleToggleOptions}
        className='legend-heading'
      >
        <svg
          width="10"
          height="10"
          style={{
            marginRight: "4px",
            transform: optionsVisible ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <polygon points="0,0 10,5 0,10" fill="black" />
        </svg>
        Power Plant
      </div>
      {optionsVisible && (
        <>
          {powerPlantOptions.map((option, index) => {
            const PlantImage = option.image
            return (
              <div
                key={option.name}
                style={{
                  width: "286px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: 'center'
                }}
              >
                <div>{option.name}</div>
                <div style={{ justifyContent: "flex", marginRight: "5px" }}>
                  <PlantImage />
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  );
};

export default PowerPlantLegend;
