import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { links } from "../../Common/MapFilterCard/layer.config";
import { layers } from "../../Common/MapFilterCard/layerCard.config";
import { findTitleFromLayerName } from "./utils";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const SpatialCard = (props) => {
  const { text } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const geoSpatialRisk = useSelector((state) => state.selectedLayers.geoSpatialRisk);
 
  return (
   
    <div className={styles.planningCardz}>
      <Accordion
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
              src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/spatial-analysis-icon.svg"
              alt="localplanning"
            />
            <div>
              <p className={styles.planningCardz__heading}>{text}</p>
              <p className={styles.planningCardz__heading__sub}>
                {geoSpatialRisk?.length > 1
                  ? `${geoSpatialRisk?.length} Potential Risks`
                  : geoSpatialRisk?.length === 1
                  ? "1 Potential Risk"
                  : "No Risk"}
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.planningCardz__danger}>
            <img
              src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/cross-icon.svg"
              alt="icon"
            />
            <div>
              <h1 className={styles.planningCardz__alert__heading}>
                Geospatial Risk
              </h1>
              <p className={styles.planningCardz__alert__para}>
                Major geospatial risks identified.{" "}
              </p>
              <ul>
                {
                  geoSpatialRisk.map((item,i)=>{
                    const name = findTitleFromLayerName(links,layers, item)
                  if(name)return(<li key={i}>{name}</li>)})
                }
              </ul>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
    
  );
};
