import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import ConvertToTitleCase from "../../Utils/TitleCaseConvertor";
import { capitalizeFirstLetter } from "../../Sidebar/LeftSidebar/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  borderRadius: 5,
};

const PolygonFeatureModal = ({ setOpen, open, data }) => {
  const { properties } = data;
  const handleClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {/* <div className={boxContainerClass}> */}
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div onClick={handleClose}>
              <CancelIcon />
            </div>
          </div>
          <Typography sx={{ mt: 2 }}>Feature Name</Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontWeight: "bold", color: "#1F443A" }}
          >
            {properties?.name ||
              properties?.rpt_jobnum ||
              properties?.title_number ||
              properties?.lsoa_name ||
              "Unnamed entity"}
          </Typography>
          <Grid container spacing={2}>
            {properties &&
              Object.entries(properties)
                ?.filter(
                  ([key, value]) =>
                    value !== null &&
                    value !== "" &&
                    key !== "name" &&
                    key !== "typology" &&
                    key !== "LAT" &&
                    key !== "LON"
                )
                .map(([key, value], index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <Box
                        sx={{
                          background: `${
                            index === 0
                              ? "#1F443A"
                              : index === 1
                              ? "#1F443A"
                              : "#F9F9F9"
                          }`,
                          color: `${
                            index === 0
                              ? "white"
                              : index === 1
                              ? "white"
                              : "#1F443A"
                          }`,
                          padding: "10px",
                          borderRadius: "10px",
                          wordBreak: "break-word",
                        }}
                      >
                        {/* properties names length is limited to 10 character on geoServer so we set it manually here  */}
                        <Typography sx={{ fontSize: 12 }}>
                          {key === "Connected"
                            ? "Connected Capacity to date (MW)"
                            : key === "Average MW"
                            ? "Average MW per Project"
                            : key === "Cumulative"
                            ? "Cumulative Queue Export Capacity (MW)"
                            : key === "Queue Leng"
                            ? "Queue Length (Pipeline)"
                            : key === "organisati"
                            ? "Organisation"
                            : key === "ancient-wo"
                            ? "Ancient Woodland"
                            : key === "documentat"
                            ? "Documentation"
                            : key === "operator ("
                            ? "Operator (or Applicant)"
                            : key === "technology"
                            ? "Technology Type"
                            : key === "chp enable"
                            ? "CHP Enabled"
                            : key === "mounting t"
                            ? "Mounting Type for Solar"
                            : key === "developmen"
                            ? "Development Status"
                            : key === "developm_1"
                            ? "Development Status (short)"
                            : key === "x-coordina"
                            ? "X-coordinate"
                            : key === "y-coordina"
                            ? "Y-coordinate"
                            : key === "planning a"
                            ? "Planning Authority"
                            : key === "planning_1"
                            ? "Planning Application Reference"
                            : key === "planning_2"
                            ? "Planning Application Submitted"
                            : key === "planning_4"
                            ? "Planning Permission Refused"
                            : key === "planning_3"
                            ? "Planning Application Withdrawn"
                            : key === "planning p"
                            ? "Planning Permission Granted"
                            : key === "planning_5"
                            ? "Planning Permission Expired"
                            : key === "under cons"
                            ? "Under Construction"
                            : key === "operationa"
                            ? "Operational"
                            : key === "installed"
                            ? "Installed Capacity (MWelec)"
                            : key === "latt"
                            ? "Latitude"
                            : key === "lon"
                            ? "Longitude"
                            : key === "power"
                            ? "Power Usage"
                            : key === "sqm"
                            ? "Area"
                            : key === "internetex"
                            ? "Internet exchange points"
                            : key === "peeringnet"
                            ? "Peering networks"
                            : key === "networkpro"
                            ? "Network providers"
                            : key === "tenants"
                            ? "Tenants offering colocation"
                            : key === "alsoknowna"
                            ? "Also known as"
                            : capitalizeFirstLetter(key)}
                          {/* {ConvertToTitleCase(key)} */}
                        </Typography>
                        <Typography sx={{ fontSize: 20, mt: 1 }} variant="h6">
                          {key === "voltage"
                            ? parseFloat(value)?.toFixed(2)
                            : key === "voltage_2"
                            ? parseFloat(value)?.toFixed(2)
                            : key === "voltage_3"
                            ? parseFloat(value)?.toFixed(2)
                            : key === "output"
                            ? parseFloat(value)?.toFixed(2)
                            : capitalizeFirstLetter(value)}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default PolygonFeatureModal;
