import React from "react";
import axios from "axios";
import Logo from "./../../../../Images/Logo1.svg";
import { ToastNotification } from "../../../../Components/Utils/ToastNotifications";
import LoginRightSide from "../LoginRightSide";
import styles from "../styles.module.scss";
import { baseURL } from "../../../../Components/Api/instance";

const VerificationCode = (props) => {
  const { code, setCode, count, setCount, email, isValid, setIsValid } = props;

  const resendEmail = (e) => {
    e.preventDefault();

    const formData = {
      username: email,
    };
    axios
      .post(
        `${baseURL}/user/forgot-password`,
        formData
      )
      .then((res) => {
        ToastNotification("success", "Email sent");
      })
      .catch((e) => {
        ToastNotification("error", e?.response?.data?.message);
        console.log("error", e);
      });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (code === "") {
      ToastNotification("warn", "Verification code required");
      return;
    } else if (!isValid) {
      ToastNotification("warn", "Invalid verification code.");
      return;
    } else {
      setCount(count + 1);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Check if the entered value consists of numbers and has length 6
    const isValidCode = /^\d{6}$/.test(value);
    setCode(value);
    setIsValid(isValidCode);
  };

  return (
    <div className={styles.signup}>
      <div className={styles.signup__container}>
        <img src={Logo} alt="logo vector" />

        <h1 className={styles.signup__container__heading}>Verification Code</h1>
        <p className={styles.signup__container__para}>
          We’ve sent a verification code to your email.
        </p>

        <form>
          <input
            type="text"
            onChange={handleChange}
            placeholder="Verification code"
            value={code}
          />
          <button type="submit" onClick={handleContinue}>
            Continue
          </button>
        </form>
        <p className={styles.signup__container__spanLink}>
          Didn't receive an email? <span onClick={resendEmail}>Resend</span>
        </p>
      </div>
      <LoginRightSide />
    </div>
  );
};

export default VerificationCode;
