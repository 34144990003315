import React from 'react';
import styles from './styles.module.scss';
import Button from '../../Components/Common/Button/Button';
import { CheckWithCircle } from '../../Components/SVG';
import { useNavigate } from 'react-router-dom';

const Congratulation = (props) => {
    const { setCount, setCsvData, setFileName, setShowAnalysis } = props;
    const navigate = useNavigate();

    const moveIntoProjectPage = (project) => {
        navigate('/projects');
    };

    return (
        <div className={styles.recommendation} style={{ textAlign: 'center' }}>
            <br />
            <br />
            <CheckWithCircle />
            <h3 className={styles.projectMapping} style={{ margin: '10px 0px' }}>Data Processed!</h3>
            <p className={styles.para} style={{ margin: '0px' }}>Congratulations, your data has been processed successfully.</p>
            <div style={{ paddingTop: '24px', width: '100%' }}>
                <div className={styles.buttonMainContainer} style={{ justifyContent: 'center' }}>
                    <Button
                        height="40px"
                        bgColor="#EDF0EF"
                        color="#1F443A"
                        radius="12px"
                        borderColor="#EDF0EF"
                        text="Import another file"
                        handleClick={() => {
                            setCount(0);
                            setCsvData(null);
                            setFileName(null);
                            setShowAnalysis(false)
                        }}
                    />
                    <Button
                        height="40px"
                        bgColor="#1F443A"
                        color="#fff"
                        radius="12px"
                        borderColor="#1F443A"
                        text="Go to My Collections"
                        handleClick={() => moveIntoProjectPage()}
                    />
                </div>
            </div>
        </div>
    );
};

export default Congratulation;
