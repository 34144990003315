import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "../reducers/filterSlice";
import substationSlice from "../reducers/substationSlice";
import datasetSlice from "../reducers/datasetSlice";
import projectsSlice from "../reducers/projectsSlice";
import usersSlice from "../reducers/usersSlice";
import selectedSubstationReducer from "../reducers/selectedSubstation";
import popUpStationSlice from "../reducers/popUpStationSlice";
import selectedLayersReducer from "../reducers/selectedLayers";
import gptOsmDataReducer from "../reducers/gptOsmSlice";
import chatSlice from "../reducers/chatSlice";

const Store = configureStore({
  reducer: {
    filters: filterReducer,
    substations: substationSlice,
    dataset: datasetSlice,
    projects: projectsSlice,
    users: usersSlice,
    selectedSubstation: selectedSubstationReducer,
    popupStation: popUpStationSlice,
    selectedLayers: selectedLayersReducer,
    gptOsmData: gptOsmDataReducer,
    chat: chatSlice,
  },
});
export default Store;
