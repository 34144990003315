import React from "react";
import CreatableSelect from "react-select/creatable";

const CustomSelect = ({
  options,
  onChange,
  value,
  placeholder,
  isMulti = false,
  isClearable = false,
  ...props
}) => {

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: '100px', // This controls the scrollable area's height
    }),
    // Add other custom styles here if needed
  };

  return (
    <CreatableSelect
      isClearable={isClearable}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isMulti={isMulti}
      value={value}
      styles={customStyles} // Apply the custom styles

      {...props}
    />
  );
};

export default CustomSelect;

// import React from 'react';
// import Select from 'react-select';

// const CustomSelect = ({ options, onChange, placeholder, isMulti = false, ...props }) => {
//     return (
//         <Select
//             options={options}
//             onChange={onChange}
//             placeholder={placeholder}
//             isMulti={isMulti}
//             {...props}
//         />
//     );
// };

// export default CustomSelect;
