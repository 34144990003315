import React from 'react';
import styles from './styles.module.scss';
const Footer = () => {
    return (
        <div className={styles.signup__container__footer}>
            <p className={styles.signup__container__footer__para}>By creating an account you agree with our</p>
            <div className={styles.signup__container__footer__links}>
                <p>
                    <a style={{marginLeft: 0}} href="https://voltquant.com/terms/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>,
                    <a href="https://voltquant.com/terms/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>,
                    <a href="https://voltquant.com/terms/end-user-licence-agreement" target="_blank" rel="noreferrer">End-User License Agreement (EULA)</a>,
                    <a href="https://voltquant.com/terms/acceptable-use-policy" target="_blank" rel="noreferrer">Acceptable Use Policy</a>,
                    <a href="https://voltquant.com/terms/disclaimer" target="_blank" rel="noreferrer">Disclaimer</a>, and&nbsp;
                    <a href="https://voltquant.com/terms/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>.
                </p>
            </div>
        </div>
    );
};

export default Footer;
