import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Profile from "../Profile/Profile";
// import Logo from "./../../Images/Logo1.svg";
import { Link, useLocation } from "react-router-dom";
import MapNavBar from "../Common/MapNavBar/MapNavBar";
import { Logo } from './../SVG/index';
import Notifications from "../Notifications";
import { useSelector, useDispatch } from "react-redux";
import { ToastNotification } from "../Utils/ToastNotifications";
import { setNewOneToOneMessage, setNewGroupMessage, setNewCompanyGroupMessage } from "../Redux/reducers/chatSlice";
import socketService from "../../Socket/socket";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { newOneToOneMessage, newGroupMessage, newCompanyGroupMessage } = useSelector((state) => state.chat);
  const { userDetail } = useSelector((state) => state.users);

  useEffect(() => {
    if (location?.pathname !== "/collaboration") {
      if (newOneToOneMessage?.type === "OneToOne") {
        ToastNotification("info", `${newOneToOneMessage?.senderUsername} send you a message`, "bottom-right");
        dispatch(setNewOneToOneMessage(null));
      }
    }
    // return () => {
    //   console.log("newOneToOneMessage in working")
    //   dispatch(setNewOneToOneMessage(null));
    // }

  }, [newOneToOneMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname !== "/collaboration") {
      if (newGroupMessage?.type === "Group") {
        ToastNotification("info", "You received a message in a group", "bottom-right");
        dispatch(setNewGroupMessage(null));
      }
    }
    // return () => {
    //   console.log("newOneToOneMessage in working")
    //   dispatch(setNewGroupMessage(null));
    // }

  }, [newGroupMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname !== "/collaboration") {
      if (newCompanyGroupMessage?.type === "CompanyGroup") {
        ToastNotification("info", "You received a message in organization", "bottom-right");
        dispatch(setNewCompanyGroupMessage(null));
      }
    }
    // return () => {
    //   dispatch(setNewCompanyGroupMessage(null));
    // }

  }, [newCompanyGroupMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    socketService.setUserId(userDetail?.user?.id);
  }, [userDetail]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#1F443A",
        boxShadow: "none",
        // borderBottom: "1px solid #1F443A",
        zIndex: 11
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div>
            <Link to='/'>
              <Logo fill='#fff' />
              {/* <img src={Logo} alt="logo vector" /> */}
            </Link>
          </div>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box
            style={{ marginLeft: "50px", marginRight: "139px" }}
            sx={{ flexGrow: 1 }}
          >
            <MapNavBar />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1, gap: '10px' }}>
            <Notifications />
            <Profile />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
