import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import { Close, Upload } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "./../../Utils/ToastNotifications";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { updateUserProfile, uploadImage } from "../../Api/API";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setUserProfile } from "../../Redux/reducers/usersSlice";

const ProfileModal = (props) => {
  const { openProfileModal, setOpenProfileModal } = props;
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { userDetail } = useSelector((state) => state.users);
  // console.log(userDetail);

  const [userData, setUserData] = useState({
    image: "",
    first_name: "",
    email: "",
    address: "",
    city: "",
    postal_code: "",
  });

  const handleClose = (e) => {
    e.preventDefault();
    setOpenProfileModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData?.first_name === "") {
      ToastNotification("error", "User name is required");
    } else {
      updateUserProfile(userDetail?.user?.id, userData)
        .then((response) => {
          if (response.success) {
            setOpenProfileModal(false);
            ToastNotification("success", "Profile updated");
            dispatch(setUserProfile(response.data));
            const data = JSON.parse(localStorage.getItem("token"));
            const updatedData = { ...data, user: response.data };
            localStorage.setItem("token", JSON.stringify(updatedData));
          }
        })
        .catch((err) => {
          console.error("Error sending email:", err);
          ToastNotification("error", "Error: Sending feedback");
        });
    }
  };

  const handleFileChange = (event) => {
    console.log("event", event.target);
    const selectedFile = event.target?.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

    // setFile(selectedFile);
    if (selectedFile) {
      // Check if the selected file type is allowed (PNG or JPEG)
      if (
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/jpg"
      ) {
        const fileSize = selectedFile?.size;
        if (fileSize > maxSizeInBytes) {
          ToastNotification("error", "Error: Maximum image size is 5MB");
        } else {
          // You can upload the file to S3 here

          uploadFile(selectedFile);
          // console.log('Selected file:', selectedFile);
        }
      } else {
        // Display an error message or take appropriate action
        console.error(
          "Invalid file type. Error: Only PNG or JPEG photos are allowed"
        );
        ToastNotification(
          "error",
          "Error: Only PNG or JPEG photos are allowed"
        );
      }
    }
  };

  const uploadFile = (selectedFile) => {
    const reader = new FileReader();
    let imageUrl = "";
    if (userData.image) {
      const parts = userData?.image?.split("/");
      imageUrl = parts[parts?.length - 1];
    }

    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];

      let body = {
        selectedFile: base64Data,
        imageUrl: imageUrl,
        previousImageExist: userData?.image ? true : false,
        name: selectedFile?.name,
      };

      uploadImage(body).then((res) => {
        // console.log(res, "image res");
        if (res.data) {
          setUserData({
            ...userData,
            image: res.data.picture,
          });
        }
      });
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (openProfileModal) {
      const { user } = userDetail;
      setUserData({
        image: user?.image,
        first_name: user?.first_name,
        email: user?.email,
        address: user?.address,
        city: user?.city,
        postal_code: user?.postal_code,
      });
    }
  }, [openProfileModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <Dialog
        open={openProfileModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.profileContainer}>
          {/* {loader ? (
            <Loader />
          ) : ( */}
          <>
            <div className={styles.headingContainer}>
              <h1 className={styles.heading}>Update Profile</h1>
              <Close onClick={handleClose} />
            </div>
            <div className={styles.imageContainer}>
              <img
                src={
                  userData?.image
                    ? userData.image
                    : "https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"
                }
                alt="user proile"
              />
              <div>
                <Button
                  height="45px"
                  bgColor="#fff"
                  color="#349879"
                  radius="100px"
                  borderColor="#349879"
                  icon={<Upload />}
                  text="Upload Photo"
                  handleClick={handleButtonClick}
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <p>At least 256 x 256px JPG or PNG file.</p>
              </div>
            </div>

            <Grid container spacing={3}>
              <Grid item xs={24} sm={24}>
                <TextField
                  autoComplete="given-name"
                  label="Name"
                  name="first_name"
                  id="name"
                  required
                  fullWidth
                  value={userData?.first_name}
                  onChange={handleInput}
                  size="medium"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={24} sm={24}>
                <TextField
                  label="Email Address"
                  name="email"
                  id="email"
                  required
                  fullWidth
                  value={userData?.email}
                  disabled={true}
                  size="medium"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={24}>
                <TextField
                  label="Address"
                  name="address"
                  id="address"
                  fullWidth
                  required
                  value={userData?.address}
                  onChange={handleInput}
                  size="medium"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  id="city"
                  fullWidth
                  required
                  value={userData?.city}
                  onChange={handleInput}
                  size="medium"
                  sx={{
                    height: "56px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="zipCode"
                  label="Postal Code"
                  name="postal_code"
                  value={userData?.postal_code}
                  onChange={handleInput}
                  size="medium"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <div className={styles.buttonContainer}>
              <Button
                height="48px"
                width="117px"
                bgColor="#F3F4F6"
                color="#1E232E"
                radius="100px"
                borderColor="#F3F4F6"
                text="Cancel"
                handleClick={handleClose}
              />
              <Button
                height="48px"
                width="174px"
                bgColor="#1F443A"
                color="#fff"
                radius="100px"
                borderColor="#1F443A"
                text="Save Changes"
                handleClick={handleSubmit}
              />
            </div>
          </>
          {/* )} */}
        </div>
      </Dialog>
    </div>
  );
};
export default ProfileModal;
