import React from 'react'
import Button from "../../Common/Button/Button";
import styles from './styles.module.scss';

const Rating = (props) => {
    const { rating, setRating } = props;
    const buttonArray = ['1 (Poor) 😞', '2 (Below Average)  😕', '3 (Average)  😐', '4 (Above Average)  😊', '5 (Excellent)  😃']

    return (
        <div className={styles.recommendation}>
            <p className={styles.para1}>
                On a scale of 1 to 5, how would you rate the platform's performance?
            </p>
            <div className={styles.recommendationButtons}>
                {buttonArray?.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            height='45px'
                            bgColor={rating === item ? '#1F443A' : '#fff'}
                            color={rating === item ? '#fff' : '#1F443A'}
                            radius='12px'
                            borderColor={rating === item ? '#1F443A' : '#DCDFE5'}
                            text={item}
                            handleClick={() => setRating(item)}
                        />
                    )
                })}
            </div>

        </div>
    )
}

export default Rating
