import { createSlice } from "@reduxjs/toolkit";

const gptOsmDataSlice = createSlice({
    name: "gptOsmData",
    initialState: {
        prompt: null,
        osmData: [],
        isDrawing: false
    },
    reducers: {
        setGptPrompt: (state, action) => {
            state.prompt = action.payload;
        },
        setOsmData: (state, action) => {
            state.osmData = action.payload
        },
        setToggleShowLayer: (state, action) => {
            const index = action.payload;
            if (state.osmData[index]) {
                state.osmData[index].show = !state.osmData[index].show;
            }
        },
        setRemoveOsmDataItem: (state, action) => {
            const index = action.payload;
            state.osmData.splice(index, 1);
        },
        setIsDrawing: (state, action) => {
            state.isDrawing = action.payload;
        },
    },
});

export const { setGptPrompt, setOsmData, setToggleShowLayer, setRemoveOsmDataItem,setIsDrawing } = gptOsmDataSlice.actions;
export default gptOsmDataSlice.reducer;