import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { LightTooltip } from "./Stamford";
import { removeBrackets } from "./utils";
import { LocationPlanningIcon } from "../../SVG";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
 
export const PlanningCard = (props) => {
  const planningData = useSelector((state) => state.substations.planningData);
  const assetType = useSelector((state) => state.filters.assetType);
  const toolTipContent = assetType === "EV Infrastructure" ? 
    "Location Planning analyses the nearest EV Infrastructure to a substation to assess their connection outcomes. This provides insights into the likely results for similar projects attempting to connect to the same substation."
   :"Location Planning analyses the ten nearest projects to a substation to assess their connection outcomes. This provides insights into the likely results for similar projects attempting to connect to the same substation."
  return (
    <LightTooltip
      title={toolTipContent}
      placement="left-start"
      arrow
      margin="20px"
    >
      <div className={styles.planningCard}>
        <div style={{ display: "flex" }}>
          <LocationPlanningIcon />
          <p className={styles.planningCard__para}>{props.text}</p>
        </div>
        <div className={styles.planning}>
      
            {Object.entries(planningData).map(
              ([status, count], index) => (
                <div  style={{ display: "flex" }}>
                  <div style={{width: '150px'}} >
                    <p key={status} style={{ marginTop: '4px'}}>{removeBrackets(status)}</p>
                  </div>
                  <div className={styles.progressSection}>
                    <div className={styles.taskProgress} key={index}>
                      <progress
                        className={`${styles.progress} ${
                          styles[`progress${index + 1}`]
                        }`}
                        max="10"
                        value={count}
                      ></progress>

                      <p> {count}</p>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </LightTooltip>
  );
};
