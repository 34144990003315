import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { setPlanningData } from "../../Components/Redux/reducers/substationSlice";
import { fixed } from "../../Components/Utils/Formats";
import { PlanningCard } from "../../Components/Sidebar/RightSidebar/PlanningCard";
import Button from "../../Components/Common/Button/Button";
import {
  BackArrow,
  BlueDot,
  DeleteIcon,
  Download,
  EditIcon,
  ElectricIcon,
} from "../../Components/SVG";
import MapCard from "../../Components/MapCard/index";
import { useNavigate, useParams } from "react-router-dom";
import EditProjectModal from "../../Components/Modals/EditProjectModal";
import {
  deleteProject,
  getProjectDetail,
  getProjectStatuses,
  updateProjectStatus,
  userRemovedSubstation,
} from "../../Components/Api/API";
import { setProjectsLoading } from "../../Components/Redux/reducers/projectsSlice";
import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import Loader from "../../Components/Common/Loader/Loader";
import DeleteProjectModal from "../../Components/Modals/DeleteProjectModal";
import { TitleDescription } from "../../Components/Common/TitleDescription";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import getCurrentSeasonUK from "../../Components/Utils/FindSeason";

const ProjectDetail = () => {
  const { id } = useParams(); // Access the dynamic parameter from the URL
  const dispatch = useDispatch();
  // const handleOpen = () => setOpen(true);
  // const filters = useSelector((state) => state.filters);
  const { projectsLoading } = useSelector((state) => state.projects);
  const [radiusUnit, setRadiusUnit] = useState("");
  const [distance, setDistance] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [projectStatus, setProjectStatus] = useState({});
  // const [minRadius, setMinRadius] = useState("");
  // const [maxRadius, setMaxRadius] = useState("");
  const [mapLoad, setmapLoad] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [finalRadius, setFinalRadius] = useState("");
  const [statuses, setStatuses] = useState([]);

  const [statusMenu, setStatusMenu] = useState(null);
  const open = Boolean(statusMenu);
  const handleClick = (event) => {
    setStatusMenu(event.currentTarget);
  };
  const handleClose = () => {
    setStatusMenu(null);
  };
  const handleUpdateStatue = (item) => {
    const body = {
      id: selectedProject.id,
      statusId: item?.id,
    };

    updateProjectStatus(body)
      .then((res) => {
        if (res.success) {
          setProjectStatus(item);
          ToastNotification("success", "Status updated");
          setStatusMenu(null);
          // fetchProjectDetail();
        } else {
        }
      })
      .catch((err) => {
        ToastNotification("error", "Error: Updating status");
      });
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  function convertDateFormat(dateString) {
    const dateParts = dateString?.split("-"); // split the date into [YYYY, MM, DD]
    return `${dateParts?.[2]}/${dateParts?.[1]}/${dateParts?.[0]}`; // rearrange to MM/DD/YYYY
  }

  const fetchProjectDetail = () => {
    dispatch(setProjectsLoading(true));
    getProjectDetail(id)
      .then((res) => {
        if (res.code === 400) {
          ToastNotification("error", res.message);
          handleBackClick();
        }
        if (res.success) {
          setSelectedProject(res?.data);
          setProjectStatus(res?.data?.ProjectStatus);
          dispatch(setPlanningData(res?.data?.planningData));
          setDistance(res?.data?.selectedSubstation?.distance);
          setRadiusUnit(res?.data?.radiusUnit);
          // setMinRadius(res?.data?.minRadius);
          // setMaxRadius(res?.data?.maxRadius);
          setFinalRadius(
            `${res?.data?.minRadius} - ${res?.data?.maxRadius} ${res?.data?.radiusUnit}`
          );
          dispatch(setProjectsLoading(false));
          setTimeout(() => {
            setmapLoad(false);
          }, 2000);
        }
      })
      .catch((err) => {
        if (err) {
          ToastNotification("error", "error: Project not found");
          handleBackClick();
        }
      });
  };

  const handleDeleteProject = () => {
    deleteProject(selectedProject.id).then((res) => {
      if (res.success) {
        ToastNotification("success", res.message);
        let body = {
          substationId: selectedProject?.selectedSubstation?.SubstationID,
        };
        userRemovedSubstation(body).then((res) => {
          console.log("User saved substation");
        });
        setOpenDeleteModal(false);
        handleBackClick();
      }
    });
  };

  const fetchProjectStatus = () => {
    getProjectStatuses()
      .then((res) => {
        if (res.code === 400) {
          ToastNotification("error", "Error: Getting status");
        }
        if (res.success) {
          const responseData = res?.data?.premadeStatuses;
          setStatuses(responseData);
          // handleProjectStatusClick(responseData);
        }
      })
      .catch((err) => {
        if (err) {
          ToastNotification("error", "Error: Getting status");
        }
      });
  };

  useEffect(() => {
    fetchProjectDetail();
    fetchProjectStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadPdf = async () => {
    setDownloading(true);
  };

  // const formattedDate = formatDateString(inputDate);
  // useEffect(() => {
  //   if (selectedProject) {
  //     dispatch(setPlanningData(selectedProject?.planningData));
  //     setDistance(selectedProject?.selectedSubstation?.distance);
  //     setRadiusUnit(selectedProject?.radiusUnit);
  //     setMinRadius(selectedProject?.minRadius);
  //     setMaxRadius(selectedProject?.maxRadius);
  //     setFinalRadius(
  //       `${selectedProject?.minRadius} - ${selectedProject?.maxRadius} ${selectedProject?.radiusUnit}`
  //     );
  //   } else {
  //     fetchProjectDetail();
  //   }
  // }, [selectedProject]); // eslint-disable-line react-hooks/exhaustive-deps

  // const updateRadius = (event) => {
  //   const selectedValue = event.target.value;
  //   setRadiusUnit(selectedValue);
  //   // Define the new min and max values based on the selected unit
  //   const newValue =
  //     selectedValue === "Kilometers" ? distance * 1.60934 : distance / 1.60934;
  //   const newMinRadius =
  //     selectedValue === "Kilometers"
  //       ? minRadius * 1.60934
  //       : minRadius / 1.60934;
  //   const newMaxRadius =
  //     selectedValue === "Kilometers"
  //       ? maxRadius * 1.60934
  //       : maxRadius / 1.60934;
  //   const newFinalRadius =
  //     selectedValue === "Kilometers"
  //       ? `${newMinRadius} - ${newMaxRadius} Kilometers`
  //       : `${newMinRadius} - ${newMaxRadius} Miles`;

  //   setDistance(newValue);
  //   setMinRadius(newMinRadius);
  //   setMaxRadius(newMaxRadius);
  //   setFinalRadius(newFinalRadius);
  // };

  // Fetch data for the project using the project id
  return (
    <>
      {projectsLoading ? (
        <Loader />
      ) : (
        <div className={styles.main_container}>
          <div>
            <div className={styles.heading_container}>
              <div className={styles.back_button_container}>
                <Button
                  text="Back"
                  icon={<BackArrow />}
                  height="40px"
                  radius="12px"
                  bgColor="#fff"
                  color="#1E232E"
                  borderColor="#1e232e33"
                  handleClick={() => handleBackClick()}
                  //   width="140px"
                />
                <Button
                  icon={<BlueDot fill={projectStatus?.typeColor} />}
                  height="40px"
                  radius="99px"
                  bgColor={projectStatus?.bgColor}
                  color="#fff"
                  borderColor={projectStatus?.bgColor}
                  text={projectStatus?.name}
                  // handleClick={() => setOpenUpdateModal(true)}
                  handleClick={handleClick}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={statusMenu}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <h1>Project Status</h1> */}
                  {statuses?.map((item, index) => {
                    return (
                      item.name !== projectStatus?.name && (
                        <MenuItem
                          key={index}
                          onClick={() => handleUpdateStatue(item)}
                        >
                          <div
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "100px",
                              background: item.bgColor,
                              marginRight: "10px",
                            }}
                          />
                          {item.name}
                        </MenuItem>
                      )
                    );
                  })}
                </Menu>
              </div>
              <div className={styles.share_project_container}>
                <Button
                  text="Download PDF"
                  icon={
                    downloading === true ? (
                      <CircularProgress
                        size={20}
                        thickness={4}
                        color="inherit"
                      />
                    ) : (
                      <Download />
                    )
                  }
                  bgColor="#065F42"
                  color="#fff"
                  radius="99px"
                  height="42px"
                  handleClick={downloadPdf}
                />
                <Button
                  text="Edit Project"
                  icon={<EditIcon fill="#065F42" />}
                  borderColor="#065F42"
                  color="#065F42"
                  bgColor="#fff"
                  radius="99px"
                  height="42px"
                  handleClick={() => setOpenEditModal(true)}
                />
                <Button
                  text="Delete Project"
                  icon={<DeleteIcon />}
                  borderColor="#EA374A"
                  color="#EA374A"
                  bgColor="#fff"
                  radius="99px"
                  height="42px"
                  handleClick={() => setOpenDeleteModal(true)}
                />
              </div>
            </div>
            <div className={styles.substation_name_container}>
              <p>Last edited on: {convertDateFormat(selectedProject?.date)} </p>
              <h1>{selectedProject?.projectName}</h1>
              <h2>{selectedProject?.comment}</h2>
            </div>
          </div>

          <div className={styles.substation_info}>
            <div className={styles.substation_info_container}>
              <h1>Substation Information</h1>
              <div className={styles.substation_info_div}>
                <TitleDescription
                  title="Substation"
                  des={selectedProject?.selectedSubstation?.Name}
                />

                <TitleDescription
                  title="Status"
                  des={
                    selectedProject?.selectedSubstation?.[getCurrentSeasonUK()]
                  }
                />

                <TitleDescription
                  title="Coordinates"
                  des={`${fixed(
                    selectedProject?.selectedSubstation?.Lattitude,
                    4
                  )} , ${fixed(
                    selectedProject?.selectedSubstation?.Longitute,
                    4
                  )}`}
                />

                <TitleDescription title="DNO" des={selectedProject?.dno} />
              </div>
            </div>

            <div className={styles.site_voltage_container}>
              <div className={styles.volt_btn_div}>
                <Button icon={<ElectricIcon />} bgColor="#fff" />
              </div>
              <div className={styles.volt__inner_div}>
                <p>Site Voltages</p>
                <h1>{selectedProject?.voltageLine}KV</h1>
              </div>
            </div>

            <div className={styles.demand_headroom_container}>
              <div>
                <Button icon={<ElectricIcon />} bgColor="#fff" />
              </div>
              <div className={styles.demand_inner_div}>
                <p>Demand Headroom</p>
                <h1>{fixed(selectedProject?.demandHeadroom, 2)} MW</h1>
              </div>
            </div>

            <div className={styles.demand_headroom_container}>
              <div>
                <Button icon={<ElectricIcon />} bgColor="#fff" />
              </div>
              <div className={styles.demand_inner_div}>
                <p>Generation Headroom</p>
                <h1>{fixed(selectedProject?.generationHeadroom, 2)} MW</h1>
              </div>
            </div>
          </div>

          <div className={styles.last_main_container}>
            <div className={styles.score_container}>
              <div className={styles.score_card_div}>
                <h1>IQ Score</h1>
                <div className={styles.progress_div}>
                  <progress
                    id="file"
                    value={selectedProject?.iqScore}
                    max="100"
                  >
                    {" "}
                    {selectedProject?.iqScore}
                  </progress>
                  <label className={styles.label} for="file">
                    {fixed(selectedProject?.iqScore, 1)}%
                  </label>
                </div>
              </div>
              <div className={styles.score_card_div}>
                <h1>Risk Score</h1>
                <div
                  className={
                    selectedProject?.riskScore > 50
                      ? styles.danger_progress_bar
                      : styles.progress_div
                  }
                >
                  <progress
                    id="file"
                    value={selectedProject?.riskScore}
                    max="100"
                  >
                    {selectedProject?.riskScore}
                  </progress>

                  <label className={styles.label} for="file">
                    {fixed(selectedProject?.riskScore, 1)}%
                  </label>
                </div>
              </div>
              <div className={styles.planning__section}>
                {selectedProject?.planningData !== null &&
                  typeof selectedProject?.planningData !== "undefined" &&
                  Object.keys(selectedProject?.planningData).length > 0 && (
                    <PlanningCard text="Location Planning" />
                  )}
              </div>
            </div>

            <div className={styles.map__container}>
              {!mapLoad && (
                <MapCard
                  setDownloading={setDownloading}
                  downloading={downloading}
                  project={selectedProject}
                  distance={distance}
                  radiusUnit={radiusUnit}
                  height="610px"
                  width="38%"
                  borderRadius="16px"
                />
              )}
            </div>

            <div className={styles.search_criteria_container}>
              <div>
                <h1>Search Criteria</h1>
              </div>

              <div className={styles.search_info_div}>
                <TitleDescription
                  title="Location"
                  des={selectedProject?.searchInput}
                />
                <TitleDescription title="Radius" des={finalRadius} />
                <TitleDescription
                  title="Asset Type"
                  des={selectedProject?.assetType}
                />
                <TitleDescription
                  title="Project Capacity"
                  des={
                    selectedProject?.capacityUnit === "kW"
                      ? parseFloat(selectedProject?.minCapacity) * 1000 +
                        "kW+ Capacity"
                      : selectedProject?.minCapacity + "MW+ Capacity"
                  }
                />
                <TitleDescription
                  title="Quick Selectors"
                  des={
                    selectedProject?.demand && selectedProject?.generation
                      ? "Demand - Generation"
                      : selectedProject?.demand
                      ? "Demand"
                      : selectedProject?.generation
                      ? "Generation"
                      : ""
                  }
                />
                <TitleDescription
                  title="Filters"
                  des={
                    selectedProject?.riskAndIQ?.[0] +
                    "-" +
                    selectedProject?.riskAndIQ?.[1]
                  }
                />
                <TitleDescription
                  title="Date Created"
                  des={convertDateFormat(selectedProject?.date)}
                />
              </div>
            </div>
          </div>
          {/* {selectedProject && (
            <PDFViewer width="100%" height="600">
              <MyPDFDocument project={selectedProject} />
            </PDFViewer>
          )} */}

          <EditProjectModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            projectData={selectedProject}
            getAllProjects={fetchProjectDetail}
          />
          <DeleteProjectModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            handleDetele={handleDeleteProject}
          />
        </div>
      )}
    </>
  );
};

export default ProjectDetail;
