import React, { useRef } from 'react';
// import Papa from 'papaparse';
import styles from './styles.module.scss';
import { ArrowForward, CsvFileIcon, FileUploadIcon, Trash } from '../../Components/SVG';
import Button from '../../Components/Common/Button/Button';
import { ToastNotification } from '../../Components/Utils/ToastNotifications';
import { uploadProjectCsv } from '../../Components/Api/API';
import Loader from '../../Components/Common/Loader/Loader';
import Gleap from 'gleap';

const UploadFile = (props) => {
    const {
        csvData,
        setCsvData,
        fileName,
        setFileName,
        loading,
        setLoading,
        setShowAnalysis
    } = props;
    const inputFileRef = useRef(null);



    const submitBulkProject = async () => {
        setLoading(true)
        let body = {
            csvData: csvData
        };

        uploadProjectCsv(body)
            .then((response) => {
                if (response.success) {
                    setLoading(false)
                    setShowAnalysis(true)
                    ToastNotification("success", response.message);
                    setCsvData(response?.data)
                }
            })
            .catch((err) => {
                console.error("Error sending email:", err);
                ToastNotification("error", "Error: Sending feedback");
            });
    };

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const content = event.target.result;
            setCsvData(content);
            // Papa.parse(text, {
            //     header: true,
            //     complete: (results) => {
            //         console.log(results);
            //         setCsvData(results.data);
            //     },
            // });
        };
        reader.readAsText(file);
    };

    const handleInputChange = (event) => {
        const file = event.target.files[0];

        if (file && file.type === 'text/csv') {
            setFileName(file.name)
            // setCsvData(file);
            handleFileUpload(file);
        } else {
            alert('Please upload a valid CSV file.');
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            setFileName(file.name)
            // setCsvData(file);
            handleFileUpload(file);
        } else {
            alert('Please drop a valid CSV file.');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleButtonClick = () => {
        inputFileRef.current.click();
    };

    const handleGuideLine = () => {
        Gleap.openHelpCenterArticle("19", false);
    };


    // const handleDownload = () => {
    //     console.log("link is working")
    //     const link = document.createElement('a');
    //     link.href = `${process.env.PUBLIC_URL}/exampleimport.csv`; // Replace with the actual path to your CSV file
    //     link.setAttribute('download', 'template.csv'); // Optional: specify the download file name
    //     console.log("link", link)
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    const handleDownload = () => {
        const url = 'https://voltquant-images.s3.eu-west-2.amazonaws.com/voltquant-CSV-template.csv'; // Replace with your URL
        window.location.href = url;
        // window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className={styles.recommendation}>
            {loading && <Loader />}
            <h3 className={styles.projectMapping}>Upload your files</h3>
            <p className={styles.para}>Before uploading your file, please ensure that everything is checked and correct.</p>
            <p className={styles.para} style={{ marginTop: '5px' }}>For multi-project uploads, download the template here. <span onClick={() => handleDownload()}>csv template</span></p>
            <div className={styles.inputFileContainer}>
                <div className={styles.inputFile}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <FileUploadIcon />
                    <h5>Drag & Drop or <span onClick={handleButtonClick}>choose file</span> to upload.</h5>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                    />
                </div>

                <div className={styles.inputFile1}>
                    <p>Supported format:&nbsp;&nbsp;&nbsp; CSV</p>
                    <p>Maximum size: 25MB</p>
                </div>
                {fileName?.length > 0 && (
                    <>
                        <div className={styles.showFile}>
                            <div className={styles.showFile__icon}>
                                <CsvFileIcon />
                                <p>{fileName}</p>
                            </div>
                            <Button
                                height='45px'
                                bgColor='#EDF0EF'
                                color='#EA374A'
                                radius='12px'
                                borderColor='#EDF0EF'
                                icon={<Trash />}
                                text='Remove file'
                                handleClick={() => {
                                    setCsvData(null);
                                    setFileName("");
                                }}
                            />
                        </div>
                        <div className={styles.inputFile1} style={{ marginTop: '10px' }}>
                            <p></p>
                            <Button
                                height="40px"
                                bgColor="#1F443A"
                                color="#fff"
                                radius="100px"
                                borderColor="#1F443A"
                                text="Continue"
                                icon1={<ArrowForward fill="#fff" />}
                                disable={loading}
                                handleClick={() => submitBulkProject()}
                            />
                        </div>
                    </>
                )}
                <br />
                <Button
                    height="40px"
                    bgColor="#1F443A"
                    color="#fff"
                    radius="100px"
                    borderColor="#1F443A"
                    text='Tutorial Video: How to "Bulk Import" sites for mass searching'
                    icon1={<ArrowForward fill="#fff" />}
                    handleClick={handleGuideLine}
                />
            </div>
        </div>
    )
}

export default UploadFile
