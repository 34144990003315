import React, { useState } from "react";
import comms_tower from "../../Images/power/comms_tower.svg";
const energyOptions = [
  {
    name: "Cable",
    image: 'https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/cable.PNG',
  },
  {
    name: "Tower/Mast",
    image: comms_tower,
  },

  {
    name: "Datacenter/Exchange",
    image: 'https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/datacenter.PNG',
  },

];

const TelecomLegend = () => {

  const [optionsVisible, setOptionsVisible] = useState(true);
  const handleToggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="legend-main">
      <div
        onClick={handleToggleOptions}
        className='legend-heading'
      >
        <svg
          width="10"
          height="10"
          style={{
            marginRight: "4px",
            transform: optionsVisible ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <polygon points="0,0 10,5 0,10" fill="black" />
        </svg>
        Telecoms
      </div>
      {optionsVisible && (
        <>
          {energyOptions.map((option, index) => (
            <div
              key={option.name}
              style={{
                width: "286px",
                display: "flex",
                justifyContent: "space-between",
                alignItems:'center',
                marginBottom: '3px'
              }}
            >
              <div>{option.name}</div>
              <div style={{ justifyContent: "flex", marginRight: "10px" }}>
                <img height={28} width={40} src={option.image} alt="icon-power" />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TelecomLegend;
