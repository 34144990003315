import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ isAuthenticated }) => {
  console.log(isAuthenticated,"public")
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  // If not authenticated, render the child components (public routes)
  return <Outlet />;
};

export default PublicRoute;
