import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "../../Utils/ToastNotifications";
import styles from "./styles.module.scss";
import { updateLinkExpiry } from "../../Api/API";
import TextInput from "../../Common/TextInput";
import { ConvertDateIntoISOString } from "../../Utils/Formats";

const UpdateExpiryDaysModal = (props) => {
  const { open, setOpen, selectedProject, getAllSharedWithUrlProjects, remainingDays } = props;
  const [expiryDate, setExpiryDate] = useState("");


  const handleClose = (e) => {
    setOpen(false);
    setExpiryDate("");
    e.stopPropagation();
  };

  useEffect(() => {
    setExpiryDate(selectedProject?.linkExpiry?.split('T')[0]);
  }, [open, remainingDays]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveProject = () => {
    if (expiryDate.trim() === "") {
      ToastNotification("warn", "Please select an expiry date");
      return;
    } else {
      // Convert to ISO string
      const date = ConvertDateIntoISOString(expiryDate)

      let body = {
        linkExpiry: date,
        // linkExpiry: parseInt(expiryDate),
        projectId: selectedProject?.id
      };
      updateLinkExpiry(body).then((res) => {
        if (res?.success) {
          setOpen(false);
          getAllSharedWithUrlProjects();
          setExpiryDate("");
          ToastNotification("success", "Project updated successfully");
        }
      });
    
    }
  };

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1);
  const minDateString = minDate?.toISOString()?.split('T')[0];
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const maxDateString = maxDate?.toISOString()?.split('T')[0];

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Edit Project</h1>
            <Close onClick={handleClose} />
          </div>
          <p className={styles.para}>
            {remainingDays} to expire the URL
          </p>
          <TextInput
            type="date"
            min={minDateString}
            max={maxDateString}
            value={expiryDate}
            setValue={setExpiryDate}
            placeholder="Expiry Days"
          />
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Update"
              handleClick={handleSaveProject}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default UpdateExpiryDaysModal;
