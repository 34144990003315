import React from 'react'
import styles from './styles.module.scss';

const TextArea = (props) => {
    const { value, setValue, placeholder } = props
    return (
        <textarea
            className={styles.c_input}
            rows={6}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
        />
    )
}

export default TextArea
