import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  setRenewableActiveLayersArray } from "../Redux/reducers/datasetSlice";
import { Checkbox } from "@mui/material";

const energyOptions = [
  { name: 'Advanced Conversion Technologies', layerName: 'Advanced-conversion-technologies', checked: false, color: '#b61d14' },
  { name: 'Anaerobic Digestion', layerName: 'anaerobic-digestion', checked: false, color: '#421986' },
  { name: 'Battery', layerName: 'battery', checked: false, color: '#9776cf' },
  { name: 'Biomass Cofiring', layerName: 'biomass-cofiring', checked: false, color: '#65b5f4' },
  { name: 'Biomass Dedicated', layerName: 'biomass-dedicated', checked: false, color: '#00569b' },
  { name: 'Compressed Air Energy Storage', layerName: 'compressed-air-energy-storage', checked: false, color: '#4ECEE3' },
  { name: 'EFW Incineration', layerName: 'efw-incineration', checked: false, color: '#004c3f' },
  { name: 'Flywheels', layerName: 'flywheels', checked: false, color: '#83c586' },
  { name: 'Fuel Cell Hydrogen', layerName: 'fuel-cell-hydrogen', checked: false, color: '#32691e' },
  { name: 'Geothermal', layerName: 'geothermal', checked: false, color: '#040200' },
  { name: 'Hot Dry Rocks', layerName: 'hot-dry-rocks', checked: false, color: '#d55802' },
  { name: 'Hydrogen', layerName: 'hydrogen', checked: false, color: '#070000' },
  { name: 'Landfill Gas', layerName: 'landfill-gas', checked: false, color: '#ff837f' },
  { name: 'Large Hydro', layerName: 'large-hydro', checked: false, color: '#3e2425' },
  { name: 'Liquid Air Energy Storage', layerName: 'liquid-air-energy-storage', checked: false, color: '#636700' },
  { name: 'Pumped Storage Hydroelectricity', layerName: 'pumped-storage-hydroelectricity', checked: false, color: '#04fed1' },
  { name: 'Sewage Sludge Digestion', layerName: 'sewage-sludge-digestion', checked: false, color: '#281ef8' },
  { name: 'Shoreline Wave', layerName: 'shoreline-wave', checked: false, color: '#e502f9' },
  { name: 'Small Hydro', layerName: 'small-hydro', checked: false, color: '#7f0e69' },
  { name: 'Solar Photovoltaics', layerName: 'solar-photovoltaics', checked: false, color: '#f5c25d' },
  { name: 'Tidal Lagoon', layerName: 'tidal-lagoon', checked: false, color: '#070000' },
  { name: 'Tidal Stream', layerName: 'tidal-stream', checked: false, color: '#070000' },
  { name: 'Wind Offshore', layerName: 'wind-offshore', checked: false, color: '#6670d2' },
  { name: 'Wind Onshore', layerName: 'wind-onshore', checked: false, color: '#456783' },
];

  const EnergyType = () => {
  const [options, setOptions] = useState(energyOptions);
  const [optionsVisible, setOptionsVisible] = useState(true); 
  const dispatch = useDispatch();

  const handleCheckboxChange = (index) => {
    const newOptions = [...options];
    newOptions[index].checked = !newOptions[index].checked;
    setOptions(newOptions);
  };
  
  const handleSelectAllCheckboxChange = (isChecked) => {
    // const newOptions = options.map((option) => ({ ...option, checked: isChecked }));
    const newOptions = [...options];
    newOptions.forEach((option) => {
      option.checked = isChecked;
    });
    setOptions(newOptions);
  };
  
  useEffect(() => {
    const activeLayersRenewable = options.filter((option) => option.checked).map((option) => option.layerName);
    dispatch(setRenewableActiveLayersArray({ activeRenewableLayers: activeLayersRenewable }));
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="legend-main">
      {/* <div onClick={handleToggleOptions}   className='legend-heading'>
        <svg width="10" height="10" style={{marginRight: '4px', transform: optionsVisible ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease'}}>
          <polygon points="0,0 10,5 0,10" fill="black"/>
        </svg>
        Energy Type
      </div> */}
      {optionsVisible && (
        <>
          <div>
               <Checkbox
               checked={options.every((option) => option.checked)}
               onChange={(e) => handleSelectAllCheckboxChange(e.target.checked)}
                sx={{
                  color: '#1F443A',
                  '&.Mui-checked': {
                    color: '#1F443A',
                  },
              }}
              />
              <label>Select All</label>
            </div>
            { options.map((option, index) => (
          <div key={option.name} style={{width: '286px', display: 'flex', justifyContent: 'space-between',marginBottom: '2px', alignItems: 'center'}}>
          
            <div>
              <svg width="10" height="10" style={{marginRight: '4px'}}>
                <circle cx="5" cy="5" r="5" stroke="transparent" strokeWidth="0" fill={`${option.color}`} />
              </svg>
              {option.name}
            </div>
            <div style={{justifyContent: 'flex'}}>
              <Checkbox
                checked={option.checked}
                onChange={() => handleCheckboxChange(index)}
                sx={{
                  color: '#1F443A',
                  '&.Mui-checked': {
                    color: '#1F443A',
                  },
              }}
              />
            </div>
          </div>
        ))}
        </>
       
      )}
    </div>
  );
};

export default EnergyType;
