import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Close } from "@mui/icons-material";
import { Button, CardActions } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  appendNewLayer,
  appendActiveCard,
} from "../../Redux/reducers/datasetSlice";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { links } from "./layer.config";

// Create your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1F443A",
    },
    secondary: {
      main: "#YourSecondaryColor",
    },
  },
  typography: {
    fontFamily: "Eudoxus sans",
    fontSize: "5px",
  },
});

export default function MapLayerCard({ item, setOpen, id, handleRemoveLayer }) {
  const { title, imageLink, name } = item;
  const dispatch = useDispatch();
  const { activeLayers } = useSelector((state) => state.dataset);

  const handleLayer = async (name, item) => {
    setOpen();
    const layerName = links[name];
    if (layerName) {
      dispatch(appendNewLayer({ layerName: layerName }));
      dispatch(appendActiveCard(item));
    } else {
      ToastNotification("warn", "Data unavailable");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ width: 220, borderRadius: 2, margin: "0 20px 20px 0" }}
        style={{
          border: `${
            activeLayers.includes(links[name]) ? "2.5px solid green" : ""
          }`,
          position: "relative",
        }}
      >
        {id > -1 && (
          <>
            <div className={styles.indexStyle}>{id + 1}</div>
            <div
              className={styles.closeStyle}
              onClick={() => handleRemoveLayer(name)}
            >
              <Close />
            </div>
          </>
        )}
        {id === 0 && activeLayers?.length > 2 && (
          <div className={styles.layerStyle}>Bottom Layer</div>
        )}
        {id === activeLayers?.length - 1 && activeLayers?.length > 2 && (
          <div className={styles.layerStyle}>Top Layer</div>
        )}
        <CardMedia component="img" height="100" image={imageLink} alt={title} />
        <CardContent sx={{ height: 20, padding: 1 }}>
          <div className={styles.container}>
            <p style={{ fontSize: "12px", margin: "0px", height: "25px" }}>
              {title}
            </p>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="secondary"
            sx={{ height: 0, padding: 0, textTransform: "none" }}
            // href={link}
            target="_blank"
            onClick={() => handleLayer(name, item)}
          >
            {activeLayers.includes(links[name]) ? "Applied" : "Apply Filter"}
          </Button>
          <ArrowForwardIcon sx={{ marginLeft: "10px" }} />
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
