import React from "react";
import Header from "../Header";
import { Collection, ImportFile1, SharedCollection } from "../SVG";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

const ProjectWrapper = ({ children }) => {

  const location = useLocation();
  console.log('location.pathname', location.pathname)
  return (
    <div>
      <Header />
      <div className={styles.main1_container}>
        <div className={styles.icon_container}>
          <NavLink
            to="/projects"
            className={
              location.pathname === "/projects" ? styles.active : styles.icons
            }
          >
            <Collection
              fill={location.pathname === "/projects" ? "#1F443A" : "#6C6E6C"}
            />
          </NavLink>
          <NavLink
            to="/shared-projects"
            className={
              location.pathname === "/shared-projects"
                ? styles.active
                : styles.icons
            }
          >
            <SharedCollection
              fill={
                location.pathname === "/shared-projects" ? "#1F443A" : "#6C6E6C"
              }
            />
          </NavLink>
          <NavLink
            to="/bulk-import"
            className={
              location.pathname === "/bulk-import" ? styles.active : styles.icons
            }
          >
            <ImportFile1
              fill={location.pathname === "/bulk-import" ? "#1F443A" : "#6C6E6C"}
            />
          </NavLink>
          {/* <NavLink
            to="/report"
            className={
              location.pathname === "/report" ? styles.active : styles.icons
            }
          >
            <Report
              fill={location.pathname === "/report" ? "#1F443A" : "#6C6E6C"}
            />
          </NavLink> */}
        </div>
        {children}
      </div>
    </div>
  );
};

export default ProjectWrapper;
