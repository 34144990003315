import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastNotification } from "../../Utils/ToastNotifications";
import {
  setCollections,
  setMapImage,
} from "./../../Redux/reducers/projectsSlice";
import styles from "./styles.module.scss";
import {
  saveProject,
  fetchAllCollection,
  userSavedSubstation,
  uploadChatImage,
} from "../../Api/API";
import TextInput from "../../Common/TextInput";
import TextArea from "../../Common/TextArea";
import CustomSelect from "../../Common/CustomSelect";
import Checkbox from "@mui/material/Checkbox";
import { setSaveSelectedLayers } from "../../Redux/reducers/filterSlice";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../Api/instance";

const SaveProjectModal = (props) => {
  const { ProjectData, open, setOpen } = props;
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [comments, setComments] = useState("");
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (option) => {
    setSelectedOption(option);
    console.log(option); // Or handle the selected option
  };

  const dispatch = useDispatch();
  const { collections, mapImage } = useSelector((state) => state.projects);
  const activeLayers = useSelector((state) => state.dataset.activeLayers);

  const handleClose = (e) => {
    setOpen(false);
    setName("");
    setComments("");
    setUrl("");
    setLoading(true);
    dispatch(setMapImage(null));
    e.stopPropagation();
  };

  const currentDate = new Date();
  const projectDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const filters = useSelector((state) => state.filters);
  const substation = useSelector((state) => state.substations);
  const selectedSubstation = useSelector(
    (state) => state.selectedSubstation.substation
  );

  const { userLocation, substations, planningData } = substation;
  const projectadata = {
    projectName: name,
    mapImage: url,
    collectionId: selectedOption?.value,
    searchInput:
      filters?.search_by === "Draw Polygon"
        ? `${userLocation?.lat?.toFixed(4)} , ${userLocation?.lng.toFixed(4)}`
        : filters?.searchValue,
    maxRadius: filters?.maxRadius,
    minRadius: filters?.minRadius,
    radiusUnit: filters?.radiusUnit,
    assetType: filters?.assetType,
    assetTypeUrl: filters?.assetTypeUrl,
    maxCapacity: filters?.maxCapacity,
    minCapacity: filters?.minCapacity,
    capacityUnit: filters?.capacityUnit,
    demand: filters?.demand,
    generation: filters?.generation,
    riskAndIQ: filters?.riskAndIQ,
    substation: ProjectData?.substationName,
    longitude: ProjectData?.longitude,
    latitude: ProjectData?.latitude,
    demandHeadroom: ProjectData?.demand,
    generationHeadroom: ProjectData?.generation,
    riskScore: ProjectData?.riskScore,
    iqScore: ProjectData?.iqScore,
    comment: comments,
    date: projectDate,
    dno: ProjectData?.dno,
    voltageLine: ProjectData?.voltageLine,
    substations: substations,
    userLocation: userLocation,
    selectedSubstation: selectedSubstation,
    planningData: planningData,
    drawnPolygon: filters.drawnPolygon,
  };
  if (filters?.drawnLine !== null) {
    projectadata.drawnLine = filters.drawnLine;
  }
  if (filters?.selectedLayers) {
    projectadata.selectedLayers = activeLayers;
  }
  useEffect(() => {
    if (collections?.length > 0) {
      const collectionOption = collections?.map((item, index) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      setOptions(collectionOption);
      setSelectedOption(
        collectionOption && collectionOption.length > 0
          ? collectionOption[0]
          : null
      );
    }
  }, [collections]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      getAllCollection();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllCollection = () => {
    fetchAllCollection().then((res) => {
      console.log("res", res);
      if (res?.success) {
        if (res?.data?.length > 0) {
          dispatch(setCollections(res?.data));
        }
      }
    });
  };

  const handleSaveProject = () => {
    if (name === "") {
      ToastNotification("warn", "Name is required");
      return;
    }
    if (comments === "") {
      ToastNotification("warn", "Description is required");
      return;
    } else {
      const base64Data = mapImage?.split(",")[1];

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });

      // Create FormData and append the Blob and name
      const formData = new FormData();
      formData.append("selectedFile", blob, `${name}.png`);
      formData.append("name", `${name}.png`);
      const token = localStorage.getItem("token");
      const userData = JSON.parse(token);
      axios
        .post(`${baseURL}/image-upload/chat`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData?.token}`,
          },
        })
        .then((res) => {
          if (res?.data.success) {
            setUrl(res?.data?.data?.picture);
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };

  useEffect(() => {
    if (url?.length > 0) {
      saveProject(projectadata).then((res) => {
        if (res?.success) {
          setOpen(false);
          setName("");
          setComments("");
          setUrl("");
          setLoading(true);
          dispatch(setMapImage(null));
          ToastNotification("success", "Project saved");
          let body = {
            substationId: selectedSubstation?.SubstationID,
          };
          userSavedSubstation(body).then((res) => {
            console.log("User saved substation");
          });
        }
      });
    }
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapImage?.length > 0) {
      setLoading(false);
    }
  }, [mapImage]);

  const onChangeSelectedLayer = (event) => {
    dispatch(setSaveSelectedLayers(event.target.checked));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Save Project</h1>
            {loading && (
              <>
                <p className={styles.para}>
                  Replicating environment into a project...
                </p>
                <CircularProgress color="success" size={15} />
              </>
            )}
            <Close onClick={handleClose} />
          </div>
          <p className={styles.para}>
            Save this project to preserve your progress and access it anytime.
          </p>
          <TextInput
            type="text"
            value={name}
            setValue={setName}
            placeholder="Project Title"
          />
          {collections?.length > 0 && (
            <>
              <CustomSelect
                options={options}
                onChange={handleChange}
                value={selectedOption}
                placeholder="Select a Collection"
              />
              <br />
            </>
          )}
          {activeLayers.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontFamily: "Eudoxus Sans",
                borderRadius: "12px",
                border: "1px solid #C5CAD4",
                alignItems: "center",
                paddingLeft: "10px",
                marginBottom: "20px",
              }}
            >
              <div>Save Selected Layers</div>
              <Checkbox
                checked={filters.selectedLayers}
                onChange={onChangeSelectedLayer}
                sx={{
                  color: "#1F443A",
                  "&.Mui-checked": {
                    color: "#1F443A",
                  },
                }}
              />
            </div>
          )}
          <TextArea
            value={comments}
            setValue={setComments}
            placeholder="Description"
          />
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor={mapImage?.length > 0 ? "#1F443A" : "#F3F4F6"}
              color={mapImage?.length > 0 ? "#fff" : "#1E232E"}
              radius="100px"
              borderColor={mapImage?.length > 0 ? "#1F443A" : "#F3F4F6"}
              text="Save Project"
              handleClick={handleSaveProject}
              disable={mapImage?.length > 0 ? false : true}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default SaveProjectModal;
