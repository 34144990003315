import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Logo from "./../../../Images/Logo1.svg";
import { NavLink } from "react-router-dom";
import CodeConfirmationModal from "../../../Components/Modals/CodeConfirmationModal";
import { ToastNotification } from "../../../Components/Utils/ToastNotifications";
import LoginRightSide from "./LoginRightSide";
import styles from "./styles.module.scss";
import Footer from "./Footer";
import { baseURL } from "../../../Components/Api/instance";

const SignupForm = () => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    job_title: "",
    password: "",
  };
  const freeEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    // Add other domains as necessary
  ];

  const emailNotFromFreeDomain = (email) => {
    // Extract domain from email
    const domain = email.split('@')[1];
    // Check if the domain is in the list of free email domains
    return !freeEmailDomains.includes(domain.toLowerCase());
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is Required*"),
    last_name: Yup.string().required("Last Name is Required*"),
    company: Yup.string().required("Company Name is Required*"),
    job_title: Yup.string().required("Job Title is Required*"),
    email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required*")
    .test(
      'is-work-email',
      'Please use a work email address',
      value => value ? emailNotFromFreeDomain(value) : false
    ),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is Required*")
    //   .test(
    //     "is-business-email",
    //     "Gmail accounts are not allowed. Please use a business email address.",
    //     value => {
    //       // Extract the domain from the email address
    //       const domain = value.split('@')[1];
    //       // Check if the domain is not gmail.com
    //       return domain && domain.toLowerCase() !== 'gmail.com';
    //     }
    //   ),
    // password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is Required*'),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is Required*")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,99}$/,
        "Password must contain at least one special character, one uppercase letter, one lowercase letter, and one number"
      ),
  });

  const createUser = (values) => {
    console.log("values", values);

    axios
      .post(`${baseURL}/user`, values)
      .then((response) => {
        ToastNotification("success", response?.data?.message);
        console.log("response", response);
        setOpenModal(true);
      })
      .catch((e) => {
        ToastNotification("error", e?.response?.data?.message);
        console.log("error", e);
      });
  };

  return (
    <div className={styles.signup}>
      <div className={styles.signup__container}>
        <img src={Logo} alt="logo vector" />

        <h1 className={styles.signup__container__heading}>Create an account</h1>
        <p className={styles.signup__container__para}>
          Already have an account? &nbsp;
          <NavLink to="/login">Sign In</NavLink>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={createUser}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
            } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '49%' }}>
                    <input
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                      placeholder="First Name"
                      value={values.first_name}
                      onBlur={handleBlur}
                      error={errors.first_name}
                      style={{
                        borderColor:
                          errors.first_name && touched.first_name
                            ? "red"
                            : "#C5CAD4",
                      }}
                    />
                    {errors.first_name && touched.first_name && (
                      <div className={styles.formikError}>{errors.first_name}</div>
                    )}
                  </div>
                  <div style={{ width: '49%' }}>
                    <input
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      value={values.last_name}
                      onBlur={handleBlur}
                      error={errors.last_name}
                      style={{
                        borderColor:
                          errors.last_name && touched.last_name ? "red" : "#C5CAD4",
                      }}
                    />
                    {errors.last_name && touched.last_name && (
                      <div className={styles.formikError}>{errors.last_name}</div>
                    )}
                  </div>
                </div>

                <input
                  type="email"
                  name="email"
                  onChange={(e) => {
                    handleChange(e);
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  value={values.email}
                  onBlur={handleBlur}
                  error={errors.email}
                  required={false}
                  style={{
                    borderColor:
                      errors.email && touched.email ? "red" : "#C5CAD4",
                  }}
                />
                {errors.email && touched.email && (
                  <div className={styles.formikError}>{errors.email}</div>
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '49%' }}>
                    <input
                      type="text"
                      name="company"
                      onChange={handleChange}
                      placeholder="Company Name"
                      value={values.company}
                      onBlur={handleBlur}
                      error={errors.company}
                      style={{
                        borderColor:
                          errors.company && touched.company
                            ? "red"
                            : "#C5CAD4",
                      }}
                    />
                    {errors.company && touched.company && (
                      <div className={styles.formikError}>{errors.company}</div>
                    )}
                  </div>
                  <div style={{ width: '49%' }}>
                    <input
                      type="text"
                      name="job_title"
                      onChange={handleChange}
                      placeholder="Job Title"
                      value={values.job_title}
                      onBlur={handleBlur}
                      error={errors.job_title}
                      style={{
                        borderColor:
                          errors.job_title && touched.job_title ? "red" : "#C5CAD4",
                      }}
                    />
                    {errors.job_title && touched.job_title && (
                      <div className={styles.formikError}>{errors.job_title}</div>
                    )}
                  </div>
                </div>

                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  error={errors.password}
                  style={{
                    borderColor:
                      errors.password && touched.password ? "red" : "#C5CAD4",
                  }}
                />
                {errors.password && touched.password && (
                  <div className={styles.formikError}>{errors.password}</div>
                )}

                <button type="submit">Submit</button>
              </form>
            );
          }}
        </Formik>

        <Footer />
      </div>
      <LoginRightSide />
      <CodeConfirmationModal
        open={openModal}
        setOpen={setOpenModal}
        email={email}
      />
    </div>
  );
};

export default SignupForm;