import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Styles from "./styles.module.scss";
// import Logo from "./../../Images/Logo1.svg";
import { Link } from "react-router-dom";
import { Logo } from "./../SVG/index";
import { NavLink } from "react-router-dom";
const HeaderExternal = () => {
 
 
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#1F443A",
        boxShadow: "none",
        // borderBottom: "1px solid #1F443A",
        zIndex: 11,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div>
            <Link to="https://www.voltquant.com">
              <Logo fill="#fff" />
            </Link>
          </div>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box
            style={{ marginLeft: "50px", marginRight: "139px" }}
            sx={{ flexGrow: 1 }}
          >
            <div id={Styles.mapBar}>
              <div className={Styles.item}>
                <NavLink to="https://www.voltquant.com">Search</NavLink>
              </div>
              <div className={Styles.item}>
                <NavLink to="https://www.voltquant.com">Projects</NavLink>
              </div>
              <div className={Styles.item}>
                <NavLink to="https://www.voltquant.com">Collaboration</NavLink>
              </div>
            </div>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {" "}
            <div id={Styles.mapBar} style={{ gap: "12px" }}>
              {" "}
              <div className={Styles.item} >
                <NavLink to="https://app.voltquant.uk/signup" className={Styles.activeLink}>
                  Sign Up
                </NavLink>
              </div>
              <div className={Styles.item}>
                <NavLink to="https://app.voltquant.uk" className={Styles.activeLink}>
                  Login
                </NavLink>
              </div>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderExternal;
