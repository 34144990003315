import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";

import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { deleteNotification } from "../../Api/API";

const ChatDeleteNotification = (props) => {
  const { open, setOpen, id, fetchAllNotifications } = props;

  const handleDeleteNotification = () => {
    deleteNotification(id)
      .then((res) => {
        console.log(res, "res");
        if (res?.success) {
          setOpen(false);
          fetchAllNotifications()
          ToastNotification("success", res?.message);
        }
      })
      .catch((error) => {
        ToastNotification("error", "Error: Notification deletion");
      });
  };
  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "588px",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.deleteContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}> Delete Notification</h1>
            <Close onClick={() => setOpen(false)} />
          </div>

          <p className={styles.para}>
            Are you sure you want to delete this notification? This action is
            irreversible and will permanently remove all associated data.
          </p>
          <div className={styles.buttonContainer}>
            <Button
              width="117px"
              height="48px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={() => setOpen(false)}
            />
            <Button
              width="126px"
              height="48px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Confrim"
                handleClick={handleDeleteNotification}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ChatDeleteNotification;
