import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListManager } from "react-beautiful-dnd-grid";

import LayerCard from "./MapFilterCard";
import styles from "./styles.module.scss";
import { links } from "./layer.config";
import { useDispatch } from "react-redux";
import {
  setActiveLayersArray,
  setActiveCard,
  removeLayer,
  setRenewableActiveLayersArray,
} from "../../Redux/reducers/datasetSlice";
import { layers } from "./layerCard.config";

const LayersData = ({
  selectedButton,
  setOpen,
  buttonId,
  sectionRefs,
  categories,
  categorizedLayers,
  sectionNames,
  setCallUpdatedLayers,
}) => {
  const { activeLayers, activeCard } = useSelector((state) => state.dataset);
  const [activeLayersData, setActiveLayersData] = useState([]);
  const [updateRedux, setUpdateRedux] = useState([]);
  const isMounted = useRef(false);

  const dispatch = useDispatch();
  
  const roadSpeedLayers = [
    "roadspeed_indicative",
    "roadspeed_avgday",
    "roadspeed_avgnight",
  ];

  const handleRemoveLayer = async (name) => {
    let layerName = links[name];
    let matchingLayer =
      layerName === "roadspeed_indicative"
        ? roadSpeedLayers.find((layer) => activeLayers.includes(layer))
        : null;
    if (matchingLayer) {
      layerName = matchingLayer;
    }
    const newActiveCard = activeCard.filter(
      (card) => !card.name.includes(name)
    );
    if (layerName) {
      dispatch(removeLayer({ layerName: layerName }));
      dispatch(setActiveCard(newActiveCard));
      setActiveLayersData(newActiveCard);
      setCallUpdatedLayers(true);
      if(layerName==="tidal-lagoon-renewable"){
        dispatch(setRenewableActiveLayersArray({ activeRenewableLayers: [] }))
      }
    }
  };

  useEffect(() => {
    if (updateRedux?.length > 0) {
      // update activeLayers state in redux
      // it will update map layers too
      const newOrderOfIds = [];
      activeLayersData.forEach((lyr) => {
        // layer id as per geoserver
        const lyrId = links[lyr?.name];
        if (lyrId) {
          newOrderOfIds.push(lyrId);
        }
      });

      dispatch(setActiveLayersArray({ activeLayersArray: newOrderOfIds }));
      dispatch(setActiveCard(updateRedux));

      // Closing modal
      setOpen();
    }
  }, [updateRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMounted?.current) {
      //fetching those cards which have active layers
      const filteredCards = layers.filter((card) =>
        activeLayers.includes(links[card.name])
      );
      filteredCards.sort((a, b) => {
        return (
          activeLayers.indexOf(links[a.name]) -
          activeLayers.indexOf(links[b.name])
        );
      });
      if (filteredCards.length > 0) {
        dispatch(setActiveCard(filteredCards));
        setActiveLayersData(filteredCards);
      } else {
        setActiveLayersData(activeCard);
      }

      isMounted.current = true;
    }
  }, [activeCard]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollOptions = {
    behavior: "smooth",
  };

  useEffect(() => {
    if (buttonId) {
      const sectionRef = sectionRefs[buttonId];
      if (sectionRef?.current) {
        sectionRef?.current.scrollIntoView(scrollOptions);
      }
    }
  }, [buttonId]); // eslint-disable-line react-hooks/exhaustive-deps

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }

    // ERROR: the following lines are not working correctly
    const reorderedLayers = Array.from(activeLayersData);
    const [movedLayer] = reorderedLayers.splice(sourceIndex, 1);
    reorderedLayers.splice(destinationIndex, 0, movedLayer);

    // Update the state with the new order of activeLayers
    setActiveLayersData(reorderedLayers);
    setUpdateRedux(reorderedLayers);
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "620px",
          overflowY: "auto",
        }}
      >
        <div ref={sectionRefs.ACTIVE}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: "15px",
                fontWeight: "bold",
                fontFamily: "Eudoxus sans",
              }}
              style={{}}
            >
              Active Layers
            </Typography>
            <div
              style={{
                backgroundColor: "#14D92B",
                height: "28px",
                width: "28px",
                borderRadius: "8px",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{activeLayersData.length}</p>
            </div>
          </div>

          <ListManager
            items={activeLayersData}
            direction="horizontal"
            maxItems={3}
            render={(item) => {
              return (
                <LayerCard
                  item={item}
                  setOpen={setOpen}
                  id={activeLayersData.indexOf(item)}
                  handleRemoveLayer={handleRemoveLayer}
                />
              );
            }}
            onDragEnd={reorderList}
          />

          {activeLayersData?.length === 0 && (
            <div className={styles.no_active_layer}>
              <p>
                There are no layers in close proximity to your search criteria,
                in this section.
              </p>
            </div>
          )}
          <hr
            style={{
              height: "3px",
              backgroundColor: "#80808080",
              border: "3px #80808080",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></hr>
        </div>
        {categories?.map((category, index) => {
          const layerData = categorizedLayers[category];
          if (layerData?.length > 0) {
            return (
              <div key={index} ref={sectionRefs[sectionNames[category][0]]}>
                <Typography
                  id={`modal-modal-title-${category}`}
                  variant="h6"
                  component="h2"
                  sx={{ pd: "30px" }}
                  style={{ marginBottom: "8px" }}
                >
                  {sectionNames[category][1]}
                  {/* Capitalize first letter */}
                </Typography>
                <Grid container rowSpacing={3} columnSpacing={-10} mb={2}>
                  {layerData.map((item, idx) => (
                    <Grid key={idx} item xs={4}>
                      <LayerCard item={item} setOpen={setOpen} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            );
          }
          return null;
        })}

        {/* <hr
          style={{
            height: "3px",
            backgroundColor: "#80808080",
            border: "3px #80808080",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></hr>  */}
      </Box>
    </div>
  );
};

export default LayersData;
