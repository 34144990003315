import React, { useState, useEffect } from "react";
import Stamford from "./Stamford";
import Generation from "./Generation";
import RiskScore from "./RiskScore";
import Divider from "@mui/material/Divider";
import styles from "./styles.module.scss";
import { fixed } from "../../Utils/Formats";
import { useSelector } from "react-redux";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SaveProjectButton from "./SaveProjectButton";
import { setRightSideBarOpen } from "./../../Redux/reducers/projectsSlice";
import { useDispatch } from "react-redux";
import { PlanningCard } from "./PlanningCard";
import SaveProjectModal from "../../Modals/SaveProjectModal";
import { setSelectedSubstation } from "../../Redux/reducers/selectedSubstation";
import { setPlanningData } from "../../Redux/reducers/substationSlice";
import { setFilterValues } from "../../Redux/reducers/filterSlice";
import {
  fetchClosestLocalPlanningProjects,
  getLayers,
  getNearestNcpr,
  userSubstationClicked,
} from "../../Api/API";
import { ExpandCircleDownIcon, OffOutlineClose } from "../../SVG";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import { GeospatialRiskLayers } from "../../Map/style/GeospatialRiskLayers";
import { SpatialCard } from "./SpatialCard";
import { setGeospatialLayers } from "../../Redux/reducers/selectedLayers";
import getCurrentSeasonUK from "../../Utils/FindSeason";
const RightSidebar = () => {
  const dispatch = useDispatch();
  const [saveProject, setSaveProject] = useState(false);
  const substation = useSelector(
    (state) => state.selectedSubstation.substation
  );

  const projects = useSelector((state) => state.projects);
  const filters = useSelector((state) => state.filters);
  const substations = useSelector((state) => state.substations);
  const isRiskAvailable = filters.riskAndIQ.includes("Risk");
  const string = substation?.Demand;
  const number = Number(string);
  const demandValue = number.toFixed(3);
  const genrationString = substation?.Generation;
  const generationNumber = Number(genrationString);
  const generationValue = generationNumber.toFixed(3);

  useEffect(() => {
    if (substation) {
      dispatch(setRightSideBarOpen(true));
      let body = {
        substationId: substation?.SubstationID,
      };
      userSubstationClicked(body).then((res) => {
        if (res.success) {
          console.log("Substation Clicked");
        }
      });
    }
  }, [substation]); // eslint-disable-line react-hooks/exhaustive-deps
  const dataForSaveProject = {
    substationName: substation?.Name || "Substation",
    iqScore: substation ? fixed(substation?.iq_score, 2) : 0,
    latitude: substation ? fixed(substation?.Lattitude, 5) : 0,
    longitude: substation ? fixed(substation?.Longitute, 5) : 0,
    generation: substation ? substation.Generation : 0,
    demand: substation ? substation.Demand : 0,
    riskScore: isRiskAvailable ? fixed(substation?.risk_score, 5) : null,
    voltageLine: substation ? substation?.VoltageLine : null,
    dno: substations ? substation?.Source : null,
  };
  // console.log("substations:", substation);

  const findLocalPlanningClosestPoints = (substation) => {
    let body = {
      substation,
    };

    fetchClosestLocalPlanningProjects(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data?.closestProjects));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData([]));
        console.log(err);
      });
  };
  const findNearestNcpr = (substation) => {
    let body = {
      latitude: substation.Lattitude,
      longitude: substation.Longitute,
    };
    getNearestNcpr(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data));
        } else {
          dispatch(setPlanningData({}));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData({}));
        console.log(err);
      });
  };
  const handleClickMarker = (substation) => {
    if (filters.assetType === "EV Infrastructure") {
      findNearestNcpr(substation);
    } else {
      findLocalPlanningClosestPoints(substation);
    }
    dispatch(
      setFilterValues({
        source: substation?.Source,
        voltageLine: substation?.VoltageLine,
      })
    );
    dispatch(setSelectedSubstation(substation));
  };
  const PlanningCardHeading =
    filters.assetType === "EV Infrastructure"
      ? "Nearby EV Infrastructure"
      : "Substation Location Planning";
  const geoRisk = {
    NGED1006: 77.7,
    NGED1065: 86.4,
    NGED311: 83.7,
  };
  return (
    <div className={projects?.rightSidebarOpen ? styles.open : ""}>
      <div
        className={styles.right}
        style={{
          display: substations?.substations?.length > 0 ? "block" : "none",
        }}
      >
        <div className={styles.top}>
          <div className={styles.result}>
            <div className={styles.result__left}>
              {substation !== null && substation.length !== 0 && (
                <div
                  className={styles.result__left__backButton}
                  onClick={() => dispatch(setSelectedSubstation(null))}
                >
                  Back
                </div>
              )}
              <p className={styles.result__left__heading}>Insight Results</p>
              <div className={styles.result__left__badge}>
                {substations?.substations?.length}
              </div>
            </div>
            <div className={styles.result__right}>
              {!projects.rightSidebarOpen ? (
                <ExpandCircleDownIcon
                  onClick={() => dispatch(setRightSideBarOpen(true))}
                />
              ) : (
                <OffOutlineClose
                  onClick={() => {
                    dispatch(setSelectedSubstation(null));
                    dispatch(setRightSideBarOpen(false));
                    dispatch(setGeospatialLayers([]));
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ paddingRight: "10px" }}>
          <Divider />
        </div>

        <div style={{ paddingRight: "10px" }}>
          {substation !== null && substation.length !== 0 ? (
            <div>
              <Stamford
                name={substation ? substation?.Name : "Substation"}
                percentage={substation ? fixed(substation?.iq_score, 2) : 0}
                voltageLine={substation?.VoltageLine}
                source={substation?.Source}
                price="2,409"
                constrainedType={
                  substation?.ConstrainedType ||
                  substation?.[getCurrentSeasonUK()]
                }
              />

              <div className={styles.right__divider}>
                <Divider />
              </div>
              {substation && "generationExtraCapacity" in substation && (
                <>
                  <Generation
                    heading="Generation Headroom"
                    mw={
                      generationValue ? `${fixed(generationValue, 2)}MW` : "0MW"
                    }
                    dif={substation?.generationExtraCapacity}
                    type="success"
                  />
                  <div className={styles.right__divider}>
                    <Divider />
                  </div>
                </>
              )}

              {substation && "demandExtraCapacity" in substation && (
                <>
                  <Generation
                    heading="Demand Headroom"
                    mw={demandValue ? `${fixed(demandValue, 2)}MW` : "0MW"}
                    dif={substation.demandExtraCapacity}
                    type="error"
                  />
                  <div className={styles.right__divider}>
                    <Divider />
                  </div>
                </>
              )}

              {isRiskAvailable && <RiskScore risk={substation?.risk_score} />}

              {/* Project Planning UI Here  */}

              {substations?.planningData !== null &&
                (filters.assetType === "Renewable Energy" ||
                  filters.assetType === "Energy Storage" ||
                  filters.assetType === "EV Infrastructure") &&
                typeof substations.planningData !== "undefined" &&
                Object.keys(substations?.planningData).length > 0 && (
                  <div style={{ width: "100px" }}>
                    <PlanningCard text={PlanningCardHeading} />
                  </div>
                )}
              {substations?.planningData !== null &&
                typeof substations.planningData !== "undefined" &&
                Object.keys(substations?.planningData).length > 0 && (
                  <div style={{ width: "100px" }}>
                    <SpatialCard
                      text="Spatial Analysis"
                      id={substation.SubstationID}
                      score={
                        geoRisk[substation.SubstationID] ||
                        substation?.risk_score
                      }
                    />
                  </div>
                )}

              <SaveProjectButton setOpen={(value) => setSaveProject(value)} />
              <SaveProjectModal
                ProjectData={dataForSaveProject}
                open={saveProject}
                setOpen={(value) => setSaveProject(value)}
              />
            </div>
          ) : (
            <>
              <div className={styles.no_substation}>
                <WarningAmberIcon /> <p>No Substation Selected </p>
              </div>
              <div style={{ paddingRight: "10px" }}>
                <Divider />
              </div>
            </>
          )}
        </div>

        <div className={styles.searchHeading}>
          <QueryStatsOutlinedIcon sx={{ color: "#0c2721" }} />
          <p className={styles.searchHeading__para}>Substations from search</p>
        </div>
        {[...substations?.substations]
          ?.sort((a, b) => b.iq_score - a.iq_score)
          ?.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.substationCard}
                // onMouseOver={() => setHoverSubStation(item)}
                // onMouseLeave={() => setRemoveHoverSubStation()}
                onClick={() => handleClickMarker(item)}
              >
                <Stamford
                  padding={true}
                  key={index}
                  active={substation?.SubstationID === item?.SubstationID}
                  name={item.Name}
                  percentage={fixed(item.iq_score, 2)}
                  voltageLine={item?.VoltageLine}
                  source={item?.Source}
                  constrainedType={
                    item?.ConstrainedType || item?.[getCurrentSeasonUK()]
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RightSidebar;
