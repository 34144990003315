import React from 'react'
import Button from "../../Common/Button/Button";
import styles from './styles.module.scss';

const Recommendation = (props) => {
    const { recommendation, setRecommendation } = props;
    const buttonArray = ['😃  Very Likely', '🙂  Likely', '😐  Neutral', '😕  Unlikely', '☹️  Very Unlikely']

    return (
        <div className={styles.recommendation}>
            <p className={styles.para1}>
                How likely are you to recommend VOLTQUANT to a<br /> colleague or friend?
            </p>
            <div className={styles.recommendationButtons}>
                {buttonArray?.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            height='45px'
                            bgColor={recommendation === item ? '#1F443A' : '#fff'}
                            color={recommendation === item ? '#fff' : '#1F443A'}
                            radius='12px'
                            borderColor={recommendation === item ? '#1F443A' : '#DCDFE5'}
                            text={item}
                            handleClick={() => setRecommendation(item)}
                        />
                    )
                })}
            </div>

        </div>
    )
}

export default Recommendation
