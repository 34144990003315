import * as React from "react";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";

const Loader = (props) => {
  const { left = "50%" } = props;
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "fixed",
        top: "50%",
        left: left,
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}
    >
      <CircularProgress color="success" />
    </Stack>
  );
}
export default Loader