import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Button from "../../Components/Common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import {
  setProjects,
  setProjectsLoading,
} from "../../Components/Redux/reducers/projectsSlice";
import Loader from "../../Components/Common/Loader/Loader";
import { fetchAllDeletedProject } from "../../Components/Api/API";
import styles from "./styles.module.scss";
import { BackArrow, Warning } from "../../Components/SVG";
import ProjectCard from "../../Components/ProjectCard";

const DeletedProjects = () => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const { projects, projectsLoading } = useSelector((state) => state.projects);
  const [selectedProject, setSelectedProject] = useState();


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAllDeletedProject(pageNumber);
  }, [pageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAllDeletedProject = (pageNumber) => {
    dispatch(setProjectsLoading(true));
    dispatch(setProjects([]));
    fetchAllDeletedProject(pageNumber).then((res) => {
      console.log("res", res);
      if (res?.success) {
        if (res?.data?.projects?.length === 0) {
          ToastNotification("error", "You have no deleted projects");
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  // useEffect(() => {
  //     if (projects.length > 0) {
  //         const filtered = projects.filter((project) =>
  //             project.projectName.toLowerCase().includes(searchByName.toLowerCase())
  //         );
  //         setFilteredProjects(filtered);
  //     }
  // }, [searchByName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredProjects(projects?.projects);
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps


  const formatDateString = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1.
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateRemainingDays = (deletedAt) => {
    const deletionDate = new Date(deletedAt);
    const currentDate = new Date();
    const timeDifference = currentDate - deletionDate;

    // Convert the time difference from milliseconds to days
    const daysSinceDeletion = timeDifference / (1000 * 3600 * 24);

    // Calculate remaining days (30-day visibility period)
    const remainingDays = 30 - daysSinceDeletion;

    return remainingDays > 0 ? Math.floor(remainingDays) : 0;
  };

  return (
    <div className={styles.projectContainer}>
      <div className={styles.projects} >
        <div className={styles.shareButtonContainer}>
          <Button
            text="Back"
            icon={<BackArrow />}
            height="40px"
            radius="12px"
            bgColor="#fff"
            borderColor="#1e232e33"
            handleClick={() => handleBackClick()}
          />
          <Button
            height="35px"
            bgColor="#FFFAEB"
            color="#F79009"
            radius="100px"
            borderColor="#FFFAEB"
            text="All projects in the Deleted Items will be permanently deleted after 30 days."
            icon={<Warning />}
          />
        </div>
        <h1 className={styles.projectContainer__heading}>Deleted Projects</h1>
        {/* <Filters
                        searchByName={searchByName}
                        setSearchByName={setSearchByName}
                    /> */}
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          {projectsLoading ? (
            <Loader />
          ) : (
            <>
              {projects?.projects?.length > 0 ? (
                filteredProjects?.map((project, index) => {
                  const mapedDate = formatDateString(project?.date);
                  const remainingDays = calculateRemainingDays(
                    project?.deleted_at
                  );

                  return (
                    <Grid item xs={4} key={project.id}>
                      <ProjectCard
                        project={project}
                        mapedDate={mapedDate}
                        showButton={false}
                        deletet={true}
                        remainingDays={remainingDays}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center", width: "100%", p: 4 }}>
                  <Typography variant="h5">No deleted projects</Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
      </div>
      <div
        className={styles.pagination}
      >
        {projects?.total > 9 && (
          <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Pagination
              page={pageNumber}
              count={Math.ceil((projects?.total || 0) / 9)}
              size="large"
              onChange={(event, value) => setPageNumber(value)}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#1F443A !important",
                  color: "white",
                },
              }}
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default DeletedProjects;
