import React from 'react'
import styles from './styles.module.scss';

const TextInput = (props) => {
    const { type, value, setValue, placeholder, margin, readOnly, format, min, max, borderRadius } = props
    return (
        <input
            readOnly={readOnly}
            type={type}
            format={format}
            min={min}
            max={max}
            className={styles.t_input}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            style={{ margin: margin, borderRadius: borderRadius }}
        />
    )
}

export default TextInput
