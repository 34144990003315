export const links = {
  Ancient_Woodland: "ancient_woodland",
  Flood_Risk_Zone: "flood_risk_zone",
  Area_Of_Outstanding_Natural_Beauty: "area-of-outstanding-natural-beauty",
  Article_4_Direction_Area: "article-4-direction-area",
  Brownfield_Land: "brownfield-land",
  Brownfield_Site: "brownfield-site",
  Building_Preservation_Notice: "building-preservation-notice",
  Central_Activities_Zone: "central-activities-zone",
  Certificate_Of_Immunity: "certificate-of-immunity",
  Civil_Parish: "civil-parish",
  Conservation_Area: "conservation-area",
  Design_Code_Area: "design-code-area",
  Green_Belt: "green_belt",
  Heritage_At_Risk: "heritage-at-risk",
  Heritage_Coast: "heritage-coast",
  Historic_Parks_and_Gardens: "park-and-garden",
  Listed_Building: "listed-building-outline",
  // Listed_Building: "listed-building",
  Local_Authority_District: "local-authority-district",
  Local_Planning_Authority: "local-planning-authority",
  Locally_Listed_Building: "locally-listed-building",
  National_Park: "national-park",
  Protected_Wreck_Site: "protected-wreck-site",
  Ramsar_Site: "ramsar-site",
  Region: "region",
  Registered_Battlefields: "registered-battlefields",
  Park_And_Garden: "national-park",
  Scheduled_Monuments: "scheduled-monument",
  Site_Of_Special_Scientific_Interest: "site-of-special-scientific-interest",
  Registered_Parks: "Registered_Parks_and_Gardens",
  Special_Area_of_Conservation: "special-area-of-conservation",
  Special_Protection_Area: "special-protection-area",
  Tree_Zones: "tree",
  Tree_Preservation_Zone: "tree-preservation-zone",
  World_Heritage_Site: "world-heritage-site",
  World_Heritage_Site_Buffer_Zone: "world-heritage-site-buffer-zone",
  Substations_Queue: "clearview",
  National_Charge_Point_Register: "ncpr",
  Data_Centres: "data_centres",
  Network_Centres: "network_centres",
  Pop_Assets: "pop_assets",
  Exchange_Assets: "exchange_assets",
  Network_Cables: "network_cables",
  Agriculture_Land_Classification: "agriculture-land-classification2",
  ENWL_11kV_Overhead: "enwl-11kv-overhead",
  ENWL_11kV_Underground: "enwl-11kv-underground",
  ENWL_County_Polygons: "enwl-county-polygons",
  ENWL_Distribution_Substation_Headroom:
    "enwl-distribution-substation-headroom",
  ENWL_General_Boundaries: "enwl-general-boundaries",
  Canmore_Maritime_heritage: "hes-canmore-maritime",
  Canmore_Points_Heritage: "hes-canmore-points",
  Conservation_Areas_Scotland: "hes-conservation-areas",
  Gardens_and_Design_Landscapes: "hes-gardens-and-design-landscapes",
  Historic_Protected_Areas: "hes-historic-protected-areas",
  Listed_Buildings_Scotland: "hes-listed-buildings",
  Listed_Buildings_Boundaries_Scotland: "hes-listed-buildings-boundaries",
  Properties_in_Care: "hes-properties-in-care",
  Scheduled_Monuments_Scotland: "hes-scheduled-monuments",
  World_Heritage_Sites_Scotland: "hes-world-heritage-sites",
  Ancient_Woodlands_Wales: "dmw-ancient-woodland",
  Area_Of_Natural_Outstanding_Beauty_Wales: "dmw-aonb",
  Conservation_Area_Wales: "dmw-conservation-area",
  Flood_Risk_From_Rivers: "dmw-floodriskfromrivers",
  Listed_Buildings_Wales: "dmw-listed-buildings",
  Pre_Assessed_Areas_For_Wind_Energy: "dmw-pre-assessed-areas-for-wind-energy",
  Ramsar_Wales: "dmw-ramsar",
  Scheduled_Monuments_Wales: "dmw-scheduled-monuments",
  Flood_Risk_From_Sea: "dmw-seafloodriskfromsea",
  Site_Of_Special_Scientific_Interest_Wales: "dmw-sssi",
  World_Heritage_Sites_Wales: "dmw-world-heritage-sites",
  National_Grid_Cables: "national-grid-cables",
  National_Grid_Overhead_Lines: "national-grid-overheadlines",
  National_Grid_Substations: "national-grid-substations",
  National_Grid_Towers: "national-grid-towers",
  National_Gas_Pipe: "national-gas-pipe",
  National_Gas_Site: "national-gas-site",
  UKPN_11kV_HV_Overhead_Lines: "ukpn-11kv-hv-overhead-new",
  UKPN_132kV_Overhead_Lines: "ukpn-132kv-overhead-lines-new",
  UKPN_33kV_Overhead_Lines: "ukpn-33kv-overhead-lines-new",
  UKPN_66kV_Overhead_Lines: "ukpn-66kv-overhead-lines-shapefile-new",
  UKPN_License_Area_Grid_Supply_Points:
    "ukpn-licence-are-grid-supply-points-areas",
  UKPN_Low_Voltage_Overhead_Lines: "ukpn-low-voltage",
  UKPN_Primary_Substation_Distribution_Areas:
    "ukpn-primary-substation-distribution-areas",
  UKPN_Secondary_Sites: "ukpn-secondary-sites",
  SSEN_11kV_Cables: "ssen-11kv-cables-new",
  SSEN_230_400V_Cables: "ssen-230to400v-cables-new",
  SSEN_33kV_Cables: "ssen-33kv-cables-new",
  NPG_132kV_Overhead: "npg-132kv-overhead-new",
  NPG_132kV_Underground: "npg-132kv-underground-new",
  NPG_33kV_Overhead: "npg-33kv-overhead-new",
  NPG_33kV_Underground: "npg-33kv-underground-new",
  NPG_66kV_Overhead: "npg-66kv-overhead-new",
  NPG_66kV_Underground: "npg-66kv-underground-new",
  NGED_11kV_Overhead_Lines: "nged-11kv-overheadlines-new",
  NGED_11kV_Underground: "nged-11kv-underground-new",
  NGED_132kV_Overhead_Lines: "nged-132kv-overheadlines-new",
  NGED_132kV_Underground: "nged-132kv-undergroundlines-new",
  NGED_33kV_Overhead_Lines: "nged-33kv-overheadlines-new",
  NGED_33kV_Underground: "nged-33kv-underground-new",
  NGED_66kV_Overhead: "nged-66kv-overhead-new",
  NGED_66kV_Underground_New: "nged-66kv-underground-new",
};