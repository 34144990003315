function getCurrentSeasonUK() {
    const now = new Date();
    const year = now.getFullYear();
    const springStart = new Date(year, 2, 20); // March 20
    const summerStart = new Date(year, 5, 21); // June 21
    const autumnStart = new Date(year, 8, 23); // September 23
    const winterStart = new Date(year, 11, 21); // December 21
    const winterStartNextYear = new Date(year + 1, 2, 19); // March 19 next year for winter end
    
    if (now >= springStart && now < summerStart) {
      return 'SummerConstraint'; // its a spring
    } else if (now >= summerStart && now < autumnStart) {
      return 'SummerConstraint'; 
    } else if (now >= autumnStart && now < winterStart) {
      return 'WinterConstraint';// its a Autumn
    } else if (now >= winterStart || now < winterStartNextYear) {
      return 'WinterConstraint';
    }   
  }

  export default getCurrentSeasonUK;