// ClickyScript.js

import React, { useEffect } from "react";

const ClickyScript = () => {
  useEffect(() => {
    // Clicky script
    (function () {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "//static.getclicky.com/js";
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(s);
    })();
  }, []);

  return <></>; // or you can return null if you prefer
};

export default ClickyScript;
