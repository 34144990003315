import io from "socket.io-client";

// Assuming you have your WebSocket server running on this URL
// const SOCKET_URL = "http://localhost:3001";
const SOCKET_URL = "https://dev.backend.voltquant.uk";
// const SOCKET_URL = "https://backend.voltquant.uk";

export const socket = io(SOCKET_URL);

const socketService = {
  init: (callback) => {
    // Listen for specific events and handle them
    socket.on("connect", () => {
      console.log("socket Connected to WebSocket server");
    });

    socket.on("newMessage", (newMessage) => {
      console.log("socket on New message", newMessage);
      if (callback) callback({ newMessage });
    });

    socket.on("receiveGroupMessage", (receiveGroupMessage) => {
      console.log("socket on Receive Group Message", receiveGroupMessage);
      if (callback) callback({ receiveGroupMessage });
    });

    socket.on("receiveCompanyGroupMessage", (receiveCompanyGroupMessage) => {
      console.log("socket on Receive Company Group Message", receiveCompanyGroupMessage);
      if (callback) callback({ receiveCompanyGroupMessage });
    });

    // Listen for online users update
    socket.on("onlineUsers", (onlineUsers) => {
      console.log("socket on Online Users:", onlineUsers);
      // Here, you can call a callback function to update the UI or state with the list of online users
      if (callback) callback({ onlineUsers });
    });

    // Listen for invitation
    socket.on("invitation", (invitation) => {
      console.log("socket on invitation to Users:", invitation);
      // Here, you can call a callback function to update the UI or state with the list of online users
      if (callback) callback({ invitation });
    });

    socket.on("invitation_response", (invitationResponse) => {
      console.log("socket on invitationResponse to Users:", invitationResponse);
      // Here, you can call a callback function to update the UI or state with the list of online users
      if (callback) callback({ invitationResponse });
    });

    // Add more event listeners as needed
  },
  setUserId: (userId) => {
    socket.emit("setUserId", userId);
    console.log(`socket emit User ID set: ${userId}`);
  },
  joinGroup: (body) => {
    socket.emit("joinGroup", body);
    console.log(`socket emit Group ID set: ${body?.groupId}`);
  },

  joinCompanyGroup: (id) => {
    socket.emit("joinCompanyGroup", id);
    console.log(`socket emit Company Group ID set: ${id}`);
  },

  // Function to emit events
  emit: (event, data) => {
    socket.emit(event, data);
  },

  // Disconnect socket (optional)
  disconnect: () => {
    if (socket.connected) {
      socket.disconnect();
    }
  },
};

export default socketService;
