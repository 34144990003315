import React from "react";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '900px',
    height: '690px',
    // overflowY: 'scroll',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // p: 2,
    borderRadius: 2,
};

const SheduleMeeting = ({open, setOpen}) => {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <div
                            className="meetings-iframe-container"
                            data-src="https://meetings-eu1.hubspot.com/george-kolokotronis/voltquant-product-request?embed=true"
                        />

                        <Helmet>
                            <script
                                type="text/javascript"
                                src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                            />
                        </Helmet>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default SheduleMeeting