import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Logo from "./../../../../Images/Logo1.svg";
import { ToastNotification } from "../../../../Components/Utils/ToastNotifications";
import LoginRightSide from "../LoginRightSide";
import styles from "../styles.module.scss";
import { baseURL } from "../../../../Components/Api/instance";

const NewPassword = (props) => {
  const { email, code, count, setCount } = props;

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is Required*")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,99}$/,
        "Password must contain at least one special character, one uppercase letter, one lowercase letter, and one number"
      ),
  });

  const resetPassword = (values) => {
    const formData = {
      username: email,
      code: code,
      newPassword: values.password,
    };
    // Add your signup logic here
    axios
      .post(
        `${baseURL}/user/reset-password`,
        formData
      )
      .then((res) => {
        ToastNotification("success", "Password changed");
        setCount(count + 1);
      })
      .catch((e) => {
        ToastNotification("error", e?.response?.data?.message);
        console.log("error", e);
      });
  };

  return (
    <div className={styles.signup}>
      <div className={styles.signup__container}>
        <img src={Logo} alt="logo vector" />

        <h1 className={styles.signup__container__heading}>New Password</h1>
        <p className={styles.signup__container__para}>
          Choose a password that's harder for people to guess
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={resetPassword}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
            } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  value={values.password}
                  onBlur={handleBlur}
                  error={errors.password}
                  style={{
                    borderColor:
                      errors.password && touched.password ? "red" : "#C5CAD4",
                  }}
                />
                {errors.password && touched.password && (
                  <div className={styles.formikError}>{errors.password}</div>
                )}
                <button type="submit">Submit</button>
              </form>
            );
          }}
        </Formik>
      </div>
      <LoginRightSide />
    </div>
  );
};

export default NewPassword;
