import React from 'react'
import styles from './styles.module.scss';
import { SearchIcon } from "../../SVG";

const SearchInput = (props) => {
    const { value, setValue, placeholder, width } = props
    return (
        <div className={styles.inputContainer} style={{width: width}}>
            <SearchIcon />
            <input
                className={styles.t_input}
                style={{width: width}}
                type='text'
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}

export default SearchInput
