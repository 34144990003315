import { createSlice } from "@reduxjs/toolkit";

const substationSlice = createSlice({
  name: "substations",
  initialState: {
    userLocation: null,
    isFindClosestPoints: false,
    substations: [],
    planningData: {},
    clossestPlanningProjects: [],
    // popupSubStation: null,
    maplayerFilter: false,
    leftSidebarSelectedOption: 'search',
    centroid: null
  },
  reducers: {
    setUserLocation: (state, action) => {
      return { ...state, userLocation: action.payload };
    },
    triggerClosestPoints: (state, action) => {
      return { ...state, isFindClosestPoints: action.payload };
    },
    setSubstations: (state, action) => {
      return { ...state, substations: action.payload };
    },
    setPlanningData: (state, action) => {
      return { ...state, planningData: action.payload };
    },
    setClosestPlanningProjects: (state, action) => {
      return { ...state, clossestPlanningProjects: action.payload };
    },
    // setPopupSubStation: (state, action) => {
    //   return {
    //     ...state,
    //     popupSubStation: action.payload
    //   }
    // },
    setMaplayerFilter: (state, action) => {
      return { ...state, maplayerFilter: action.payload };
    },
    setleftSidebarSelectedOption: (state, action) => {
      return { ...state, leftSidebarSelectedOption: action.payload };
    },
    setPolygonCentroid: (state, action) => {
      return { ...state, centroid: action.payload };
    },
  },
});

export const {
  setUserLocation,
  triggerClosestPoints,
  setSubstations,
  setPlanningData,
  setClosestPlanningProjects,
  // setPopupSubStation,
  setMaplayerFilter,
  setleftSidebarSelectedOption,
  setPolygonCentroid
} = substationSlice.actions;
export default substationSlice.reducer;
