import { createSlice } from "@reduxjs/toolkit";

const selectedLayersSlice = createSlice({
  name: "selectedLayers",
  initialState: {
    selectedLayers: [],
    geoSpatialRisk: []
  },
  reducers: {
    setSelectedLayers: (state, action) => {
      state.selectedLayers = action.payload;
    },
    setGeospatialLayers: (state, action) => {
      state.geoSpatialRisk = action.payload;
    },
  },
});

export const { setSelectedLayers,setGeospatialLayers } = selectedLayersSlice.actions;
export default selectedLayersSlice.reducer;