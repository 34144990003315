import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styles from "./styles.module.scss";
import { LightTooltip } from "./Stamford";

import { fixed } from "../../Utils/Formats";
const RiskScore = ({ risk }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 6.5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#E3E7EF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: value > 50 ? "red" : "#14D92B",
    },
  }));

  return (
    <>
      <LightTooltip
        title="Risk Score measures the potential risks involved in connecting to a substation. It factors in various elements such as pending projects of a similar nature, geographical data including zone classifications, available capacity at the substation, and more."
        placement="left-start"
        arrow
        margin="20px"
      >
        {risk !== null && (
          <div className={styles.risk}>
            <div style={{ width: "70%" }}>
              <h5 className={styles.risk__score}>Risk Score</h5>
              <BorderLinearProgress variant="determinate" value={risk} />
              {/* <p className={styles.risk__para}>
              -0,8% <span>from last month</span>
            </p> */}
            </div>
            <h1 className={styles.risk__heading}>{fixed(risk, 1) + "%"}</h1>
          </div>
        )}
      </LightTooltip>
    </>
  );
};
export default RiskScore;
