import React from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { setTakeImage } from "../../Redux/reducers/projectsSlice";
const SaveProjectButton = ({ setOpen }) => {

  const dispatch = useDispatch();

  return (
    <div
      className={styles.save_project}
      onClick={() => {
        dispatch(setTakeImage(true));
        setOpen(true);
      }}
    >
      <p>Save Project</p>
    </div>
  );
};
export default SaveProjectButton;
