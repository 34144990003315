import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    onlineUsers: [],
    userMessages: [],
    selectedUserChat: null,
    selectedGroup: null,
    selectedCompanyGroup: null,
    newNotification: false,
    newOneToOneMessage: null,
    newGroupMessage: null,
    newCompanyGroupMessage: null,
    fetchUserGroups: false
  },
  reducers: {
    setOnlineUsers: (state, action) => {
      return { ...state, onlineUsers: action.payload };
    },
    setUserMessages: (state, action) => {
      return {
        ...state,
        userMessages: action.payload,
      };
    },
    appendUserMessages: (state, action) => {
      return {
        ...state,
        userMessages: [...state.userMessages, action.payload],
      };
    },
    setSelectedUserChat: (state, action) => {
      state.selectedUserChat = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setSelectedCompanyGroup: (state, action) => {
      state.selectedCompanyGroup = action.payload;
    },
    setNewNotification: (state, action) => {
      state.newNotification = action.payload;
    },
    setNewOneToOneMessage: (state, action) => {
      state.newOneToOneMessage = action.payload;
    },
    setNewGroupMessage: (state, action) => {
      state.newGroupMessage = action.payload;
    },
    setNewCompanyGroupMessage: (state, action) => {
      state.newCompanyGroupMessage = action.payload;
    },
    setFetchUserGroups: (state, action) => {
      state.fetchUserGroups = action.payload;
    },
  },
});

export const {
  setNewOneToOneMessage,
  setNewGroupMessage,
  setNewCompanyGroupMessage,
  setOnlineUsers,
  setUserMessages,
  setSelectedUserChat,
  appendUserMessages,
  setSelectedGroup,
  setSelectedCompanyGroup,
  setNewNotification,
  setFetchUserGroups
} = chatSlice.actions;
export default chatSlice.reducer;
