import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '../Common/Button/Button';
import { HorizontalDots, Move, Share, Trash } from '../SVG';
import { TablePagination } from '@mui/material';
import { fixed } from '../Utils/Formats';
import { useNavigate } from 'react-router-dom';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoveProjectModal from '../Modals/MoveProjectModal';
import ShareProjectModal from '../Modals/ShareProjectModal';
import { styled } from '@mui/system';

const TableComponent = (props) => {
    const { headCells, projects, getAllCollection, selectedProject, setSelectedProject, setOpenDeleteModal, rowsPerPage, setRowsPerPage } = props;
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    // const [dense, setDense] = useState(false);
    // const [rowsPerPage, setRowsPerPage] = useState(9);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('project_name');
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);

    const [openMoveModal, setOpenMoveModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);

    const navigate = useNavigate();

    const [projectMenu, setProjectMenu] = useState(null);
    const open = Boolean(projectMenu);

    const evaluateRowsPerPage = (projects) => {

        const projectLength = projects?.total;
        const result = [9];

        if (projectLength >= 9) {
            let milestone = 25;
            while (milestone <= projectLength) {
                result.push(milestone);
                milestone += 25;
            }
            if (result[result?.length - 1] === 9 && projectLength > result[result?.length - 1]) {
                result.push(25)
            } else if (result[result?.length - 1] !== 9 && projectLength > result[result?.length - 1]) {
                const lastIndex = result[result?.length - 1] + 25
                result.push(lastIndex)
            }
        }

        return result
    }

    useEffect(() => {
        if (projects?.total > 0) {
            // console.log("useEffect is working")
            const rows = evaluateRowsPerPage(projects)
            setRowsPerPageOptions(rows)
        }
    }, [projects])

    const handleProjectClick = (event) => {
        setProjectMenu(event.currentTarget);
    };
    const handleClose = () => {
        setProjectMenu(null);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const CustomPaginationActions = styled('div')`
    .MuiTablePagination-actions > button:nth-of-type(1),
    .MuiTablePagination-actions > button:nth-of-type(2) {
      margin-right: 10px;
      visibility: hidden
    }
  `;
    const CustomTablePaginationActions = (props) => {
        return (
            <CustomPaginationActions >
                {/* This div is left intentionally empty to keep the spacing consistent */}
            </CustomPaginationActions>
        );
    };

    // const headCells = [
    //     {
    //         id: 'project_name',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Project Name',
    //         minWidth: '200px'
    //     },
    //     {
    //         id: 'asset_type',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Asset Type',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'headrom_type',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Headrom Type',
    //         minWidth: '120px'
    //     },
    //     {
    //         id: 'radius',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Radius',
    //         minWidth: '150px'
    //     },
    //     {
    //         id: 'distance',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Distance',
    //         minWidth: '150px'
    //     },
    //     {
    //         id: 'capacity',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Capacity',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'substation_name',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Substation Name',
    //         minWidth: '150px'
    //     },
    //     {
    //         id: 'constraint_type',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Constraint Type',
    //         minWidth: '150px'
    //     },
    //     {
    //         id: 'dno',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'DNO',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'iq_score',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'IQ Score',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'risk_score',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Risk Score',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'date',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Date',
    //         minWidth: '100px'
    //     },
    //     {
    //         id: 'status',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Status',
    //         minWidth: '180px'
    //     },
    //     {
    //         id: 'action',
    //         numeric: true,
    //         disablePadding: false,
    //         label: 'Action',
    //         minWidth: '60px'
    //     }
    // ];

    const EnhancedTableHead = (props) => {
        // const { onSelectAllClick, numSelected, rowCount } = props;

        return (
            <TableHead>
                <TableRow>
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell> */}
                    {headCells.map((headCell) => (headCell?.checked &&
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            style={{ fontWeight: '600', minWidth: headCell.minWidth, fontFamily: 'Eudoxus Sans' }}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Projects
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = projects?.projects?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    const handleProjectDetail = (project) => {
        navigate(`/project/${project.id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty projects.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects?.projects?.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(projects?.projects, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 6 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={projects?.total}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        // onClick={(event) => {
                                        //     handleProjectDetail(row);
                                        //     handleClick(event, row.id)
                                        // }}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        {headCells?.[0]?.checked &&
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                                // sx={{paddingLeft: '20px' }}
                                                style={{ fontFamily: 'Eudoxus Sans' }}
                                            >
                                                {row.projectName}
                                            </TableCell>
                                        }
                                        {headCells?.[1]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row.assetType}</TableCell>
                                        }
                                        {headCells?.[2]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row.demand === true ? "Demand" : "Generation"}</TableCell>
                                        }
                                        {headCells?.[3]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>({row.minRadius} - {row.maxRadius}) '{row.radiusUnit}'</TableCell>
                                        }
                                        {headCells?.[4]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{fixed(row?.selectedSubstation?.distance, 2)} '{row.radiusUnit}'</TableCell>
                                        }
                                        {headCells?.[5]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row.minCapacity} '{row.capacityUnit}'</TableCell>
                                        }
                                        {headCells?.[6]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row?.selectedSubstation?.Name}</TableCell>
                                        }
                                        {headCells?.[7]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row?.selectedSubstation?.VoltageLine} KV</TableCell>
                                        }
                                        {headCells?.[8]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{fixed(row?.selectedSubstation?.Demand, 2)} MW</TableCell>
                                        }
                                        {headCells?.[9]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{fixed(row?.selectedSubstation?.Generation, 2)} MW</TableCell>
                                        }
                                        {headCells?.[10]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row?.selectedSubstation?.ConstrainedType?.length > 0 ? row?.selectedSubstation?.ConstrainedType : row?.selectedSubstation?.WinterConstraint}</TableCell>
                                        }
                                        {headCells?.[11]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }} v>{row.dno}</TableCell>
                                        }
                                        {headCells?.[12]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans', color: '#0aed24' }}>{fixed(row.iqScore, 2)}%</TableCell>
                                        }
                                        {headCells?.[13]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans', color: row.riskScore < 50 ? '#0aed24' : 'red' }}>{fixed(row.riskScore, 2)}%</TableCell>
                                        }
                                        {headCells?.[14]?.checked &&
                                            <TableCell style={{ fontFamily: 'Eudoxus Sans' }}>{row.date}</TableCell>
                                        }
                                        {headCells?.[15]?.checked &&
                                            <TableCell align='center'>
                                                <Button
                                                    height="25px"
                                                    bgColor={row?.ProjectStatus?.bgColor}
                                                    color="#fff"
                                                    radius="100px"
                                                    borderColor={row?.ProjectStatus?.bgColor}
                                                    text={row?.ProjectStatus?.name}
                                                />
                                            </TableCell>
                                        }
                                        {headCells?.[16]?.checked &&
                                            <TableCell align='right'>
                                                <div
                                                    style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        setSelectedProject(row);
                                                        handleProjectClick(e);
                                                    }}
                                                >
                                                    <HorizontalDots />
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={projects?.total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={CustomTablePaginationActions}
                />
            </Paper>

            <Menu
                id="basic-menu"
                anchorEl={projectMenu}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        height="38px"
                        bgColor="#fff"
                        color="#1F443A"
                        radius="100px"
                        borderColor="#1F443A"
                        text="Open Project"
                        handleClick={() => {
                            handleProjectDetail(selectedProject);
                        }}
                    />
                </MenuItem>
                <MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        bgColor="#F3F4F6"
                        radius="100px"
                        icon={<Move />}
                        borderColor="#F3F4F6"
                        handleClick={() => {
                            setOpenMoveModal(true);
                        }}
                    />
                </MenuItem>
                <MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        bgColor="#F3F4F6"
                        radius="100px"
                        icon={<Share />}
                        borderColor="#F3F4F6"
                        handleClick={() => {
                            setOpenShareModal(true);
                        }}
                    />
                </MenuItem>
                <MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        bgColor="#FFF5F6"
                        radius="100px"
                        icon={<Trash />}
                        borderColor="#FFF5F6"
                        handleClick={() => {
                            setOpenDeleteModal(true);
                        }}
                    />
                </MenuItem>
            </Menu>

            <MoveProjectModal
                open={openMoveModal}
                setOpen={setOpenMoveModal}
                projectId={selectedProject?.id}
                getAllCollection={getAllCollection}
            />
            <ShareProjectModal
                open={openShareModal}
                setOpen={setOpenShareModal}
                projectId={selectedProject?.id}
            />
        </Box>
    );
}

export default TableComponent;