import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close, Plus, Share, Trash } from "../../SVG";
import Button from "../../Common/Button/Button";
import ShareCollectionsModal from "../ShareCollectionsModal";
import CreateCollectionsModal from "../CreateCollectionsModal";
import DeleteCollectionsModal from "../DeleteCollectionsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCollections,
  fetchAllCollection,
  shareColectionToUsers,
} from "../../Api/API";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { setCollections } from "../../Redux/reducers/projectsSlice";
import SearchInput from "../../Common/SearchInput";

const ManageCollectionsModal = (props) => {
  const { open, setOpen } = props;
  const [shareCollectionModal, setShareCollectionModal] = useState(false);
  const [createCollectionModal, setCreateCollectionModal] = useState(false);
  const [deleteCollectionModal, setDeleteCollectionModal] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [searchByName, setSearchByName] = useState("");
  const { collections } = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  const handleDeleteModal = (collectionId) => {
    setSelectedCollectionId(collectionId);
    setDeleteCollectionModal(true);
  };

  const getAllCollections = () => {
    fetchAllCollection().then((res) => {
      if (res?.success) {
        if (res?.data?.length > 0) {
          dispatch(setCollections(res?.data));
        }
      }
    });
  };
  const handleDeleteCollection = (value) => {
    deleteCollections(selectedCollectionId, value)
      .then((res) => {
        console.log(res, "res");
        if (res?.success) {
          setDeleteCollectionModal(false);
          setSelectedCollectionId("");
          ToastNotification("success", "Collection deleted successfully!");
          getAllCollections();
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        ToastNotification("error", "Error: Collection deletion");
      });
  };

  const handleShareCollection = () => {
    if (!selectedUsers) {
      ToastNotification("warning", "Please select at least one user");
      return;
    }
    const userEmails = selectedUsers?.map((user) => user.value);

    let body = {
      emails: userEmails,
    };
    shareColectionToUsers(selectedCollectionId, body)
      .then((res) => {
        if (res?.success) {
          setShareCollectionModal(false);
          setSelectedCollectionId("");
          ToastNotification("success", "Collection shared");
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        ToastNotification("error", "Error: Collection sharing");
      });
  };

  useEffect(() => {
    const filtered = collections.filter((collection) =>
      collection.title.toLowerCase().includes(searchByName.toLowerCase())
    );
    setFilteredCollections(filtered);
  }, [collections, searchByName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.manageContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Manage Collections</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>Create, delete or update a collection</p>
          
          <SearchInput
            value={searchByName}
            setValue={setSearchByName}
            placeholder="Find a collection"
            width="460px"
          />
          <div className={styles.collectionContainer}>
            {filteredCollections.map((collection, index) => {
              return (
                <div
                  key={index}
                  className={
                    collection.title === "Default Collection"
                      ? styles.buttonContainer
                      : styles.buttonContainer1
                  }
                >
                  <p>{collection.title}</p>
                  <div className={styles.buttonContainer1__inner}>

                    {collection.title !== "Default Collection" && (
                      <Button
                        bgColor="#FFF5F6"
                        radius="100px"
                        icon={<Trash />}
                        borderColor="#FFF5F6"
                        handleClick={() => handleDeleteModal(collection.id)}
                      />
                    )}
                    <Button
                      bgColor="#F3F4F6"
                      radius="100px"
                      icon={<Share />}
                      borderColor="#F3F4F6"
                      handleClick={() => {
                        setShareCollectionModal(true);
                        setSelectedCollectionId(collection.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.line} />
          <Button
            height="45px"
            bgColor="#fff"
            color="#349879"
            radius="12px"
            borderColor="#fff"
            icon={<Plus fill="#349879" />}
            text="Create a new collection"
            handleClick={() => {
              setCreateCollectionModal(true);
              setOpen(false);
            }}
          />
        </div>
      </Dialog>
      <ShareCollectionsModal
        open={shareCollectionModal}
        setOpen={setShareCollectionModal}
        setSelectedUsers={setSelectedUsers}
        handleShareCollection={handleShareCollection}
      />
      <CreateCollectionsModal
        open={createCollectionModal}
        setOpen={setCreateCollectionModal}
        getAllCollections={getAllCollections}
      />
      <DeleteCollectionsModal
        open={deleteCollectionModal}
        setOpen={setDeleteCollectionModal}
        handleDelete={handleDeleteCollection}
      />
    </div>
  );
};
export default ManageCollectionsModal;
