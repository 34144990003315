import React, { useState } from "react";
import SendEmail from "./SendEmail";
import VerificationCode from "./VerificationCode";
import NewPassword from "./NewPassword";
import Success from "./Success";

const ResetPassword = () => {
  const [count, setCount] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  return (
    <div>
      {count === 1 && (
        <SendEmail
          email={email}
          setEmail={setEmail}
          count={count}
          setCount={setCount}
        />
      )}

      {count === 2 && (
        <VerificationCode
          code={code}
          setCode={setCode}
          count={count}
          setCount={setCount}
          email={email}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      )}

      {count === 3 && (
        <NewPassword
          email={email}
          code={code}
          password={password}
          setPassword={setPassword}
          count={count}
          setCount={setCount}
        />
      )}

      {count === 4 && <Success />}
    </div>
  );
};

export default ResetPassword;
