import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styles from "./styles.module.scss";

const RadioSelectors = ({ showAllSubstations, handleShowAllSubstations }) => {
  return (
    <div className={styles.radio_selector}>
      <FormControl>
          <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue={showAllSubstations}
            value={showAllSubstations}
            name="radio-buttons-group"
            onChange={handleShowAllSubstations}
          >
            <FormControlLabel value={true} control={<Radio />} label="Show All Substations" />
            <FormControlLabel value={false} control={<Radio />} label="Show Substations with Capacity" />
          </RadioGroup>
        </FormControl>
    </div>
  );
};
export default RadioSelectors;
