import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Dialog from "@mui/material/Dialog";
import styles from './styles.module.scss';
import Button from "../../Common/Button/Button";
import TextInput from "../../Common/TextInput";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { baseURL } from "../../Api/instance";

const CodeConfirmationModal = (props) => {
    const { open, setOpen, email } = props;
    const navigate = useNavigate();

    const [code, setCode] = useState('')

    const handleConfirm = (e) => {
        e.preventDefault();
        const formData = {
            username: email,
            code: code
        }
        // Add your signup logic here
        axios.post(`${baseURL}/user/confirm`, formData).then((response) => {
            console.log('response', response)
            ToastNotification("success", response?.data?.message)
            setOpen(false)
            navigate("/");
        }).catch((e) => {
            ToastNotification("error", e?.response?.data?.message)
            console.log('error', e)
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                // onClose={() => handleModal(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <div className={styles.confirmContainer}>
                    <div className={styles.headingContainer}>
                        <h1 className={styles.heading}>Confirm Your Email</h1>
                        {/* <Close onClick={() => setOpen(false)} /> */}
                    </div>
                    <p className={styles.para}>Please check you email and enter code here!</p>
                    <TextInput
                        type='text'
                        value={code}
                        setValue={setCode}
                        placeholder='Enter Code'
                    />
                    <div className={styles.buttonContainer}>
                        <Button height='40px' bgColor='#1F443A' color='#fff' radius='100px' borderColor='#1F443A' text='Submit' handleClick={handleConfirm} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
export default CodeConfirmationModal