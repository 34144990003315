import { createSlice } from "@reduxjs/toolkit";
import {RenewableLayersDefault} from '../../Common/MapFilterCard/layer.renewable'
const initialState = {
  activeLayers: [],
  activeCard: [],
  features: [],
  activeRenewableLayers:[]
};
const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setLayerData: (state, action) => {
      return { ...state, ...action.payload };
    },
    appendNewLayer: (state, action) => {
      const { layerName = null, append = true } = action.payload;
            if (["", null, undefined].includes(layerName)) {
        return state;
      }

      const previousActiveLayers = JSON.parse(
        JSON.stringify(state.activeLayers)
      );

      // A case where we need to to remove previous layers and add selected ones so thats why here we handled "append" props conditionally
      if (append === false) {
        previousActiveLayers.length = 0;
        previousActiveLayers?.push(layerName);
        return {
          ...state,
          activeLayers: previousActiveLayers,
        };
      }
      if (previousActiveLayers.includes(layerName)) {
        return state;
      }
      if (append === true) {
        previousActiveLayers?.push(layerName);
        return {
          ...state,
          activeLayers: previousActiveLayers,
        };
      }
    },
    removeLayer: (state, action) => {
      const lyr = action.payload.layerName;
      const previousActiveLayers = JSON.parse(
        JSON.stringify(state.activeLayers)
      );
      // const newActiveLayer = previousActiveLayers.filter(function (str) { return !str.includes(action.payload); });
      const newActiveLayers = previousActiveLayers.filter(
        (layer) => !layer.includes(lyr)
      );
      return {
        ...state,
        activeLayers: newActiveLayers,
      };
    },
    appendActiveCard: (state, action) => {
      return {
        ...state,
        activeCard: [...state.activeCard, action.payload],
      };
    },
    resetLayersData: (state) => initialState,
    setActiveLayersArray: (state, action) => {
      const { activeLayersArray = [] } = action.payload;

      return {
        ...state,
        activeLayers: activeLayersArray,
      };
    },
    setRenewableActiveLayersArray: (state, action) => {
      const { activeRenewableLayers = [] } = action.payload;

      return {
        ...state,
        activeRenewableLayers: activeRenewableLayers,
      };
    },
    setActiveCard: (state, action) => {
      return {
        ...state,
        activeCard: action.payload,
      };
    },
    setFeatues: (state, action) => {
      return {
        ...state,
        features: action.payload,
      };
    },
  },
});

export const {
  setLayerData,
  resetLayersData,
  appendNewLayer,
  removeLayer,
  setActiveLayersArray,
  appendActiveCard,
  setActiveCard,
  setFeatues,
  setRenewableActiveLayersArray
} = datasetSlice.actions;
export default datasetSlice.reducer;
