import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {  setRemoveOsmDataItem, setToggleShowLayer } from '../../Redux/reducers/gptOsmSlice'
import { EyeIcon, EyeOff, TrashGpt } from '../../SVG'
import ConvertToTitleCase from '../../Utils/TitleCaseConvertor'

const GptLayers = () => {
  const osmData = useSelector((state) => state.gptOsmData.osmData)
  const dispatch = useDispatch()
  const handleCheckboxChange = (ind) => {
    dispatch(setToggleShowLayer(ind))
  }
  return (
    <div>
      {
        osmData.map((item, i) => {
          return (
            <div key={i} style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px',
              padding: '10px',
              backgroundColor: '#F3F4F6',
              borderRadius: '8px'
            }}>
              <div>
                <svg width="10" height="10" style={{marginRight: '4px'}}>
                  <circle cx="5" cy="5" r="5" stroke="transparent" strokeWidth="0" fill={`${item.color}`} />
                </svg>
              {ConvertToTitleCase(item.name)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!item.show ? <div onClick={() => handleCheckboxChange(i)}><EyeOff color={'#787B82'} /> </div>
                  : <div onClick={() => handleCheckboxChange(i)}><EyeIcon color={'#787B82'} /></div>}
                <div onClick={() => dispatch(setRemoveOsmDataItem(i))}> <TrashGpt fill={'#787B82'} /></div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default GptLayers
