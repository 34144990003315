export const fixed = (number, fixedLength = 2) => {
  try {
    var value =
      number.toString().split(".")[0] +
      "." +
      number.toString().split(".")[1]?.slice(0, fixedLength);
    value = parseFloat(value);
    // var value = Number(number).toFixed(fixedLength);
    if (typeof value === "number" && (isNaN(value) || !isFinite(value))) {
      return 0;
    } else {
      return value;
    }
  } catch (error) {
    return 0;
  }
};

export function firstLetterCapital(str) {
  const lowerCaseStr = str.toLowerCase();
  const capitalizedStr =
    lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  return capitalizedStr;
}

export function kilometersToMiles(kilometers) {
  // Conversion factor
  const milesInAKilometer = 0.621371;

  // Calculate the equivalent miles
  const miles = kilometers * milesInAKilometer;

  return miles;
}

export function convertDate(isoDate) {
  const date = new Date(isoDate);

  // Specify locale and formatting options
  const options = {
    weekday: "long", // e.g., Monday
    year: "numeric",
    month: "long", // e.g., December
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    // timeZoneName: "short", // e.g., EST, PDT
  };

  const readableDate = date.toLocaleString("en-US", options);

  return readableDate;
}

export function timeAgoFind(date) {
  const messageDate = new Date(date);
  const currentDate = new Date();
  const difference = currentDate - messageDate; // Difference in milliseconds
  const minutesAgo = Math.floor(difference / 60000); // 60,000 milliseconds in a minute
  const hoursAgo = Math.floor(difference / 3600000); // 3,600,000 milliseconds in an hour
  const daysAgo = Math.floor(difference / 86400000); // 86,400,000 milliseconds in a day

  // console.log(minutesAgo,hoursAgo,daysAgo, messageDate, date)

  if (minutesAgo < 60) {
    return `${minutesAgo} minutes ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hours ago`;
  } else {
    return `${daysAgo} days ago`;
  }
}
export function ConvertDateIntoISOString(date) {

  const specificDate = new Date(date);
  const currentTime = new Date();

  // Set the time components of the specific date object to match the current time
  specificDate.setHours(currentTime.getHours());
  specificDate.setMinutes(currentTime.getMinutes());
  specificDate.setSeconds(currentTime.getSeconds());
  specificDate.setMilliseconds(currentTime.getMilliseconds());

  // Adjust for timezone to get UTC equivalent if needed
  specificDate.setMinutes(specificDate.getMinutes() - specificDate.getTimezoneOffset());

  // Convert to ISO string
  return specificDate.toISOString();

}
