import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";

const DeleteProjectModal = (props) => {
  const { open, setOpen, handleDetele, onClose= () => {setOpen(false)}, title="Are you sure you want to delete this project?", text=`This action will move the project to the "Deleted Items" tab. After 30 days the project will be deleted. Deletion is irreversible and will permanently remove all associated data.` } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.deleteContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>
              {" "}
              {title}
            </h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>
            {text}
          </p>
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={onClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Delete"
              handleClick={handleDetele}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DeleteProjectModal;
