import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { setCollections } from "./../../Redux/reducers/projectsSlice";
import styles from "./styles.module.scss";
import { moveProject, fetchAllCollection } from "../../Api/API";
import CustomSelect from "../../Common/CustomSelect";

const MoveProjectModal = (props) => {
  const { open, setOpen, projectId, getAllCollection } = props;
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const dispatch = useDispatch();
  const { collections, selectedCollection } = useSelector(
    (state) => state.projects
  );

  const handleClose = (e) => {
    setOpen(false);
    setSelectedOption(null);
    e.stopPropagation();
  };

  useEffect(() => {
    if (collections?.length > 0) {
      const filterCollections = collections?.filter(
        (data) => data.id !== selectedCollection?.id
      );
      const collectionOption = filterCollections?.map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      setOptions(collectionOption);
    }
  }, [collections, selectedCollection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      getCollection();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCollection = () => {
    fetchAllCollection().then((res) => {
      if (res?.success) {
        if (res?.data?.length > 0) {
          dispatch(setCollections(res?.data));
        }
      }
    });
  };

  const handleMoveProject = () => {
    if (selectedOption === null) {
      ToastNotification("warn", "Collection is required");
      return;
    } else {
      const body = {
        projectId: projectId,
        newCollectionId: selectedOption?.value,
      };
      moveProject(body).then((res) => {
        // if (res.success) {
        if (res.message) {
          setOpen(false);
          setSelectedOption(null);
          getAllCollection();
          ToastNotification("success", "Project moved");
        }
      });
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Move Project</h1>
            <Close onClick={handleClose} />
          </div>
          <p className={styles.para}>
            Move this project to your other collections.
          </p>
          {collections?.length > 0 && (
            <>
              <CustomSelect
                options={options}
                onChange={handleChange}
                placeholder="Select a Collection"
              />
              <br />
            </>
          )}
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Move Project"
              handleClick={handleMoveProject}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default MoveProjectModal;
