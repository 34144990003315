import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { ToastNotification } from "./../../Utils/ToastNotifications";
import { sendEmail } from "../../Api/API";
import TextArea from "../../Common/TextArea";
import TextInput from "../../Common/TextInput";
import { useSelector } from "react-redux/es";

const BugReportModal = (props) => {
  const { openBugReportModal, setOpenBugReportModal } = props;
  const { userDetail } = useSelector((state) => state.users);

  const [textareaContent, setTextareaContent] = useState("");
  const [title, setTitle] = useState("");

  const handleClose = () => setOpenBugReportModal(false);

  const submitReport = async () => {
    if (title.trim() === "") {
      ToastNotification("error", "Title is required");
    } else if (textareaContent.trim() === "") {
      ToastNotification("error", "Feedback is required");
    } else {
      let body = {
        name: userDetail?.user?.first_name
          ? userDetail?.user?.first_name
          : "John",
        email: userDetail?.user.email,
        message: textareaContent,
        title: title,
        subject: "Bug Reported",
      };
      sendEmail(body)
        .then((response) => {
          if (response.success) {
            setOpenBugReportModal(false);
            setTextareaContent(""); // Clear the textarea content after sending
            setTitle(""); // Clear the title content after sending
            ToastNotification("success", response.message);
          }
        })
        .catch((err) => {
          console.error("Error sending email:", err);
          ToastNotification("error", "Error: Sending feedback");
        });
    }
  };

  return (
    <div>
      <Dialog
        open={openBugReportModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.bugContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Report a bug or issue</h1>
            <Close onClick={handleClose} />
          </div>

          <p className={styles.para}>
            Your input is valuable! Reporting a bug or issue helps us enhance
            and refine our platform for a better user experience. Thank you for
            your contribution to our continuous improvement efforts.
          </p>
          <TextInput
            type="text"
            value={title}
            setValue={setTitle}
            placeholder="Title"
          />
          <TextArea
            value={textareaContent}
            setValue={setTextareaContent}
            placeholder="Your comment"
          />
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Submit"
              handleClick={submitReport}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default BugReportModal;
