// GoogleAnalyticsScript.js

import React, { useEffect } from "react";

const GoogleAnalyticsScript = () => {
  useEffect(() => {
    // Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-26T1ZZSVKV";
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-26T1ZZSVKV");
    };
  }, []);

  return <></>; // or you can return null if you prefer
};

export default GoogleAnalyticsScript;
