import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { restoreDeletedProject } from "../../Api/API";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { useNavigate } from "react-router-dom";

const RestoreProjectModal = (props) => {
  const { open, setOpen, projectId } = props;
  const navigate = useNavigate();

  const handleRestore = () => {
    restoreDeletedProject(projectId).then((res) => {
      console.log("res", res);
      if (res?.success) {
        ToastNotification("success", "Project restored");
        setOpen(false);
        navigate("/projects");
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.deleteContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Restore Project</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>
            Are you sure you want to Restore this project?
          </p>
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={() => setOpen(false)}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Confirm"
              handleClick={handleRestore}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default RestoreProjectModal;
