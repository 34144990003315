const logos = {
  ".No Controller":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/.No+Controller.png",
  "Alfa Power":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Alfa+Power.png",
  Allego:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Allego.png",
  APT: "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/APT.png",
  "AW Energy":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/AW+Energy.png",
  "Aylesford Electric":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Aylesford+Electric.png",
  "Be.EV":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Be.EV.png",
  Believ:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Believ.png",
  "Blink Charging":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Blink+Charging.png",
  "Boston Borough Council":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Boston+Borough+Council.png",
  "BP Pulse":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/BP+Pulse.png",
  "BP-Pulse (POLAR)":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/BP-Pulse+(POLAR).png",
  "Bradford Metropolitan District Council":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Bradford+Metropolitan+District+Council.png",
  "Car charged UK ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Car+charged+UK+ltd.png",
  CBRE: "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/CBRE.png",
  "Char.gy":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Char.gy.png",
  "Charge Your Car":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Charge+Your+Car.png",
  "ChargePlace Scotland":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ChargePlace+Scotland.png",
  "ChargePoint Network (Netherlands) B.V.":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ChargePoint+Network+(Netherlands)+B.V..png",
  "Charging Solutions":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Charging+Solutions.png",
  CitiPark:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/CitiPark.png",
  "City EV":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/City+EV.png",
  "Clenergy EV":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Clenergy+EV.png",
  "Connected Kerb":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Connected+Kerb.png",
  DBT: "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/DBT.png",
  "Drax Energy Solutions Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Drax+Energy+Solutions+Limited.png",
  "E.ON Drive":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/E.ON+Drive.png",
  "ecars ESB":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ecars+ESB.png",
  "Eco-Movement BV":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Eco-Movement+BV.png",
  ElectRoad:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ElectRoad.png",
  Elektromotive:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Elektromotive.png",
  "eo Charging":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/eo+Charging.png",
  "Equans EV Solutions":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Equans+EV+Solutions.png",
  "ESB EV Solutions":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ESB+EV+Solutions.png",
  "EV-Dot":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/EV-Dot.png",
  "EZ-Charge":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/EZ-Charge.png",
  Fastned:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Fastned.png",
  "Flowbird Smart City UK LTD":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Flowbird+Smart+City+UK+LTD.png",
  "Fulham Broadway Shopping Centre":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Fulham+Broadway+Shopping+Centre.png",
  "Green Mile Charging Co Ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Green+Mile+Charging+Co+Ltd.png",
  "GRIDSERVE Sustainable Energy":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/GRIDSERVE+Sustainable+Energy.png",
  Hubsta:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Hubsta.png",
  "InstaVolt Ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/InstaVolt+Ltd.png",
  "IONITY GmbH":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/IONITY+GmbH.png",
  "Joju Ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Joju+Ltd.png",
  "JOLT Charge Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/JOLT+Charge+Limited.png",
  "Lancashire Care NHS":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Lancashire+Care+NHS.png",
  "LEVC West London":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/LEVC+West+London.png",
  Mer: "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Mer.png",
  metermanager:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/metermanager.png",
  MiPermit:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/MiPermit.png",
  "Miralis Data":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Miralis+Data.png",
  "NI Public Sector":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/NI+Public+Sector.png",
  Nissan:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Nissan.png",
  "Opcharge UK":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Opcharge+UK.png",
  Osprey:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Osprey.png",
  "Pilot Group EV Charging Solutions":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Pilot+Group+EV+Charging+Solutions.png",
  "Plug N Go Ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Plug+N+Go+Ltd.png",
  "POD Point":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/POD+Point.png",
  "Project Centre":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Project+Centre.png",
  "Project EV":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Project+EV.png",
  "RAW Infrastructure Ltd":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/RAW+Infrastructure+Ltd.png",
  "Riverside ES":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Riverside+ES.png",
  "Sainsbury's":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Sainsbury's.png",
  ScottishPower:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ScottishPower.png",
  "Shell Recharge Solutions":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Shell+Recharge+Solutions.png",
  "Shell Recharge":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Shell+Recharge.png",
  Siemens:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Siemens.png",
  "Silverstone Green Energy":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Silverstone+Green+Energy.png",
  "Solnet Consultants Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Solnet+Consultants+Limited.png",
  "Source London":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Source+London.png",
  "Southampton City Council":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Southampton+City+Council.png",
  "SPX.Bosch.Keba":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/SPX.Bosch.Keba.png",
  "SureCharge.FM Conway":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/SureCharge.FM+Conway.png",
  "Swarco E.Connect":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Swarco+E.Connect.png",
  "Tonik Energy":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Tonik+Energy.png",
  "Trojan Energy Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Trojan+Energy+Limited.png",
  ubitricity:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/ubitricity.png",
  VendElectric:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/VendElectric.png",
  Voltec:
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Voltec.png",
  "Wattif EV UK Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Wattif+EV+UK+Limited.png",
  "Wenea Services UK Limited":
    "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Wenea+Services+UK+Limited.png",
  Zest: "https://voltquant-images.s3.eu-west-2.amazonaws.com/Logos/NCPR/Zest.png",
};

const findLogo = (name) => {
  if (name) {
    // console.log(colors[name], "colorsds");
    return logos[name];
  }
};
export default findLogo;
