import "./myMap.css";
import * as React from "react";
import * as turf from "@turf/turf";
import MapEvents from "./MapEvents";
import html2canvas from "html2canvas";
import SpeedLegend from "./SpeedLegend";
import DrawControl from "./drawControl";
import { fixed, kilometersToMiles } from "../Utils/Formats";
import "maplibre-gl/dist/maplibre-gl.css";
import map_layers from "./style/map_layers";
import { baseURL } from "../Api/instance.js";
import Loader from "../Common/Loader/Loader";
import NpsControl from "./npsLayerControl.js";
import AreaCalculator from "./drawCalculations";
import BasemapControl from "./basemapControl.js";
import { LngLatBounds, Popup } from "maplibre-gl";
import evSearch from "../../Images/ev_search.png";
import bsSearch from "../../Images/battery_search.png";
import reSearch from "../../Images/realestate_search.png";
import rneSearch from "../../Images/renewable_search.png";
import LayerToggleControl from './LayerToggleControl';
import { useSelector, useDispatch } from "react-redux";
import { osmDataColor } from "./style/gptLayerColors.js";
import style_oim_power from "./style/style_oim_power.js";
import { setFeatues } from "../Redux/reducers/datasetSlice";
import { links } from "../Common/MapFilterCard/layer.config";
import DeleteProjectModal from "../Modals/DeleteProjectModal";
import PolygonPopup from "../Modals/PolygonModal/PolygonPopup";
import style_oim_petroleum from "./style/style_oim_petroleum.js";
import { useState, useRef, useEffect, useCallback } from "react";
import { ToastNotification } from "../Utils/ToastNotifications.js";
import { GeospatialRiskLayers } from "./style/GeospatialRiskLayers.js";
import { setGptPrompt, setOsmData } from "../Redux/reducers/gptOsmSlice.js";
import { setSelectedSubstation } from "../Redux/reducers/selectedSubstation";
import PolygonFeatureModal from "../Modals/PolygonModal/PolygonFeatureModal";
import Map, { Marker, NavigationControl, Source, Layer } from "react-map-gl/maplibre";
import { setGeospatialLayers, setSelectedLayers } from "../Redux/reducers/selectedLayers";
import { setRightSideBarOpen, setMapImage, setTakeImage } from "../Redux/reducers/projectsSlice";
import { setDrawnLine, setDrawnPolygon, setFilterValues, setMapLoader, setCurrentCenter } from "../Redux/reducers/filterSlice";
import { setSubstations, setPlanningData, triggerClosestPoints, setPolygonCentroid } from "../Redux/reducers/substationSlice";
import { fetchClosestSubstations, fetchClosestLocalPlanningProjects, getLayers, getNearestNcpr, getGptOsmData, updateActiveLayers } from "../Api/API";

const MapArea = () => {
  const mapRef = useRef();

  const dispatch = useDispatch();
  
  const drawControlRef = useRef();

  const [line, setLine] = useState(null);

  const [idle, setIdle] = useState(false);

  const [mapZoom, setMapZoom] = useState(10);

  const [npsData, setNpsData] = useState({});
  
  const [visible, setVisible] = useState(true);
  
  const [showSat, setShowSat] = useState(false);
  
  const [mapWidth, setMapWidth] = useState(460);
  
  const [showNPS, setShowNPS] = useState(false);
  
  const [showCOD, setShowCOD] = useState(false);

  const [currentMode, setCurrentMode] = useState('');
  
  const [popupModal, setPopupModal] = useState(false);
  
  const [drawFeatures, setDrawFeatures] = useState({});
  
  const [showPrivate, setShowPrivate] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const filters = useSelector((state) => state.filters);

  const [getStations, setGetStations] = useState(false);

  const [vQInfralayers, setVQInfraLayers] = useState([]);

  const projects = useSelector((state) => state.projects);

  const [polygonPopup, togglePolygonPopup] = useState(false);

  const [selectedPolygonData, setPolygonData] = useState({});

  const substation = useSelector((state) => state.substations);
  
  const prompt = useSelector((state) => state.gptOsmData.prompt);
  
  const search_by = useSelector(state => state.filters.search_by);
  
  const drawnLine = useSelector(state => state.filters.drawnLine);

  const osmData = useSelector((state) => state.gptOsmData.osmData);
  
  const mapLoader = useSelector((state) => state.filters.mapLoader);
  
  const takeImage = useSelector((state) => state.projects.takeImage);
  
  const currentCenter = useSelector(state => state.filters.currentCenter);
  
  const activeLayers = useSelector((state) => state.dataset.activeLayers);
  
  const searchedPolygon = useSelector(state => state.filters.drawnPolygon);

  const substations = useSelector((state) => state.substations.substations);

  const userLocation = useSelector((state) => state.substations.userLocation);

  const selectedSubstation = useSelector((state) => state.selectedSubstation.substation);

  const activeRenewableLayers = useSelector((state) => state.dataset.activeRenewableLayers);

  const [viewport, setViewport] = useState({
    longitude: userLocation ? userLocation.lat : currentCenter ? currentCenter.lat : -0.1275862,
    latitude: userLocation ? userLocation.lng : currentCenter ? currentCenter.lng : 51.5072178,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  const myAPIKey = "irCAcfKXOE8yijupOexZaTBASOVGroLF";
  
  const myGoogleKey = "AIzaSyCQFqXwp4QZGkJuCQr6SjPwiTr8QHckksg";
  
  let hoveredStateId = null;
  
  const hoverPopup = new Popup({
    closeButton: false,
    closeOnClick: false,
    anchor: 'bottom',
    dynamicPosition: false,
});

  // const wimdLayer = {
  //   "id": "wimd",
  //   "type": "fill",
  //   "source": "wimd",
  //   "layout": {},
  //   "paint": {
  //     "fill-color": "#ef5350",
  //     "fill-outline-color": "#b71c1c",
  //     "fill-opacity": 0.5
  //   },
  // };

  const privateLandLayer = {
    "id": "private_land",
    "type": "fill",
    "source": "vqprivatelandmap",
    "source-layer": "private_land",
    "layout": {},
    "paint": {
      "fill-color": "#81c784",
      "fill-outline-color": "#1b5e20",
      "fill-opacity": 0.5
    },
  };

  const npsFillLayer = {
    "id": "nps",
    "type": "fill",
    "source": "vqnpsmap",
    "source-layer": "nps",
    "layout": {},
    "paint": {
      "fill-color": "#ffcc80",
      "fill-outline-color": "#ff6d00",
      "fill-opacity": 0.5
      // "fill-color": ["match", ["get", "tenure"],
      //   "Freehold", "#13b3e8", 
      //   "Leasehold", "#e83313",
      //   "#e83313"
      // ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#34c0eb", 
      //   "Leasehold", "#e83313",
      //   "#e83313"
      // ],
    },
  };

  // const npsLineLayer = {
  //   "id": "nps-outline-layer",
  //   "type": "line",
  //   "source": "nps",
  //   "layout": {},
  //   "paint": {
  //     'line-color': '#000',
  //     'line-width': 2,
  //   },
  // };

  // const codFillLayer = {
  //   id: "nps",
  //   type: "fill",
  //   source: "nps",
  //   layout: {},
  //   paint: {
  //     'fill-color': '#088',
  //     'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 0.5]
  //   },
  // };

  const ccodLayer = {
    "id": "ccod_nps",
    "type": "fill",
    "source": "cod",
    "source-layer": "ccod_nps",
    "paint": {
      "fill-opacity": 0.5,
      "fill-color": ["match", ["get", "Tenure"],
        "Freehold", "#34c0eb", 
        "Leasehold", "#e83313",
        "#e83313"
      ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#e65100", 
      //   "Leasehold", "#0d47a1",
      //   "#e83313"
      // ],
    }
  };

  const ocodLayer = {
    "id": "ocod_nps",
    "type": "fill",
    "source": "cod",
    "source-layer": "ocod_nps",
    "paint": {
      "fill-opacity": 0.5,
      "fill-color": ["match", ["get", "Tenure"],
        "Freehold", "#34c0eb", 
        "Leasehold", "#e83313",
        "#e83313"
      ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#ff9100", 
      //   "Leasehold", "#ba68c8",
      //   "#e83313"
      // ],
    }
  };

  // const wmsLayer = {
  //   id: "wms-geoserver-layer",
  //   type: "raster",
  //   source: "wms",
  //   paint: {},
  // };

  const _3dLayer = {
    "id": "OS/TopographicArea_2/Building/1_3D",
    "type": "fill-extrusion",
    "source": "esri",
    "source-layer": "TopographicArea_2",
    "filter": [
        "==",
        "_symbol",
        4
    ],
    "minzoom": 15,
    "layout": {},
    "paint": {
        "fill-extrusion-color": "#DCD7C6",
        "fill-extrusion-height": [
            "interpolate",
            [ "linear" ],
            [ "zoom" ],
            15,
            0,
            15.05,
            [ "get", "RelHMax" ]
        ],
        "fill-extrusion-opacity": [
            "interpolate",
            [ "linear" ],
            [ "zoom" ],
            15,
            0,
            16,
            0.9
        ]
    }
};

  const renewableLayer = {
    id: "wms-renewable-layers",
    type: "raster",
    source: "wmsRenewable",
    paint: {},
  };

  const mapStyle = {
    "version": 8,
    "sources": {
      "google-satellite": {
        "type": "raster",
        "tiles": ["https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=" + myGoogleKey],
        "tileSize": 256,
      }
    },
    "bounds": [
      -10.76418, 
      50.528423,
      1.9134116,
      60.331151
    ],
    "center": [
      -0.1275862,
      51.5072178,
      10
    ],
    "layers": [{
      "id": "google-satellite",
      "type": "raster",
      "source": "google-satellite",
      "layout": { 'visibility': 'visible' },
      "paint": { "raster-opacity": 1 },
      "minzoom": 0,
      "maxzoom": 22
    }],
    "sprite": "https://voltquant-images.s3.eu-west-2.amazonaws.com/vqSprites",
    "glyphs": "https://voltquant-images.s3.eu-west-2.amazonaws.com/vq-hackathon/fonts/{fontstack}/{range}.pbf",
    // "sprite": "https://api.os.uk/maps/vector/v1/vts/resources/sprites/sprite",
    // "glyphs": "https://api.os.uk/maps/vector/v1/vts/resources/fonts/{fontstack}/{range}.pbf",
  };

  const map_style = 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Light.json';

  const tegolaSource = {
    id: "mapLayers",
    type: "vector",
    tiles: ["https://backend.voltquant.uk/tegola/maps/vq_postgis/{z}/{x}/{y}.pbf?"]
  };

  const roadSpeedLayers = [
    "roadspeed_indicative",
    "roadspeed_avgday",
    "roadspeed_avgnight",
  ];

  const shouldRenderSpeedLegend = roadSpeedLayers.some((item) =>
    activeLayers.includes(item)
  );

  const shouldRenderRenewableLayers = activeLayers.includes("tidal-lagoon-renewable");
  
  const onUpdate = useCallback((e) => {
    setDrawFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
    const keys = Object.keys(e.features);
    const lastKey = keys[keys.length - 1];
    const geom = e.features[lastKey];
    const centroids = turf.centroid(geom);
    if (geom.geometry.type === 'Polygon') {
      dispatch(setDrawnPolygon(geom))
      dispatch(setPolygonCentroid({ lat: centroids?.geometry?.coordinates[1], lng: centroids?.geometry?.coordinates[0] }))
    } else if (geom.geometry.type === 'LineString') {
      dispatch(setDrawnLine(geom))
    }
  }, []);

  const onDelete = useCallback((e) => {
    setDrawFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
    const keys = Object.keys(e.features);
    const lastKey = keys[keys.length - 1];
    const geom = e.features[lastKey];
    if (geom.geometry.type === 'Polygon') {
      dispatch(setDrawnPolygon(null))
      dispatch(setPolygonCentroid(null))
    } else if (geom.geometry.type === 'LineString') {
      dispatch(setDrawnLine(null))
    }
  }, []);

  const findClosestSubstations = () => {
    const { userLocation } = substation;
    const {
      assetType,
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      showAllSubstations,
      search_by,
      searchValue
    } = filters;

    let body = {
      assetType,
      userLocation,
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      search_by,
      searched_location: searchValue,
      getAll: showAllSubstations === "true" ? true : false,
    };

    fetchClosestSubstations(body)
      .then((response) => {
        if (response.success) {
          dispatch(setSelectedSubstation(""));
          if (response?.data?.closestSubstations?.length > 0) {
            dispatch(setRightSideBarOpen(true))
          }
          // setZoomValue(response?.data?.zoomValue);
          dispatch(setSubstations(response?.data?.closestSubstations));
          dispatch(setMapLoader(false));
          dispatch(triggerClosestPoints(false));
          setGetStations(!getStations);
        } else {
          dispatch(setSubstations([]));
          dispatch(setMapLoader(false));
          dispatch(triggerClosestPoints(false));
          setGetStations(!getStations);
        }
      })
      .catch((err) => {
        dispatch(setSubstations([]));
        console.log(err);
      });
  };

  const findLocalPlanningClosestPoints = (substation) => {
    let body = {
      substation,
    };


    fetchClosestLocalPlanningProjects(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data?.closestProjects));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData([]));
        console.log(err);
      });
  };

  const findNearestNcpr = (substation) => {

    let body = {
      latitude: substation.Lattitude,
      longitude: substation.Longitute
    }

    getNearestNcpr(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data));
        } else {
          dispatch(setPlanningData({}));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData({}));
        console.log(err);
      });

  };

  const findGeoSpatialRiskLayers = ()=>{
    const body = {
      longitude: userLocation?.lng,
      latitude: userLocation?.lat,
      bufferRadius: 1,
      layers: GeospatialRiskLayers,
    };
    getLayers(body).then((response) => {
      if (response.success) {
        // filter response.data array and exculde these two values: "brownfield-land", "brownfield-site"
        const layers = response.data.filter(layer => !["brownfield-land", "brownfield-site"].includes(layer));
        dispatch(setGeospatialLayers(layers))
      }
    });
  };

  const handleClickMarker = (e, substation) => { 
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setFilterValues({
        source: substation?.Source,
        voltageLine: substation?.VoltageLine,
      })
    );
    dispatch(setSelectedSubstation(substation));
    if (filters.assetType === "EV Infrastructure") {
      findNearestNcpr(substation)
    } else {
      findLocalPlanningClosestPoints(substation);
    }
    dispatch(setFeatues([]));
  };
  
  const handlePolygonLayer = (data) => {
    setPopupModal(!popupModal);
    setNpsData(data);
  };
  
  const onMapLoad = () => {
    console.log("Inside OnLoad");
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (!map.hasImage("ncpr_image")) {
        map.loadImage(
          'https://voltquant-images.s3.eu-west-2.amazonaws.com/ev-Icon-filled.png',
          (error, image) => {
              if (error) throw error;
                map.addImage("ncpr_image", image);
            }
        );
      }
      if ( userLocation !== null && userLocation.lng !== undefined && userLocation.lat !== undefined ) {
        const latitude = userLocation.lat;
        const longitude = userLocation.lng;
        const zoom = 12;
        setViewport({ ...viewport, zoom, longitude, latitude });
        map.flyTo({ center: [longitude, latitude] });
      } else if (currentCenter === null && userLocation === null) {
        const newViewport = {
          ...viewport,
          latitude: -0.1275862,
          longitude: 51.5072178,
          };
          setViewport(newViewport);
          map.setCenter([-0.1275862, 51.5072178])
      } else if (currentCenter !== null &&  substations === null && userLocation === null) {
        const latitude = 51.5072178;
        const longitude = -0.1275862;
        const zoom = 10;
        setViewport({ ...viewport, zoom, longitude, latitude });
        map.flyTo({ center: [longitude, latitude] });
      }

      // map.on('click', 'nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("Nps title: ", e.features[0].properties);
      //     const title_number = e.features[0].properties.title_no;
      //     const record_status = e.features[0].properties.rec_status;
      //     const properties = {
      //       title_number,
      //       record_status
      //     };
      //     const data = {properties};
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'wimd', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("wimd: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'ccod_nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("ccod: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'ocod_nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("ocod: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('mouseenter', 'nps', (e) => {
      //     map.getCanvas().style.cursor = 'pointer';
      //     if (e.features.length > 0) {
      //       if (hoveredStateId) {
      //         map.removeFeatureState(
      //           {source: 'nps', sourceLayer: 'nps', id: hoveredStateId},
      //           {hover: false}
      //         );
      //       }
      //       hoveredStateId = e.features[0].id;
      //       map.setFeatureState({
      //         source: 'nps',
      //         sourceLayer: 'nps',
      //         id: e.features[0].id,
      //       }, {
      //         hover: true
      //       });
      //       const polygon = turf.polygon([e.features[0].geometry.coordinates[0]]);
      //       const centr = turf.centroid(polygon);
      //       const description = e.features[0].properties.title_no;
      //       hoverPopup.setLngLat(centr.geometry.coordinates).setHTML(description).addTo(map);
      //     }
      // });
      // map.on('mouseleave', 'nps', () => {
      //     map.getCanvas().style.cursor = '';
      //     hoverPopup.remove();
      //     if (hoveredStateId) {
      //       map.setFeatureState(
      //           {source: 'nps', sourceLayer: 'nps', id: hoveredStateId},
      //           {hover: false}
      //       );
      //     }
      //     hoveredStateId = null;
      // });
      // add3DBuildings(map);
    }
  };

  const handleDragEnd = () => {
    const newCenter = mapRef.current.getMap().getCenter();
    const newViewport = {
      ...viewport,
      latitude: newCenter.lat,
      longitude: newCenter.lng,
    };
    dispatch(setCurrentCenter(newCenter));
    setViewport(newViewport);
  };

  const onMapClick = (e) => {
    e.preventDefault();
  };

  const onMapIdle = () => {
    console.log("Map Idle detected", idle);
    if (idle === false) setIdle(true);
  };

  const handleConfirm = () => {
    const map = mapRef.current.getMap();
    deleteAllFromParent();
    if (currentMode === 'draw_polygon' && searchedPolygon) {
      dispatch(setDrawnPolygon(null));
      dispatch(setPolygonCentroid(null));
    } else if (currentMode === 'draw_line_string' && drawnLine) {
      dispatch(setDrawnLine(null));
    }
    setDrawFeatures({});
    setDeleteModal(false);
    drawControlRef.current.handleMode();
  };

  const handleCancel = () => {
    setDeleteModal(false);
    drawControlRef.current.handleMode();
  };

  const handleDraw = useCallback((e) => {
    if (e.mode === 'draw_polygon' && searchedPolygon) {
      setCurrentMode(e.mode);
      setDeleteModal(true);
    } else if (e.mode === 'draw_line_string' && drawnLine) {
      setCurrentMode(e.mode);
      setDeleteModal(true);
    }
  }, [searchedPolygon, drawnLine]);

  const deleteAllFromParent = () => {
    drawControlRef.current.deleteAllDrawings();
  };

  const exportMapAsPNG = (map) => {
      const target = document.getElementById("mapCanvas1");
      const controls = document.querySelector(".maplibregl-control-container");
      html2canvas(target, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        ignoreElements: (element) => controls.contains(element),
      }).then(async (canvas) => {
        const imgSrc = canvas.toDataURL();
        dispatch(setMapImage(imgSrc));
        dispatch(setTakeImage(false));
      });
  };

  const updateEnabledLayers = () => {
    let body = {
      updatedLayers: activeLayers
    }
    updateActiveLayers(body).then((res) => {
      console.log("Layers updated successfully")
    })
  };
  
  // useEffect(() => {
  //   console.log("Inside useEffect for map idle");
  //   if (mapRef.current) {
  //     const map = mapRef.current.getMap();
  //     setTimeout(()=>{
  //       add3DBuildings(map);
  //     }, 5000);
  //   }
  // }, [showSat]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [geojsonData, setGeojsonData] = useState(null);

  // useEffect(() => {
  //   // Fetch the GeoJSON data from S3
  //   const fetchGeoJSON = async () => {
  //     try {
  //       const response = await fetch('https://voltquant-images.s3.eu-west-2.amazonaws.com/wimd2019_merged.geojson');
  //       const data = await response.json();
  //       setGeojsonData(data);
  //       console.log("GeoJSON data fetched successfully");
  //     } catch (error) {
  //       console.error('Error fetching GeoJSON:', error);
  //     }
  //   };

  //   fetchGeoJSON();
  // }, []);
  
  useEffect(() => {
    if (mapRef.current && takeImage === true) {
      const map = mapRef.current.getMap();
      console.log("Calling exportMapAsPNG");
      exportMapAsPNG(map);
    }
  }, [takeImage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.on('draw.modechange', handleDraw);
    }
    return () => {
      const map = mapRef?.current?.getMap();
      map?.off('draw.modechange', handleDraw);
    }
  }, [drawFeatures, searchedPolygon, drawnLine]);

  useEffect(() => {
    const oim_layers = style_oim_power.concat(style_oim_petroleum);
    oim_layers.sort((a, b) => {
      if (a["zorder"] < b["zorder"]) return -1;
      if (a["zorder"] > b["zorder"]) return 1;
      return 0;
    });
    setVQInfraLayers(oim_layers);
  }, []);

  useEffect(() => {
    if (substations.length > 0 && mapRef.current) {
      const map = mapRef.current.getMap();
      let bounds = new LngLatBounds();
      substations.forEach((substation) => {
        bounds.extend([substation.Longitute, substation.Lattitude]);
      });
      map.fitBounds(bounds, { padding: 150 });
    }
  }, [substations, mapRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    findClosestSubstations();
  }, [substation.isFindClosestPoints]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects?.leftSidebarOpen && projects?.rightSidebarOpen) {
      setMapWidth(835);
    } else if (projects?.leftSidebarOpen && !projects?.rightSidebarOpen) {
      setMapWidth(460);
    } else if (!projects?.leftSidebarOpen && projects?.rightSidebarOpen) {
      setMapWidth(495);
    } else {
      setMapWidth(120);
    }
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.resize();
    }
  }, [projects?.leftSidebarOpen, projects?.rightSidebarOpen, mapRef.current]);

  useEffect(() => {
    if (
      selectedSubstation === null &&
      substations.length > 0 &&
      mapRef.current
    ) {
      const map = mapRef.current.getMap();
      let bounds = new LngLatBounds();
      substations.forEach((substation) => {
        bounds.extend([substation.Longitute, substation.Lattitude]);
      });
      map.fitBounds(bounds, { padding: 150 });
      // map.flyTo({ center: [viewport.longitude, viewport.latitude] })
    }
    if (selectedSubstation && userLocation) {
      const lineString = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              properties: {},
              coordinates: [
                [userLocation.lng, userLocation.lat],
                [selectedSubstation.Longitute, selectedSubstation.Lattitude],
              ],
            },
          },
        ],
      };
      setLine(lineString);
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        const coordinates = lineString.features[0].geometry.coordinates;
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new LngLatBounds(coordinates[0], coordinates[0]));
        map.fitBounds(bounds, {
          padding: 200,
        });
      }
    }
  }, [selectedSubstation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const delayedLoad = () => {
      if (mapRef.current) {
        onMapLoad();
      }
    };
    const timeoutId = setTimeout(delayedLoad, 10);
    return () => clearTimeout(timeoutId);
  }, [mapRef.current, userLocation, activeLayers, mapLoader]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (substation.substations.length > 0 || userLocation) {
      let listOfLayers = Object.values(links);
      const { userLocation, substations } = substation;
      const farthestSubstationDistance =
        substations[substations.length - 1]?.distance;
      listOfLayers = listOfLayers.concat(activeRenewableLayers)
      const body = {
        longitude: userLocation?.lng,
        latitude: userLocation?.lat,
        bufferRadius: farthestSubstationDistance + 2,
        layers: listOfLayers,
      };
      getLayers(body).then((response) => {
        if (response.success) {
          dispatch(setSelectedLayers(response.data));
        } else {
          dispatch(setSelectedLayers([]));
        }
      });
      findGeoSpatialRiskLayers()
    }
  }, [getStations]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (prompt && mapRef.current) {
      ToastNotification('info', "Searching Please wait..");
      const map = mapRef.current.getMap();;
      const bounds = map.getBounds();
      const location = "" + bounds.getSouthWest().lat + "," + bounds.getSouthWest().lng + "," + bounds.getNorthEast().lat + "," + bounds.getNorthEast().lng + "";
      const body = {
        location: location,
        prompt: prompt
      };
      getGptOsmData(body, { timeout: 100000 }).then((response) => {
        if (response.success) {
          if (response?.data.geojson?.features?.length > 0) {
            const newOsmData = [...osmData]
            const newObject = {
              geojson: response?.data?.geojson,
              name: response?.data?.layerName,
              show: true,
              color: osmDataColor[osmData?.length]
            }
            newOsmData.push(newObject)
            dispatch(setOsmData(newOsmData))
            ToastNotification('success', "Query Run Succesfully");
            ToastNotification('success', `No. of Features Found:${response?.data.geojson?.features?.length}`);
            dispatch(setGptPrompt(null))
          } else {
            ToastNotification('warning', 'No Features Found, Try Again');
            dispatch(setGptPrompt(null))
          }
        } else {
          ToastNotification('error', 'Failed search, report a bug and refresh page')
          dispatch(setGptPrompt(null))
        }
      });
    }
  }, [prompt]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeLayers?.length > 0) {
      updateEnabledLayers()
    }
  }, [activeLayers])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div id="mapCanvas" className="mapArea" style={{ padding: "20px" }}>
        {mapLoader ? (
          <Loader />
        ) : (
          <>
            {shouldRenderSpeedLegend && (
              <div className="legend-container">
                <SpeedLegend />
              </div>
            )}
            <Map
            id="mapCanvas1"
              ref={mapRef}
              reuseMaps={true}
              onLoad={onMapLoad}
              styleDiffing={true}
              onClick={onMapClick}
              onMapIdle={onMapIdle}
              onZoomEnd={() => setMapZoom(mapRef.current.getMap().getZoom())}
              // onMouseMove={onMouseMove}
              // onMouseLeave={onMouseLeave}
              // onMouseMove={onHover}
              // interactiveLayerIds={['nps']}
              initialViewState={viewport}
              onViewportChange={setViewport}
              style={{
                width: `calc(100vw - ${mapWidth}px)`,
                borderRadius: "20px",
                paddingRight: "5px",
                marginLeft: projects?.leftSidebarOpen ? "420px" : "80px",
                height: "calc(100vh - 106px)",
              }}
              minZoom={7}
              maxZoom={18}
              maxBounds={[
                [-10.76418, 49.528423],
                [1.9134116, 61.331151],
              ]}
              // mapStyle={mapStyle}
              mapStyle={showSat ? mapStyle : map_style}
              transformRequest={(url, resourceType) => {
                if (url.includes("voltquant-images"))
                  return {
                    url: url,
                  };
                if (url.includes("mapbox"))
                  return {
                    url: url,
                  };
                if (url.includes("vqinframap"))
                  return {
                    url: url,
                  };
                if (url.includes("vqprivatelandmap"))
                  return {
                    url: url,
                  };
                if (url.includes("vqnpsmap"))
                  return {
                    url: url,
                  };
                if (url.includes("/tegola/"))
                  return {
                    url: url,
                  };
                if (url.includes("/geoserver/"))
                  return {
                    url: url,
                  };
                if (url.includes("//mt1.google.com/"))
                  return {
                    url: url,
                  };
                if (!/[?&]key=/.test(url)) {
                  url += "?key=" + myAPIKey;
                  return {
                    url: url + "&srs=3857",
                  };
                }
                if (resourceType === 'Tile' && url.startsWith('https://api.os.uk/maps/raster/v1/zxy/Light_3857')) {
                  const match = url.match(/Light_3857\/(\d+)\/(\d+)\/(\d+)\.png/);
                  if (match) {
                    const z = match[1];
                    const x = match[2];
                    const y = match[3];
                    const proxyUrl = `${baseURL}/map/map-tiles/${z}/${x}/${y}.png`;
                    return { url: proxyUrl };
                  }
                }
              }}
            >
              <LayerToggleControl
                onMapLoad={onMapLoad}
                visible={visible}
                setVisible={setVisible}
              />
              <NavigationControl position={"top-left"} />
              <BasemapControl setShowSat={setShowSat} showSat={showSat} />
              <NpsControl mapZoom={mapZoom} setShowNPS={setShowNPS} showNPS={showNPS} setShowCOD={setShowCOD} showCOD={showCOD} showPrivate={showPrivate} setShowPrivate={setShowPrivate} />
              {/* {geojsonData && (
                <Source
                  id="wimd"
                  type="geojson"
                  data={geojsonData}
                >
                  <Layer {...wimdLayer} />
                </Source>
              )} */}
              {!showSat ? 
                <Source
                  id="3d-buildings"
                  type="vector"
                  url="https://api.os.uk/maps/vector/v1/vts"
                >
                  <Layer {..._3dLayer} />
                </Source>   : ''}
              <DrawControl
                position="top-left"
                ref={drawControlRef}
                onCreate={(e) => onUpdate(e)}
                onUpdate={(e) => onUpdate(e)}
                onDelete={onDelete}
              />
              {searchedPolygon || drawnLine ?  <AreaCalculator /> : ''}
              {showCOD && (
                <Source
                  id="cod"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqnpsmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...ocodLayer} />
                  <Layer {...ccodLayer} />
                </Source>
              )}
              {showPrivate && (
                <Source
                  id="privateLand"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqprivatelandmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...privateLandLayer} />
                </Source>
              )}
              {showNPS && (
                <Source
                  id="nps"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqnpsmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...npsFillLayer} />
                  {/* <Layer {...npsLineLayer} /> */}
                </Source>
              )}
              {map_layers.map((lyr, key)=>{
                if (activeLayers.includes(lyr.id)){
                  let beforeLayerId = null;
                  const index = activeLayers.indexOf(lyr.id);
                  if (index !== activeLayers.length - 1 && mapRef.current) {
                    const map = mapRef.current.getMap();
                    // if (activeLayers.includes("National_Charge_Point_Register")){
                    if (map.hasImage("ncpr_image") === false) {
                      map.loadImage(
                        'https://voltquant-images.s3.eu-west-2.amazonaws.com/ev-Icon-filled.png',
                        (error, image) => {
                            if (error) throw error;
                              map.addImage('ncpr_image', image);
                          }
                      );
                    }
                    // }
                    if (map.getLayer(activeLayers[index + 1])) {
                      beforeLayerId = activeLayers[index + 1];
                    }
                  }
                  if (showSat === true) {
                    if (beforeLayerId !== null) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                    } else {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                    }
                  } else {
                    const map = mapRef?.current?.getMap();
                    if (beforeLayerId !== null) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                    } else if(map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={"OS/Railway_stations/Light Rapid Transit Station"} key={key} /></Source>;
                    } else {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                    }
                  }
                }
              })}
              {/* {activeLayers?.length > 0 && (
                <Source
                  id="wms"
                  type="raster"
                  tileSize={256}
                  tiles={[
                    `https://backend.voltquant.uk/geoserver/volt-quant/wms?service=WMS&version=1.1.1&request=GetMap&layers=${activeLayers?.join(
                      ","
                    )}&styles=&format=image/png&transparent=true&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}`,
                  ]}
                >
                  <Layer {...wmsLayer} />
                </Source>
              )} */}
              {visible && (
                <Source
                  id="vqinframap"
                  type="vector"
                  url="https://voltquant-images.s3.eu-west-2.amazonaws.com/infraMap.json"
                  sprite="https://voltquant-images.s3.eu-west-2.amazonaws.com/vqSprites"
                  glyphs="https://voltquant-images.s3.eu-west-2.amazonaws.com/vq-hackathon/fonts/{fontstack}/{range}.pbf"
                >
                  {vQInfralayers.map((layer) => {
                    if (showSat === true) {
                      return <Layer {...layer} />;
                    } else {
                      const map = mapRef?.current?.getMap();
                      if(map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")){
                        return <Layer {...layer} beforeId={"OS/Railway_stations/Light Rapid Transit Station And London Underground Station"}/>;
                      } else {
                        return <Layer {...layer} />;
                      }
                    }
                  })}
                </Source>
              )}
              {activeLayers?.length > 0 || osmData?.length > 0 || visible || showNPS || showCOD ? <MapEvents showNPS={showNPS} showCOD={showCOD} showPrivate={showPrivate} /> : ''}
              {!selectedSubstation && (
                <>
                  {substations?.map((substation, index) => {
                    const isSelectedSubstation =
                      selectedSubstation?.SubstationID ===
                      substation?.SubstationID;
                    const isRedIcon =
                      substation?.generationExtraCapacity < 0 ||
                      substation?.demandExtraCapacity < 0;
                    return (
                      <Marker
                        key={index}
                        longitude={Number(substation?.Longitute)}
                        latitude={Number(substation?.Lattitude)}
                      >
                        <div
                          className={
                            isSelectedSubstation
                              ? isRedIcon
                                ? "redMarker"
                                : "greenMarker"
                              : isRedIcon
                                ? "redMarker"
                                : "greenMarker"
                          }
                          onClick={(e) => handleClickMarker(e, substation)}
                        ></div>
                      </Marker>
                    );
                  })}
                </>
              )}
              {userLocation && (
                <Marker
                  longitude={userLocation.lng}
                  latitude={userLocation.lat}
                  // anchor="center"
                  offset={[0, -91 / 3.8]}
                >
                  <img
                    src={
                      filters?.assetTypeUrl ===
                        "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/ev_search.png"
                        ? evSearch
                        : filters?.assetTypeUrl ===
                          "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/battery_search.png"
                          ? bsSearch
                          : filters?.assetTypeUrl ===
                            "https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/renewable_search.png"
                            ? rneSearch
                            : reSearch
                    }
                    alt="Search location"
                  />
                  {/* <img src={filters?.assetTypeUrl} alt="asset type" /> */}
                </Marker>
              )}
              {selectedSubstation && (
                <>
                  <Marker
                    longitude={Number(selectedSubstation?.Longitute)}
                    latitude={Number(selectedSubstation?.Lattitude)}
                    anchor="center"
                  >
                    <div
                      id="selectedSubstation"
                      className={
                        selectedSubstation?.generationExtraCapacity < 0 ||
                          selectedSubstation?.demandExtraCapacity < 0
                          ? "redMarker"
                          : "greenMarker"
                      }
                    ></div>
                  </Marker>
                </>
              )}
              {userLocation && searchedPolygon && search_by === 'Draw Polygon' && (
                <Source type="geojson" data={searchedPolygon}>
                  <Layer
                    id={`aapolygon`}
                    type="fill"
                    paint={{
                      'fill-color': `#b0f542`,
                      'fill-opacity': 0.6,
                    }}
                  />
                </Source>
              )}
              {userLocation && drawnLine && (
                <Source type="geojson" data={drawnLine}>
                  <Layer
                    id={`aaLine`}
                    type="line"
                    paint={{
                      "line-width": 4,
                      "line-color": "#008000",
                    }}
                  />
                </Source>
              )}
              {userLocation && selectedSubstation && line && (
                <>
                  <Source type="geojson" data={line}>
                    <Layer

                      id="lineLayer"
                      type="line"
                      paint={{
                        "line-width": 4,
                        "line-color": "#008000",
                      }}
                    />
                  </Source>
                  <Marker
                    longitude={
                      (userLocation.lng +
                        Number(selectedSubstation.Longitute)) /
                      2
                    }
                    latitude={
                      (userLocation.lat +
                        Number(selectedSubstation.Lattitude)) /
                      2
                    }
                    offset={[0, -91 / 2]}
                  >
                    <div id="userLocation" className="lineMarker">
                      {`${filters?.radiusUnit === "Miles" ? fixed(kilometersToMiles(selectedSubstation?.distance), 2) : fixed(selectedSubstation?.distance, 2)} ${filters?.radiusUnit === "Miles"
                        ? filters?.radiusUnit
                        : "km"
                        }`}
                    </div>
                  </Marker>
                </>
              )}
              {/* {shouldRenderRenewableLayers &&
                activeRenewableLayers?.length > 0 && (
                  <Source
                    id="wmsRenewable"
                    type="raster"
                    tileSize={256}
                    tiles={[
                      `https://backend.voltquant.uk/geoserver/volt-quant/wms?service=WMS&version=1.1.1&request=GetMap&layers=${activeRenewableLayers?.join(
                        ","
                      )}&styles=&format=image/png&transparent=true&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}`,
                    ]}
                  >
                    <Layer {...renewableLayer} />
                  </Source>
                )} */}
              {(shouldRenderRenewableLayers &&
                activeRenewableLayers?.length > 0 ) ? (
                 map_layers.map((lyr, key)=>{
                  if (activeRenewableLayers.includes(lyr.id)){
                    let beforeLayerId = null;
                    const index = activeRenewableLayers.indexOf(lyr.id);
                    if (index !== activeRenewableLayers.length - 1 && mapRef.current) {
                      const map = mapRef.current.getMap();
                      if (map.getLayer(activeRenewableLayers[index + 1])) {
                        beforeLayerId = activeRenewableLayers[index + 1];
                      }
                    }
                    if (showSat === true) {
                      if (beforeLayerId !== null) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                      } else {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                      }
                    } else {
                      const map = mapRef?.current?.getMap();
                      if (beforeLayerId !== null) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                      } else if(map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={"OS/Railway_stations/Light Rapid Transit Station"} key={key} /></Source>;
                      } else {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                      }
                    }
                  }
                })
                ) : ('')}
              {
                osmData.length > 0 && osmData.map((item, i) => {
                  if (item.show) {
                    return (
                      <Source key={i} type="geojson" data={item.geojson}>
                        <Layer

                          filter={['==', '$type', 'LineString']}
                          id={item.name}
                          type="line"
                          paint={{
                            'line-color': `${item.color}`,
                            'line-width': 3,
                          }}
                        />
                        <Layer
                          id={`${item.name}point`}
                          type='circle'
                          paint={{
                            'circle-radius': 4,
                            'circle-stroke-width': 0.5,
                            'circle-color': `${item.color}`,
                            'circle-stroke-color': 'black'
                          }}

                          filter={['==', '$type', 'Point']}
                        />
                        <Layer
                          id={`${item.name}polygon`}
                          type="fill"
                          paint={{
                            'fill-color': `${item.color}`,
                            'fill-opacity': 0.6,
                          }}
                          filter={['==', '$type', 'Polygon']}

                        />
                      </Source>
                    )
                  }
                })
              }
            </Map>
          </>
        )}
      </div>
      <PolygonFeatureModal
        open={popupModal}
        setOpen={setPopupModal}
        data={npsData}
      />
      <PolygonPopup
        open={polygonPopup}
        setOpen={togglePolygonPopup}
        data={selectedPolygonData}
        topPosition={0}
        leftPosition={0}
      />
      <DeleteProjectModal
        open={deleteModal}
        setOpen={setDeleteModal}
        onClose={handleCancel}
        handleDetele={handleConfirm}
        title={`Delete existing ${currentMode === 'draw_line_string' ? 'line' : 'polygon'} drawing?`}
        text={`Currently only one ${currentMode === 'draw_line_string' ? 'line' : 'polygon'} can be drawn at a time, are you sure you want to delete the previous drawing and start a new one?`}
      />
    </>
  );
};

export default React.memo(MapArea);
