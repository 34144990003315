import React, { useEffect } from "react";
import AppRoutes from "./Routes/index";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import HotjarScript from "./Components/Utils/Scripts/Hotjar";
import ClickyScript from "./Components/Utils/Scripts/Clicky";
import GoogleAnalyticsScript from "./Components/Utils/Scripts/GoogleAnalytics";
import SocketService from "./Socket/socket";
import { useDispatch } from "react-redux";
import {
  setNewOneToOneMessage,
  setNewGroupMessage,
  setNewCompanyGroupMessage,
  setOnlineUsers,
  setNewNotification
} from "./Components/Redux/reducers/chatSlice";
import { ToastNotification } from "./Components/Utils/ToastNotifications";
import { useSelector } from "react-redux";

function App() {
  const { userDetail } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  // const fetchNewMessage = (message) => {
  //   const messageBody = {
  //     content: message,
  //     createdAt: new Date().toISOString(),
  //     id: Number(userMessages[userMessages.length - 1].id) + 1,
  //     read: false,
  //     recipientId: userDetail.user.id,
  //     senderId: selectedUserChat.userId,
  //     url: null,
  //   };
  //   dispatch(appendUserMessages(messageBody));
  // };
  useEffect(() => {
    SocketService.init((data) => {
      console.log("dataa", data);
      if (data?.newMessage) {
        console.log("newMessage", data?.newMessage);
        dispatch(setNewOneToOneMessage(data?.newMessage));
      }
      if (data?.receiveGroupMessage) {
        if (userDetail?.hasOwnProperty('user') && userDetail?.user?.id !== data?.receiveGroupMessage?.groupMessage?.senderId) {
          console.log("receiveGroupMessage", data?.receiveGroupMessage);
          dispatch(setNewGroupMessage(data?.receiveGroupMessage));
        } else {
          dispatch(setNewGroupMessage(null));
        }
      }
      if (data?.receiveCompanyGroupMessage) {
        if (userDetail?.hasOwnProperty('user') && userDetail?.user?.id !== data?.receiveCompanyGroupMessage?.companyGroupMessage?.senderId) {
          console.log("receiveCompanyGroupMessage", data?.receiveCompanyGroupMessage);
          dispatch(setNewCompanyGroupMessage(data?.receiveCompanyGroupMessage));
        } else {
          dispatch(setNewGroupMessage(null));
        }
      }
      dispatch(setOnlineUsers(data?.onlineUsers));
      if (data?.invitation?.hasOwnProperty('message')) {
        dispatch(setNewNotification(true));
        ToastNotification("info", data?.invitation?.message, "bottom-right");
      }

      if (data?.invitationResponse?.hasOwnProperty('message')) {
        dispatch(setNewNotification(true));
        ToastNotification("info", data?.invitationResponse?.message, "bottom-right");
      }
      // if (data?.message) {
      //   fetchNewMessage(data);
      // }
    }); // socket Connection and Listing all the events
    // return () => {
    //   console.log("working disconnect")
    //   SocketService.disconnect();
    // }
  }); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AppRoutes />
      <ToastContainer />
      <HotjarScript />
      <ClickyScript />
      <GoogleAnalyticsScript />
    </>
  );
}

export default App;
