import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";

const DeleteCollectionsModal = (props) => {
  const { open, setOpen, handleDelete } = props;
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.deleteContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Delete Collection?</h1>
            <Close onClick={() => setOpen(false)} />
          </div>
          <p className={styles.para}>Please Select any one of them</p>
          <div style={{ width: "500px", marginTop: "5px" }}></div>

          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              width="100%"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text=" Delete all projects in this Collection"
              handleClick={()=>handleDelete(false)}
            />

            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              width="100%"
              radius="100px"
              borderColor="#1F443A"
              text="Move Projects to Default Collection"
              handleClick={()=>handleDelete(true)}

            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DeleteCollectionsModal;
