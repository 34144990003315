export const osmDataColor = [
    '#ff0000',
    '#00bfff',
    '#4000ff',
    '#ff00ff',
    '#00ff00',
    '#ffff00',
    '#808080',
    '#330000',
    '#83c586',
    '#d55802',
    '#ff837f',
    '#636700',
    '#f5c25d',
    '#6670d2',
    '#456783',
    '#38ff6d',
    '#098899',
    '#a281fc',
    '#694e8a'
  ]

  export const powerline =  [
    'power_line',
    'power_tower',
    'power_substation',
    'power_substation_point',
    'power_plant',
    'power_plant_point',
    'power_generator',
    'power_generator_area',
    'power_transformer',
    'power_compensator',
    'power_switch',
    'telecoms_communication_line',
    'telecoms_data_center',
    'telecoms_mast',
    'petroleum_pipeline',
    'petroleum_well',
    'petroleum_site',
    'water_pipeline',
    "power_line_case",
    "power_line_underground_2",
    "power_line_underground_1",
    "power_line_2",
    "power_line_1",
    "petroleum_pipeline_case",
    "power_substation_label",
    "power_line_ref",
    "power_pole",
    "power_plant_label"]