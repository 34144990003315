import React from "react";
import AppButton from "../../Common/Button/AppButton";
import styles from "./styles.module.scss";

const FilterButton = ({ handleFiltersButtonClick, filterButtonsArray, filterButtonText }) => {
  return (
    <div className={styles.filer_buttons}>
      {filterButtonsArray.map((item) => {
        return (
          <AppButton
            variant="large"
            key={item.id}
            text={item.text}
            onClick={() => handleFiltersButtonClick(item.text)}
            isSelected={item.text === filterButtonText ? true : false}
            id={item.id}
          />
        );
      })}
    </div>
  );
};

export default FilterButton;
