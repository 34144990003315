import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginIcon from "@mui/icons-material/Login";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import Button from "@mui/material/Button";
import logo from "./../../Images/Logo1.svg";
import greenlogo from "./../../Images/green-logo.svg";
import SheduleMeeting from "../../Components/Modals/SheduleMeeting";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://voltquant.co.uk/">
        Voltquant
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1F443A", // Green color of figma design
    },
    secondary: {
      main: "#FDFFFD", // Green color of figma design
    },
    primaryColor: "#1F443A", // Green color of figma design
    secondaryColor: "#FDFFFD", // Off white color of figma design
    appButtonOutlineColor: "#A0AEC0", // Grey color for outline
  },
  typography: {
    fontFamily: "Eudoxus sans",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
  },
});

const SubmitRequest = () => {
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    window.location.href = "/";
    window.localStorage.clear();
  };

  return (
    <div>
      <ThemeProvider theme={createTheme(defaultTheme, customTheme)}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#FDFFFD",
              // height: "300px",
              // width: "1200px",
              borderRadius: 2,
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 70,
                width: 70,
                mt: 5,
                mb: 5,
              }}
              alt="voltquant logo"
              src={greenlogo}
            />
            <Typography component="h1" variant="h4">
              Welcome to our Page
            </Typography>
            <Typography
              component="subtitle1"
              variant="subtitle1"
              sx={{ mt: 1 }}
            >
              Please submit request to access the content.
            </Typography>
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{ mt: 4, mb: 2 }}
                endIcon={<LoginIcon />}
              >
                Schedule Meeting
              </Button>
              <Button
                variant="contained"
                onClick={handleLogout}
                sx={{ mt: 4, mb: 2, ml: 2 }}
                startIcon={<LogoutSharpIcon />}
              >
                Logout
              </Button>
            </div>
            <Box component="form" noValidate sx={{ mt: 5 }}></Box>
          </Box>
          <Copyright sx={{ mt: 15 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 2,
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img src={logo} alt="Logo" />
          </Box>
        </Container>
      </ThemeProvider>
      <SheduleMeeting open={open} setOpen={setOpen} />
    </div>
  );
};

export default SubmitRequest;
