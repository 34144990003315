import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Close } from "../../SVG";
import Button from "../../Common/Button/Button";
import { useSelector } from "react-redux";
import { ToastNotification } from "../../Utils/ToastNotifications";
import styles from "./styles.module.scss";
import { updateProject } from "../../Api/API";
import TextInput from "../../Common/TextInput";
import TextArea from "../../Common/TextArea";

const EditProjectModal = (props) => {
  const { projectData, open, setOpen, getAllProjects } = props;
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");

  const { userDetail } = useSelector((state) => state.users);

  const handleClose = (e) => {
    setOpen(false);
    setName("");
    setComments("");
    e.stopPropagation();
  };

  useEffect(() => {
    setComments(projectData?.comment);
    setName(projectData?.projectName);
  }, [projectData, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveProject = () => {
    if (name.trim() === "") {
      ToastNotification("warn", "Project name is required");
      return;
    } else if (comments.trim() === "") {
      ToastNotification("warn", "Project detail is required");
      return;
    } else {
      let body = {
        projectName: name,
        comment: comments,
      };
      updateProject(projectData?.id, body).then((res) => {
        if (res?.success) {
          setOpen(false);
          getAllProjects(projectData.collectionId);
          setName("");
          setComments("");
          ToastNotification("success", "Project saved");
        }
      });
      // }
    }
  };
  function convertDateFormat(dateString) {
    const dateParts = dateString?.split("-"); // split the date into [YYYY, MM, DD]
    return `${dateParts?.[2]}/${dateParts?.[1]}/${dateParts?.[0]}`; // rearrange to MM/DD/YYYY
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.saveContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.date}>{convertDateFormat(projectData?.date)}</h1>
            <Close onClick={handleClose} />
          </div>
          <h1 className={styles.heading}>Edit Project</h1>
          <p className={styles.para}>
            {`Save Changes as "${userDetail?.user?.first_name}"`}
          </p>
          <TextInput
            type="text"
            value={name}
            setValue={setName}
            placeholder="Project Title"
          />
          <TextArea
            value={comments}
            setValue={setComments}
            placeholder="Description"
          />
          <div className={styles.buttonContainer}>
            <Button
              height="40px"
              bgColor="#F3F4F6"
              color="#1E232E"
              radius="100px"
              borderColor="#F3F4F6"
              text="Cancel"
              handleClick={handleClose}
            />
            <Button
              height="40px"
              bgColor="#1F443A"
              color="#fff"
              radius="100px"
              borderColor="#1F443A"
              text="Save Project"
              handleClick={handleSaveProject}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default EditProjectModal;
