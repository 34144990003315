import React from "react";
import Header from "../Header";
import RightSidebar from "../Sidebar/RightSidebar";
import LeftSidebar from "../Sidebar/LeftSidebar/LeftSidebar";

const MapWrapper = ({ children }) => {

  return (
    <>
      <div>
        <Header />
        <div>
          <LeftSidebar />
        </div>
        <div>{children}</div>
        <div>
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default MapWrapper;
