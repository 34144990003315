import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Button from "../../Components/Common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastNotification } from "../../Components/Utils/ToastNotifications";
import {
  setProjects,
  setProjectsLoading,
} from "../../Components/Redux/reducers/projectsSlice";
import Filters from "../../Components/Projects/Filters";
import Loader from "../../Components/Common/Loader/Loader";
import {
  getSharedByYouProjects,
  getSharedWithYouProjects,
  getSharedWithUrlProjects
} from "../../Components/Api/API";
import styles from "./styles.module.scss";
import ProjectCard from "../../Components/ProjectCard";

const SharedProjects = () => {
  const [searchByName, setSearchByName] = useState("");
  const [columnView, setColumnView] = useState(true);
  const [listView, setListView] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState("None");
  const [selectedAlphabeticSorting, setSelectedAlphabeticSorting] =
    useState("None");
  const [selectedProject, setSelectedProject] = useState();

  const [headromType, setHeadromType] = useState("None");
  const [status, setStatus] = useState("None");
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");
  const [minRadius, setMinRadius] = useState("");
  const [maxRadius, setMaxRadius] = useState("");

  const [buttonText, setButtonText] = useState("Shared By You");
  const [pageNumber, setPageNumber] = useState(1);

  const { projects, projectsLoading } = useSelector((state) => state.projects);

  const buttonArray = ["Shared By You", "Shared With You", "Shared via Links"];

  const dispatch = useDispatch();

  const getAllSharedByYouProjects = (pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius) => {
    dispatch(setProjectsLoading(true));
    getSharedByYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius).then((res) => {
      dispatch(setProjects([]));
      if (res.code === 400) {
        ToastNotification("error", "You are currently sharing 0 projects...!");
        dispatch(setProjectsLoading(false));
      }
      if (res.success) {
        if (res?.data?.projects?.length === 0) {
          ToastNotification("error", "You are currently sharing 0 projects.");
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };
  const getAllSharedWithYouProjects = (pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius) => {
    dispatch(setProjectsLoading(true));
    getSharedWithYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius).then((res) => {
      dispatch(setProjects([]));
      if (res.code === 400) {
        ToastNotification("error", "No projects are currently shared with you.");
        dispatch(setProjectsLoading(false));
      }
      if (res.success) {
        if (res?.data?.projects?.length === 0) {
          ToastNotification("error", "No projects are currently shared with you.");
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  const getAllSharedWithUrlProjects = (pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius) => {
    dispatch(setProjectsLoading(true));
    getSharedWithUrlProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius).then((res) => {
      dispatch(setProjects([]));
      if (res.code === 400) {
        ToastNotification("error", "No projects are currently shared with url.");
        dispatch(setProjectsLoading(false));
      }
      if (res.success) {
        if (res?.data?.projects?.length === 0) {
          ToastNotification("error", "No projects are currently shared with url.");
          dispatch(setProjectsLoading(false));
        } else if (res?.data?.projects?.length > 0) {
          ToastNotification("success", res.message);
          dispatch(setProjects(res?.data));
          dispatch(setProjectsLoading(false));
        }
      }
    });
  };

  const handleSharedByUrlProjects = () => {
    getAllSharedWithUrlProjects(
      pageNumber,
      searchByName,
      selectedAssetType,
      selectedAlphabeticSorting,
      headromType,
      status,
      minCapacity,
      maxCapacity,
      minRadius,
      maxRadius
    );
  };

  useEffect(() => {
    if (buttonText === "Shared By You") {
      getAllSharedByYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
    }
    if (buttonText === "Shared With You") {
      getAllSharedWithYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
    }
    if (buttonText === "Shared via Links") {
      getAllSharedWithUrlProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
    }
  }, [buttonText, pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFiltersProject = () => {
    if (searchByName?.length > 0 || selectedAssetType?.length > 0 || selectedAlphabeticSorting?.length > 0) {
      if (buttonText === "Shared By You") {
        getAllSharedByYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
      }
      if (buttonText === "Shared With You") {
        getAllSharedWithYouProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
      }
      if (buttonText === "Shared via Links") {
        getAllSharedWithUrlProjects(pageNumber, searchByName, selectedAssetType, selectedAlphabeticSorting, headromType, status, minCapacity, maxCapacity, minRadius, maxRadius);
      }
    } else {
      ToastNotification("error", "Please enter any filter");

    }
  }

  useEffect(() => {
    setFilteredProjects(projects?.projects);
  }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDateString = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1.
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getDaysLeft = (expirationDate) => {
    const now = moment();
    const expiry = moment(expirationDate);
    const daysRemaining = expiry.diff(now, 'days');
    if (daysRemaining === 0) {
      return 'Last Day';
    } else if (daysRemaining === 1) {
      return '1 Day Left';
    } else {
      return `${daysRemaining} Days Left`;
    }
  };

  return (
    <div className={styles.projectContainer}>
      <div className={styles.projects} >
        <h1 className={styles.projectContainer__heading}>Shared Projects</h1>
        <div className={styles.shareButtonContainer}>
          {buttonArray?.map((item, index) => {
            return (
              <Button
                key={index}
                height="45px"
                bgColor={item === buttonText ? "#F3F4F6" : "#fff"}
                color={item === buttonText ? "#1F443A" : "#1E232E"}
                radius="100px"
                borderColor={item === buttonText ? "#F3F4F6" : "#DCDFE5"}
                text={item}
                handleClick={() => setButtonText(item)}
              />
            );
          })}
          {/* <Button height='45px' bgColor='#fff' color='#1F443A' radius='100px' borderColor='#1F443A' text='Shared With You' /> */}
        </div>
        {/* <TabButtons getAllCollection={getAllCollection} /> */}
        <Filters
          searchByName={searchByName}
          setSearchByName={setSearchByName}
          setSelectedAssetType={setSelectedAssetType}
          selectedAssetType={selectedAssetType}
          selectedAlphabeticSorting={selectedAlphabeticSorting}
          setSelectedAlphabeticSorting={setSelectedAlphabeticSorting}
          handleFiltersProject={handleFiltersProject}
          headromType={headromType}
          setHeadromType={setHeadromType}
          status={status}
          setStatus={setStatus}

          minCapacity={minCapacity}
          setMinCapacity={setMinCapacity}
          maxCapacity={maxCapacity}
          setMaxCapacity={setMaxCapacity}
          minRadius={minRadius}
          setMinRadius={setMinRadius}
          maxRadius={maxRadius}
          setMaxRadius={setMaxRadius}

          columnView={columnView}
          setColumnView={setColumnView}
          listView={listView}
          setListView={setListView}
        />
        <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
          {projectsLoading ? (
            <Loader />
          ) : (
            <>
              {projects?.projects?.length > 0 ? (
                filteredProjects?.map((project, index) => {
                  const mapedDate = formatDateString(project?.date);
                  const remainingDays = getDaysLeft(project?.linkExpiry);
                  return (
                    <Grid item xs={4} key={project.id}>
                      <ProjectCard
                        project={project}
                        mapedDate={mapedDate}
                        showButton={false}
                        link={buttonText === "Shared via Links" ? true : false}
                        remainingDays={remainingDays}
                        getAllSharedWithUrlProjects={handleSharedByUrlProjects}
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box sx={{ textAlign: "center", width: "100%", p: 4 }}>
                  <Typography variant="h5">No shared projects</Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
      </div>
      <div className={styles.pagination}>
        {projects?.total > 9 && (
          <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Pagination
              page={pageNumber}
              count={Math.ceil((projects?.total || 0) / 9)}
              size="large"
              onChange={(event, value) => setPageNumber(value)}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#1F443A !important",
                  color: "white",
                },
              }}
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default SharedProjects;
