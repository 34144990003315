import React from 'react';
import styles from './styles.module.scss';
import TableComponent from './TableComponent';
import Button from '../../Components/Common/Button/Button';
import { ArrowForward } from '../../Components/SVG';

const MappingProject = (props) => {
    const { csvData, count, setCount } = props;

    return (
        <div className={styles.recommendation}>
            <h3 className={styles.projectMapping}>Project Mapping</h3>
            <p className={styles.para}>Your file has been automatically mapped. A demo record is available for your review below</p>
            <div style={{ paddingTop: '24px', width: '100%' }}>
                <TableComponent
                    projects={csvData?.records}
                />
                <div className={styles.buttonMainContainer} style={{marginTop: '-30px'}}>
                    <Button
                        height="40px"
                        bgColor="#1F443A"
                        color="#fff"
                        radius="100px"
                        borderColor="#1F443A"
                        text="Continue"
                        icon1={<ArrowForward fill="#fff"/>}
                        // disable={disable}
                        handleClick={() => setCount(count + 1)}
                    />
                </div>
            </div>
        </div>
    );
};

export default MappingProject;
