import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LayersData from "./LayersData";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import MapLayersButtons from "../MapLayersButtons/MapLayersButtons";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { links } from "./layer.config";
import { layers } from "./layerCard.config";
import { Slide } from "@mui/material";

export default function LayersModal({ open, setOpen }) {
  const [buttonText, setButtonText] = useState("Active");
  const [callUpdatedLayers, setCallUpdatedLayers] = useState(false);
  const [buttonId, setButtonId] = useState("section1");
  const [categorizedLayers, setCategorizedLayers] = useState([]);
  const [layerButtons, setLayersButton] = useState([]);
  const filters = useSelector((state) => state.filters);
  const { activeLayers } = useSelector((state) => state.dataset);
  const selectedLayers = useSelector(
    (state) => state.selectedLayers.selectedLayers
  );
  const substation = useSelector((state) => state.substations);
  const autoLayers = useSelector((state) => state.filters.enableLayers);

  const buttonsArray = [
    { id: "ACTIVE", text: "Active" },
    { id: "ENGLAND", text: "England" },
    { id: "SCOTLAND", text: "Scotland" },
    { id: "WALES", text: "Wales" },
    { id: "EV", text: "EV" },
    { id: "Data_Centres", text: "Data Centres" },
    { id: "NATIONAL_GRID", text: "National Grid" },
    { id: "NATIONAL_GAS", text: "National Gas" },
    { id: "UKPN", text: "DNO:UKPN" },
    { id: "SSEN", text: "DNO:SSEN" },
    { id: "NPG", text: "DNO:NPG" },
    { id: "NGED", text: "DNO:NGED" },
    { id: "ENWL", text: "DNO:ENWL" },
  ];
  const style = {
    position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 2,
  };

  const sectionRefs = {
    ACTIVE: useRef(null),
    ENGLAND: useRef(null),
    SCOTLAND: useRef(null),
    WALES: useRef(null),
    EV: useRef(null),
    Data_Centres: useRef(null),
    NATIONAL_GRID: useRef(null),
    NATIONAL_GAS: useRef(null),
    UKPN: useRef(null),
    SSEN: useRef(null),
    NPG: useRef(null),
    NGED: useRef(null),
    ENWL: useRef(null),
  };

  //All those layers which is not active are "final layers"
  const allLayers = layers.filter(
    (item) => !activeLayers?.includes(links[item.name])
  );

  // Extracting names from the layers Config
  const namesOfSelectedLayers = Object.entries(links)?.map(([key, value]) => {
    if (selectedLayers?.includes(value)) {
      return key;
    }
  });

  const finalSelectedLayers = allLayers.filter((item) =>
    namesOfSelectedLayers.includes(item.name)
  );

  // Making data after check that, is user comes with search or without search
  const categorizeLayers = (layers, categories, substation, autoLayers) => {
    const categorizedLayers = {};

    for (const category of categories) {
      categorizedLayers[category] =
        substation.userLocation && autoLayers
          ? finalSelectedLayers.filter((item) => item.category === category)
          : layers.filter((layer) => layer.category === category);
    }
    const filteredLayerButtons = buttonsArray.filter(
      (button) =>
        categorizedLayers[button.id.replace("_", " ")] &&
        categorizedLayers[button.id.replace("_", " ")].length > 0
    );
    const updatedButtonsArray = [
      { id: "ACTIVE", text: "Active" },
      ...filteredLayerButtons,
    ];
    // console.log(updatedButtonsArray,"updatedButtonsArray")
    setLayersButton(updatedButtonsArray);

    return categorizedLayers;
  };

  //All Posible Categories
  const categories = [
    "ENGLAND",
    "SCOTLAND",
    "WALES",
    "EV",
    "Data Centres",
    "NATIONAL GRID",
    "NATIONAL GAS",
    "UKPN",
    "SSEN",
    "NPG",
    "NGED",
    "ENWL", // Add other categories if necessary
  ];

  // Section Names according to the Ref IDs and value against him
  const sectionNames = {
    ACTIVE: ["ACTIVE", "Active"],
    ENGLAND: ["ENGLAND", "England"],
    SCOTLAND: ["SCOTLAND", "Scotland"],
    WALES: ["WALES", "Wales"],
    EV: ["EV", "EV"],
    "Data Centres": ["Data_Centres", "Data Centres"],
    "NATIONAL GRID": ["NATIONAL_GRID", "National Grid"],
    "NATIONAL GAS": ["NATIONAL_GAS", "National Gas"],
    UKPN: ["UKPN", "DNO:UKPN"],
    SSEN: ["SSEN", "DNO:SSEN"],
    NPG: ["NPG", "DNO:NPG"],
    NGED: ["NGED", "DNO:NGED"],
    ENWL: ["ENWL", "DNO:ENWL"],
  };

  useEffect(() => {
    // Categorize all layers
    const categorizedLayers = categorizeLayers(
      allLayers,
      categories,
      substation,
      autoLayers
    );
    setCategorizedLayers(categorizedLayers);
  }, [callUpdatedLayers]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMapLayerButtonClick = (event, text) => {
    event.stopPropagation();
    setButtonText(text);
  };
  const handleEventClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={setOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={(event) => handleEventClick(event)}
        style={{
          // backgroundColor: '#ffffff',
          // border: '2px solid #000000',
          // padding: '20px',
          // width: '1000px',
          // height: '80vh',
          // marginTop: '10vh',
          // borderWidth: '0px',
          // borderRadius: '30px'
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Slide direction="down" in={open}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h6"
                    sx={{
                      pl: 1,
                      m: 0,
                      fontFamily: "Eudoxus sans",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Layers
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h6"
                    sx={{
                      pl: 1,
                      fontFamily: "Eudoxus sans",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {filters.assetType === ""
                      ? "Real Estate"
                      : filters.assetType}
                  </Typography>
                  <MapLayersButtons
                    layersButtonsArray={layerButtons}
                    handleMapLayerButtonClick={handleMapLayerButtonClick}
                    setButtonId={setButtonId}
                    buttonText={buttonText}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <CancelIcon
                  onClick={setOpen}
                  style={{ position: "relative", left: "97%" }}
                />
                <Grid sx={{ m: 0.5, mt: 0 }}>
                  <LayersData
                    sectionNames={sectionNames}
                    sectionRefs={sectionRefs}
                    categorizedLayers={categorizedLayers}
                    categories={categories}
                    selectedButton={buttonText}
                    buttonId={buttonId}
                    setOpen={setOpen}
                    setCallUpdatedLayers={(value) =>
                      setCallUpdatedLayers(value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
}
