import "./myMap.css";
import {useSelector} from 'react-redux';
import {useState, useLayoutEffect} from 'react';
import * as turf from '@turf/turf';

export default function AreaCalculator() {
    const [area, setArea] = useState(null);
    const [length, setLength] = useState(null);
    const drawnLine = useSelector(state=>state.filters.drawnLine);
    const drawPolygon = useSelector(state=>state.filters.drawnPolygon);
  useLayoutEffect(() => {
    if(drawnLine && drawnLine?.geometry?.coordinates?.length >= 2){
        const line = turf.lineString(drawnLine.geometry.coordinates);
        const calculatedLength = turf.length(line, {units: 'meters'});
        setLength(calculatedLength);
    }
  },[drawnLine]);
  
  useLayoutEffect(() => {
    if(drawPolygon && drawPolygon?.geometry?.coordinates[0]?.length >= 3){
        const polygon = turf.polygon([drawPolygon.geometry.coordinates[0]]);
        const calculatedArea = turf.area(polygon);
        setArea(calculatedArea);
    }
  },[drawPolygon]);

  return (
    <div className="sidebar" style={length ? {display: 'block'} : area ? {display: 'block'} : {display: 'none'}}>
        {length && drawnLine &&
            <p>Length: {length.toFixed(2)} m</p>
        }
        {area && drawPolygon &&
            <p>Area: {area.toFixed(2)} sqm</p>
        }
    </div>
  )
}