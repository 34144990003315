import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    userDetail: {},
    isLoading:null,
  },
  reducers: {
    setUsers: (state, action) => {
      return { ...state, users: action.payload };
    },
    setUserDetail: (state, action) => {
      return { ...state, userDetail: action.payload };
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setUserProfile: (state, action) => {
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          user: action.payload,
        },
      };
    },
  },
});

export const { setUsers, setUserDetail,setUserProfile,setIsLoading } = usersSlice.actions;
export default usersSlice.reducer;
