import Box from "@mui/system/Box";
import Slider from "@mui/material/Slider";
import React from "react";
import styles from "./styles.module.scss";
import Graph_Range from "./../../../Images/Graph_Range.png";
import { fixed } from "../../Utils/Formats";


export default function RangeSlider({
  value,
  setValue,
  unit,
  minValue,
  maxValue,
}) {

  function valueText(value) {
    return `${value} ${unit}`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <Box
        sx={{
          width: 298,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img src={Graph_Range} alt="" />
        <Slider
          className={styles.slider}
          style={{
            color: "#1F443A",
          }}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={valueText}
          min={minValue}
          max={maxValue}
        />
      </Box>
      <div className={styles.range_values}>
        <p>
          {value?.[0]} <span style={{ textTransform: 'lowercase' }}> {unit} </span>
        </p>
        <p>
          {fixed(value?.[1], 2)} <span style={{ textTransform: 'lowercase' }}> {unit} </span>
        </p>
      </div>
    </div>
  );
}
