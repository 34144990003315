import React from "react";
// import Papa from 'papaparse';
import styles from "./styles.module.scss";
import { ArrowForward, LoaderWithCircle } from "../../Components/SVG";
import Button from "../../Components/Common/Button/Button";

const Analysis = (props) => {
  const { csvData, setCsvData, setFileName, count, setCount, setShowAnalysis } =
    props;

  return (
    <div className={styles.recommendation}>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <LoaderWithCircle />
      </div>
      <h3 className={styles.projectMapping} style={{ margin: "10px 0px" }}>
        Processing data
      </h3>
      <div className={styles.inputFileContainer}>
        <div className={styles.analysisData}>
          <h3>Project Details</h3>
          <hr />

          <div className={styles.projectInFile}>
            <p>Total projects in file</p>
            <p>{csvData?.records?.length}</p>
          </div>
          <div className={styles.projectInFile}>
            <p>Duplicated Records</p>
            <p>-{csvData?.duplicates?.length}</p>
          </div>
          <div className={styles.projectInFile}>
            <p>Invalid Records</p>
            <p>-{csvData?.invalidRecords?.length}</p>
          </div>
          <div className={styles.projectInFile}>
            <p>Unique Records</p>
            <p>{csvData?.uniqueRecords?.length}</p>
          </div>
          <hr />

          <div className={styles.projectInFile}>
            <p style={{ fontWeight: "bold" }}>Total Projects Imported</p>
            <p style={{ fontWeight: "bold" }}>{csvData?.records?.length}</p>
          </div>
        </div>

        <div
          className={styles.buttonMainContainer}
          style={{ justifyContent: "center" }}
        >
          <Button
            height="40px"
            bgColor="#EDF0EF"
            color="#1F443A"
            radius="12px"
            borderColor="#EDF0EF"
            text="Re-upload file"
            handleClick={() => {
              setCsvData(null);
              setFileName(null);
              setShowAnalysis(false);
            }}
          />
          <Button
            height="40px"
            bgColor="#1F443A"
            color="#fff"
            radius="12px"
            borderColor="#1F443A"
            disable={
              csvData?.duplicates.length > 0 ||
              csvData.invalidRecords.length > 0
                ? "true"
                : "false"
            }
            text="Continue"
            icon1={<ArrowForward fill="#fff" />}
            handleClick={() => {
              setCount(count + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Analysis;
