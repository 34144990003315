import React from "react";
import styles from "./styles.module.scss";
export const TitleDescription = ({ title, des }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <h3 className={styles.des}>{des}</h3>
    </div>
  );
};
