import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import AppButton from "../../Common/Button/AppButton";
import { useDispatch } from "react-redux";
import { setGptPrompt, setOsmData } from "../../Redux/reducers/gptOsmSlice";
import { useSelector } from "react-redux";
import GptLayers from "./GptLayers";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";

const GptOsmMode = () => {
  const [text, setText] = useState("");
  const prompt = useSelector((state) => state.gptOsmData.prompt);
  const dispatch = useDispatch();
  const osmData = useSelector((state) => state.gptOsmData.osmData);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  return (
    <>
      <div style={{ margin: "10px" }}>
        <TextField
          className="wideTextField"
          id="outlined-multiline-static"
          label="Prompt"
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%" }}
          onChange={handleTextChange}
        />
        <div style={{ margin: "10px" }}>
          <AppButton
            variant="large"
            text={"Show Insight"}
            onClick={() => dispatch(setGptPrompt(text))}
            isSelected={text !== "" ? true : false}
            id={1}
          />
        </div>
      </div>
      {
        prompt && <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '21px'
        }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
            marginTop: '19px',
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            
            }}
          >
            <CircularProgress color="success" />
          </Stack>
        </div>
      }
      <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-between', color: '#787B82', fontSize: '14px' }}>
        <div style={{ fontWeight: 'bold' }}>LAYERS</div>
        {osmData?.length > 0 && <div onClick={() => {
          dispatch(setOsmData([]))
          dispatch(setGptPrompt(null))
        }} style={{ cursor: 'pointer' }}>Delete All</div>}
      </div>
      <GptLayers />
    </>
  );
};

export default GptOsmMode;
